import React, { useState } from 'react';
import { useDeviceState } from '../../hooks/useDeviceState';
import classNames from 'classnames';
import styles from './Postcard.module.scss';
import { CustomCursorState } from '../CustomCursor/CustomCursor';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';

interface PostcardProps {
  fromValue: string;
  toValue: string;
  images: string[];
  activeImageIndex: number;
  mainCopy: string;
  onActivePostcardChange?: (newPostcardIndex: number) => void;
  canEdit?: boolean;
  location: string;
}

const Postcard: React.FC<PostcardProps> = ({
  fromValue,
  toValue,
  images,
  mainCopy,
  activeImageIndex,
  onActivePostcardChange,
  canEdit,
  location,
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const { isMobile, deviceState } = useDeviceState();

  const onEnterZoom = (node: HTMLElement) => {
    gsap.set(node, { autoAlpha: 0 });
  };

  const onZoomTransition = (node: HTMLElement, done: () => void) => {
    gsap.to(node, {
      autoAlpha: isZoomed ? 1 : 0,
      onComplete: done,
    });
  };

  return (
    <div
      className={classNames(styles.postcardWrapper, deviceState < 2 && isZoomed && styles.isZoomed)}
      onClick={() => setIsZoomed(!isZoomed)}
    >
      <div className={styles.stamp}></div>
      <div className={styles.location}>{location}</div>

      <div className={styles.imageWrapper}>
        {images.map(
          (image, index) =>
            activeImageIndex === index && <img src={image} alt="" key={index}></img>,
        )}

        {!isMobile && canEdit && (
          <div className={styles.postcardSelectorWrapper}>
            {images.map((image, index) => (
              <button
                className={classNames(styles.button, activeImageIndex === index && styles.isActive)}
                onClick={() => onActivePostcardChange && onActivePostcardChange(index)}
                key={index}
                data-custom-cursor-state={CustomCursorState.Pointer}
              ></button>
            ))}
          </div>
        )}
      </div>

      <div className={styles.right}>
        <div className={styles.from}>
          <span className={styles.label}>From:</span>
          <span className={styles.name}>{fromValue}</span>
        </div>
        <div className={styles.to}>
          <span className={styles.label}>To:</span>
          <span className={styles.name}>{toValue}</span>
        </div>

        <h3 className={styles.title}>Greetings</h3>
        <span className={styles.caption}>from the 1890s</span>

        <p className={styles.copy}>{mainCopy}</p>
      </div>

      {deviceState < 2 && (
        <Transition
          unmountOnExit
          in={isZoomed}
          onEnter={onEnterZoom}
          addEndListener={onZoomTransition}
        >
          <div className={styles.zoomedCopy}>{mainCopy}</div>
        </Transition>
      )}
    </div>
  );
};

export { Postcard };
