import React, { FunctionComponent, useRef, useEffect, useCallback, useState } from 'react';
import { RoutePaths } from '../../../../data/enum/RoutePaths';
import { useHistory, useLocation } from 'react-router-dom';

import gsap from 'gsap';
import { SplitText } from '../../../../vendor/SplitText';

import classNames from 'classnames';
import styles from './Hero.module.scss';

import { MaskedImage } from '../../../MaskedImage/MaskedImage';
import { RoundedCta } from '../../../RoundedCta/RoundedCta';

import inkTransition from '../../../../assets/images/ink-transition3.jpg';
import mapBackground from '../../../../assets/images/map/bg_map_4.jpg';
import mapMobileBackground from '../../../../assets/images/map/bg_map_4_mobile.jpg';

import { useDeviceState } from '../../../../hooks/useDeviceState';
import { handleHeroPageViewAnalytics } from '../../../../utils/analyticsWrapper';

import backgroundVisible from '../../../../assets/images/landing/hero/background.jpg';
import backgroundHidden from '../../../../assets/images/landing/hero/background-hidden.jpg';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';
import { transitionWithShader } from '../../../../utils/transitionWithShader';

interface HeroProps {
  onDownArrowButtonClick: () => void;
}

export const Hero: FunctionComponent<HeroProps> = ({ onDownArrowButtonClick }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { isTouch } = useDeviceState();
  const maskRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const taglineRef = useRef<HTMLParagraphElement>(null);
  const taglineLineRef = useRef<HTMLSpanElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const mobileCtaRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLSpanElement>(null);
  const backgroundOverlayRef = useRef<HTMLDivElement>(null);
  const { push } = useHistory();

  const { state } = useLocation<{ noTransition: boolean }>();
  const locationState = useRef(state);

  const handleTransitions = useCallback(() => {
    const timeline = gsap.timeline();

    backgroundOverlayRef.current &&
      timeline.fromTo(
        backgroundOverlayRef.current,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.6,
        },
        0,
      );

    timeline.to(
      [contentRef.current],
      {
        opacity: 1,
        duration: 0,
        ease: 'power2.in',
      },
      0,
    );

    const splitTagline = new SplitText(taglineRef.current, { type: 'words, chars' });
    const splitTitle = new SplitText(titleRef.current, { type: 'words, chars' });

    timeline.fromTo(
      splitTagline.chars,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.1,
        duration: 0.7,
        ease: 'power2.in',
      },
      0,
    );

    timeline.fromTo(
      taglineLineRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.6,
        ease: 'power2.in',
      },
      1,
    );

    timeline.fromTo(
      splitTitle.chars,
      {
        opacity: 0,
        y: 5,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        stagger: 0.06,
        ease: 'power2.in',
      },
      0,
    );

    mobileCtaRef.current &&
      timeline.fromTo(
        mobileCtaRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
          ease: 'power2.in',
        },
        1,
      );

    timeline.fromTo(
      iconRef.current,
      {
        opacity: 0,
        y: 5,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: 'power2.in',
      },
      1,
    );

    return timeline;
  }, []);

  useEffect(() => {
    handleTransitions();
  }, [handleTransitions]);

  useEffect(() => {
    handleHeroPageViewAnalytics();

    if (locationState.current?.noTransition) {
      gsap.set(maskRef.current, { autoAlpha: 0 });
      return;
    }

    const timeline = gsap.timeline();

    maskRef.current &&
      timeline.to(
        maskRef.current,
        {
          opacity: 0,
          duration: 0.6,
          delay: 0.2,
          ease: 'power2.in',
        },
        0.2,
      );
  }, []);

  const transitionToExplore = () => {
    if (isTransitioning) return;

    const timeline = gsap.timeline();
    const splitTitle = new SplitText(titleRef.current, { type: 'words, chars' });

    timeline.fromTo(
      splitTitle.chars,
      {
        opacity: 1,
        y: 0,
      },
      {
        opacity: 0,
        y: 5,
        duration: 0.65,
        stagger: 0.035,
        ease: 'power2.in',
        onComplete: () => {
          transitionWithShader(
            [isTouch ? mapMobileBackground : mapBackground, inkTransition],
            () => push(RoutePaths.EXPLORE, { noTransition: true }),
            () => setIsTransitioning(false),
            8,
          );
        },
      },
      0,
    );
  };

  return (
    <div className={styles.hero}>
      <div ref={maskRef} className={styles.mask} />
      {isTouch ? (
        <div className={classNames(styles.backgroundWrapper)}>
          <span className={classNames(styles.backgroundImage, styles.mobileImage)} />
          <span ref={backgroundOverlayRef} className={classNames(styles.backgroundOverlay)} />
        </div>
      ) : (
        <div className={styles.backgroundImage} onClick={transitionToExplore}>
          <MaskedImage
            visibleImageSrc={backgroundVisible}
            hiddenImageSrc={backgroundHidden}
            cursorText="Click to</br><i>Explore</i>"
          />
        </div>
      )}

      <div ref={contentRef} className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <p ref={taglineRef} className={classNames(styles.tagline, 'globalTagline01')}>
              <span>NEW YORK, </span>
              <span className={styles.underlineText}>
                <span>1897</span>
                <span ref={taglineLineRef} className={classNames(styles.underline, 'texture')} />
              </span>
            </p>
            <h2 ref={titleRef} className={classNames(styles.title, 'globalTitle01 texture')}>
              <span>
                <i>EXPLORE</i> NYC
              </span>
              <span>
                —<i>Then&NOW</i>
              </span>
            </h2>
          </div>

          <div className={styles.footer}>
            {isTouch && (
              <div ref={mobileCtaRef} className={styles.mobileCta} onClick={transitionToExplore}>
                <RoundedCta copy="Discover</br><i>the City</i>" />
              </div>
            )}
            <button
              className={styles.exploreLink}
              data-custom-cursor-state={CustomCursorState.Pointer}
              onClick={onDownArrowButtonClick}
            >
              <span ref={iconRef} className={classNames(styles.icon, 'texture')} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
