import React, { useEffect } from 'react';

import { HotspotModalProps } from './HotspotModal';
import { VideoPlayer } from '../../../VideoPlayer/VideoPlayer';
import { ImageComparerMobile } from '../ImageComparer/ImageComparerMobile';
import { HotspotImageCarousel } from '../HotspotImageCarousel/HotspotImageCarousel';
import { Modal } from '../Modal/Modal';

import { handleHotspotDetailsViewAnalytics } from '../../../../utils/analyticsWrapper';

import { useDeviceState } from '../../../../hooks/useDeviceState';

import styles from './HotspotModalMobile.module.scss';

const HotspotModalMobile: React.FC<HotspotModalProps> = ({
  isVisible,
  currentHotspotCopy,
  totalHotspotsCopy,
  hotspotData,
  previousHotspotClick,
  nextHotspotClick,
  closeClick,
  currentLocation,
}) => {
  const { isTouch } = useDeviceState();

  const prevStory = ((totalHotspotsCopy + currentHotspotCopy - 2) % totalHotspotsCopy) + 1;
  const nextStory = ((totalHotspotsCopy + currentHotspotCopy) % totalHotspotsCopy) + 1;

  useEffect(() => {
    if (isVisible) {
      handleHotspotDetailsViewAnalytics(currentLocation, hotspotData.cleanTitle);
    }
  }, [hotspotData, isVisible, currentLocation]);

  return (
    <Modal
      isVisible={isVisible}
      closeClick={closeClick}
      closeButtonIsBlack={hotspotData.closeButtonBlack}
    >
      <div className={styles.mediaContent}>
        {isTouch && <span className={styles.mobileOverlay} />}

        {hotspotData.mediaContent.hiddenContent ? (
          hotspotData.mediaContent.isVideo && isVisible ? (
            <VideoPlayer
              src={hotspotData.mediaContent.visibleContent[0].src}
              visiblePosterSrc={hotspotData.mediaContent.hiddenContent.src}
              isModalMobile
            />
          ) : (
            <ImageComparerMobile
              visibleContent={hotspotData.mediaContent.visibleContent[0]}
              hiddenContent={hotspotData.mediaContent.hiddenContent}
            />
          )
        ) : (
          <HotspotImageCarousel images={hotspotData.mediaContent.visibleContent} />
        )}
      </div>

      <div className={styles.infoWrapper}>
        <div>
          <div className={styles.titleWrapper}>
            <span className={styles.pager}>
              {currentHotspotCopy.toString().padStart(2, '0')}/
              {totalHotspotsCopy.toString().padStart(2, '0')}
            </span>
            <h3 dangerouslySetInnerHTML={{ __html: hotspotData.title }}></h3>
          </div>

          <div className={styles.copyWrapper}>
            <p
              className={styles.copy}
              dangerouslySetInnerHTML={{ __html: hotspotData.description }}
            />
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <button onClick={previousHotspotClick}>
            Story {prevStory.toString().padStart(2, '0')}
          </button>
          <button onClick={nextHotspotClick}>Story {nextStory.toString().padStart(2, '0')}</button>
        </div>
      </div>
    </Modal>
  );
};

export { HotspotModalMobile };
