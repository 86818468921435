import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import gsap from 'gsap';

import { MapLocationHotspot } from '../../../../types/nyMapTypes';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from '../../../../vendor/InertiaPlugin';

import styles from './HotspotSelectorMobile.module.scss';

interface HotspotSelectorProps {
  isVisible: boolean;
  activeHotspotIndex: number;
  hotspots: Array<MapLocationHotspot>;
  onHotspotSelect: (hotspotIndex: number) => void;
}

const HotspotSelector: React.FC<HotspotSelectorProps> = ({
  isVisible,
  activeHotspotIndex,
  hotspots,
  onHotspotSelect,
}) => {
  const selectorContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gsap.registerPlugin(Draggable);
    gsap.registerPlugin(InertiaPlugin);
  }, []);

  useEffect(() => {
    if (!selectorContainer.current) return;

    const width = selectorContainer.current.getBoundingClientRect().width;
    const itemWidth = width / hotspots.length;

    const xSnap = hotspots.map((hotspot, index) => {
      return Math.round(-index * itemWidth + itemWidth - 10);
    });

    Draggable.create(selectorContainer.current, {
      type: 'x',
      zIndexBoost: false,
      snap: { x: xSnap },
      inertia: true,
      onThrowComplete: function () {
        onHotspotSelect(xSnap.findIndex((snapValue) => snapValue === Math.round(this.x)));
      },
    });
  }, [hotspots, isVisible, onHotspotSelect, activeHotspotIndex]);

  useEffect(() => {
    if (!selectorContainer.current) {
      return;
    }

    const width = selectorContainer.current.getBoundingClientRect().width;
    const itemWidth = width / hotspots.length;
    gsap.to(selectorContainer.current, {
      x: -activeHotspotIndex * itemWidth + itemWidth - 10,
    });
  });

  return (
    <div className={classNames(isVisible && styles.isVisible, styles.hotspotSelectorWrapper)}>
      <div className={classNames(styles.hotspotSelector)}>
        <div className={styles.selectorWrapper}>
          <div ref={selectorContainer} className={styles.selectorContainer}>
            {hotspots.map((hotspot, index) => {
              return (
                <div
                  className={classNames(
                    styles.hotspotItem,
                    activeHotspotIndex === index && styles.isActive,
                  )}
                  key={index}
                >
                  <button onClick={() => onHotspotSelect(index)}>
                    <span>{index + 1}</span>
                    <div className={styles.imgWrapper}>
                      <img src={hotspot.thumbnail.src} alt={hotspot.thumbnail.alt} />
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HotspotSelector };
