import React from 'react';

import classNames from 'classnames';
import styles from './ImageComparerDesktop.module.scss';

import { MediaContent } from '../../../../types/nyMapTypes';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';

interface ImageComparerProps {
  visibleContent: MediaContent;
  hiddenContent: MediaContent;
}

const ImageComparer: React.FC<ImageComparerProps> = ({ visibleContent, hiddenContent }) => {
  return (
    <div className={styles.imageComparer}>
      <img
        className={classNames(styles.image, styles.visible)}
        src={visibleContent.src}
        alt={visibleContent.alt}
      />
      <div
        className={classNames(styles.hiddenImageWrapper)}
        data-custom-cursor-state={CustomCursorState.MaskAlt}
      >
        <img
          className={classNames(styles.image, styles.hidden)}
          src={hiddenContent.src}
          alt={hiddenContent.alt}
        />
      </div>
    </div>
  );
};

export { ImageComparer };
export type { ImageComparerProps };
