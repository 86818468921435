import React from 'react';
import classNames from 'classnames';
import { MapLocationHotspot, ShareHotspot } from '../../../../types/nyMapTypes';

import styles from './HotspotMarkerMobile.module.scss';

interface HotspotMarkerProps {
  hotspotData: MapLocationHotspot | ShareHotspot;
  copy: string;
  onMarkerClick: () => void;
  isActive?: boolean;
}

const HotspotMarkerMobile: React.FC<HotspotMarkerProps> = ({
  hotspotData,
  isActive,
  copy,
  onMarkerClick,
}) => {
  return (
    <button
      className={classNames(
        styles.hotspotMarker,
        isActive && styles.isActive,
        styles.hotspotMarker,
        styles[hotspotData.type + 'Type'],
        hotspotData.isDarkPin && styles.darkPin,
        hotspotData.isPostcard && styles.postcardPin,
      )}
      style={{
        left: hotspotData.markerPositionMobile.x,
        top: hotspotData.markerPositionMobile.y,
      }}
      onClick={onMarkerClick}
    >
      <span>{copy}</span>
    </button>
  );
};

export { HotspotMarkerMobile };
