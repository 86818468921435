import { Extras } from '../../types/extrasTypes';

import image01 from '../../assets/images/extras/slides/image-01.jpg';
import video01 from '../../assets/videos/lying-in-hospital-overview.mp4';
import posterMobile01 from '../../assets/images/extras/slides/poster-desktop-01.jpg';
import posterDesktop01 from '../../assets/images/extras/slides/poster-desktop-01.jpg';

import image02 from '../../assets/images/extras/slides/image-02.jpg';
import posterMobile02 from '../../assets/images/extras/slides/poster-desktop-02.jpg';
import posterDesktop02 from '../../assets/images/extras/slides/poster-desktop-02.jpg';
import video02 from '../../assets/videos/creating-costumes.mp4';

import image03 from '../../assets/images/extras/slides/image-03.jpg';
import posterMobile03 from '../../assets/images/extras/slides/poster-desktop-03.jpg';
import posterDesktop03 from '../../assets/images/extras/slides/poster-desktop-03.jpg';
import video03 from '../../assets/videos/dressing-the-modern-woman.mp4';

import image04 from '../../assets/images/extras/slides/image-04.jpg';
import posterMobile04 from '../../assets/images/extras/slides/poster-desktop-04.jpg';
import posterDesktop04 from '../../assets/images/extras/slides/poster-desktop-04.jpg';
import video04 from '../../assets/videos/walking-the-set.mp4';

import image05 from '../../assets/images/extras/slides/image-05.jpg';
import posterMobile05 from '../../assets/images/extras/slides/poster-desktop-05.jpg';
import posterDesktop05 from '../../assets/images/extras/slides/poster-desktop-05.jpg';
import video05 from '../../assets/videos/ny-journal.mp4';

const extras1: Extras = {
  title: '<span>Begins</span><span>—AGAIN</span>',
  description: '<i>The hunt for</i><span> a killer</span>',
  cleanDescription: 'The Hunt for a Killer',
  length: 'length . 2:48',
  image: image01,
  poster: {
    mobile: posterMobile01,
    desktop: posterDesktop01,
  },
  video: video01,
};

const extras2: Extras = {
  title: '<span>Dressing</span><span class="isNumber">—1897</span>',
  description: '<i>Creating</i><span> Costumes</span>',
  cleanDescription: 'Creating Costumes',
  length: 'length . 3:34',
  image: image02,
  poster: {
    mobile: posterMobile02,
    desktop: posterDesktop02,
  },
  video: video02,
};

const extras3: Extras = {
  title: '<span>Modern</span><span>—WOMAN</span>',
  description: '<i>Dressing</i><span> Sarah</span>',
  cleanDescription: 'Dressing Sarah',
  length: 'length . 2:34',
  image: image03,
  poster: {
    mobile: posterMobile03,
    desktop: posterDesktop03,
  },
  video: video03,
};

const extras4: Extras = {
  title: '<span>Tour with</span><span>—LUKE</span>',
  description: '<i>Walking the</i><span> set</span>',
  cleanDescription: 'Walking the Set',
  length: 'length . 2:09',
  image: image04,
  poster: {
    mobile: posterMobile04,
    desktop: posterDesktop04,
  },
  video: video04,
};

const extras5: Extras = {
  title: '<span>Gilded</span><span>—AGE</span>',
  description: '<i>Building the</i><span> World</span>',
  cleanDescription: 'Building the World',
  length: 'length . 3:20',
  image: image05,
  poster: {
    mobile: posterMobile05,
    desktop: posterDesktop05,
  },
  video: video05,
};

export const extrasContent = [extras1, extras2, extras3, extras4, extras5];
