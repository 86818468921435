import React, { useState } from 'react';

import { TextCanvas } from './TextCanvas/TextCanvas';
import { Modal, ModalProps } from '../views/NYMap/Modal/Modal';

import { availablePostcards } from '../../data/postcards';

import defaultImageSrc from '../../assets/images/postcards/img_postcard1.jpg';
import image from '../../assets/images/postcards/postcard1_full.jpg';
import { Postcard } from '../Postcard/Postcard';

import styles from './PostcardDownloader.module.scss';
import { CustomCursorState } from '../CustomCursor/CustomCursor';

interface PostcardDownloaderProps extends ModalProps {
  fromValue: string;
  toValue: string;
  activePostcardIndex: number;
}

const PostcardDownloader: React.FC<PostcardDownloaderProps> = ({
  isVisible,
  closeClick,
  fromValue,
  toValue,
  activePostcardIndex,
}) => {
  const [postcardSrc, setPostcardSrc] = useState('');

  const handleCanvasUpdate = (canvas: HTMLCanvasElement) => {
    setPostcardSrc(canvas.toDataURL('image/jpeg'));
  };

  return (
    <Modal isPostcard isVisible={isVisible} closeClick={closeClick}>
      <div className={styles.PostcardDownloader}>
        <div className={styles.postcardWrapper}>
          <Postcard
            fromValue={fromValue}
            toValue={toValue}
            images={availablePostcards.map((postcard) => postcard.imageSrc)}
            activeImageIndex={activePostcardIndex}
            mainCopy={availablePostcards[activePostcardIndex].copy}
            location={availablePostcards[activePostcardIndex].location}
          />
        </div>

        <img src={image} alt=""></img>

        <div className={styles.canvasWrapper}>
          <TextCanvas
            imageSrc={
              availablePostcards[activePostcardIndex]
                ? availablePostcards[activePostcardIndex].downloadableImageSrc
                : defaultImageSrc
            }
            texts={[
              {
                copy: fromValue,
                fontProps: { family: 'Cormorant' },
                position: { x: 382, y: 122 },
              },
              {
                copy: toValue,
                fontProps: { family: 'Cormorant' },
                position: { x: 367, y: 142 },
              },
            ]}
            onCanvasUpdate={handleCanvasUpdate}
          />
        </div>

        <div className={styles.infoWrapper}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>
              NY <i>in the time of THE ALIENIST</i>
            </h2>
          </div>

          <div className={styles.descriptionWrapper}>
            <div className={styles.copyWrapper}>
              <p>You received a postcard from a friend, enjoy the Alienist World!</p>
            </div>

            <a
              href={postcardSrc}
              download="Postcard"
              data-custom-cursor-state={CustomCursorState.Pointer}
            >
              Download postcard
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { PostcardDownloader };
