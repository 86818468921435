import React, { useEffect, useRef, useCallback } from 'react';
import { imagesToLoadDesktop, imagesToLoadMobile } from './AssetsToLoad';
import classNames from 'classnames';
import styles from './Loader.module.scss';
import gsap from 'gsap';
import { CustomCursorState } from '../CustomCursor/CustomCursor';
import { useDeviceState } from '../../hooks/useDeviceState';

interface LoaderProps {
  onComplete: () => void;
}

const Loader: React.FC<LoaderProps> = ({ onComplete }) => {
  const { isTouch } = useDeviceState();
  const loadProgress = useRef(0);
  const loadTextElement = useRef<HTMLParagraphElement>(null);

  const progressCircle = useRef<SVGCircleElement>(null);
  const circleCircumference = useRef(0);

  const handleLoadChange = useCallback((onAnimationEnd) => {
    gsap.to(loadTextElement.current, {
      innerText: loadProgress.current,
      duration: 0.5,
      snap: { 'data-custom-cursor-text': 1, innerText: 1 },
      onUpdate: () => {
        if (loadTextElement.current) {
          loadTextElement.current!.setAttribute(
            'data-custom-cursor-text',
            loadTextElement.current!.innerText,
          );
        }
      },
      onComplete: onAnimationEnd, // comment this line to avoid loading finish
    });
  }, []);

  useEffect(() => {
    const radius = progressCircle.current!.r.baseVal.value;
    circleCircumference.current = radius * 2 * Math.PI;

    progressCircle.current!.style.strokeDasharray = `${circleCircumference.current} ${circleCircumference.current}`;
    progressCircle.current!.style.strokeDashoffset = `${circleCircumference.current}`;
  }, []);

  const updateCircleProgress = useCallback(() => {
    if (!progressCircle.current) {
      return;
    }

    const offset =
      circleCircumference.current - (loadProgress.current / 100) * circleCircumference.current;
    progressCircle.current.style.strokeDashoffset = `${offset}`;
  }, []);

  useEffect(() => {
    const imagesToLoad = isTouch ? imagesToLoadMobile : imagesToLoadDesktop;
    const loadFactorPerImage = 1 / imagesToLoad.length;
    const imageElementRefs: Array<HTMLImageElement> = [];

    const handleImageLoad = () => {
      loadProgress.current += loadFactorPerImage * 100;
      handleLoadChange(Math.round(loadProgress.current) >= 100 ? onComplete : null);
      updateCircleProgress();
    };

    imagesToLoad.forEach((imageToLoad) => {
      const imageElement = new Image();
      imageElement.onload = handleImageLoad;
      imageElement.onerror = handleImageLoad; // even if there's an error let's count it as loaded, otherwise site won't finish loading
      imageElement.crossOrigin = 'Anonymous';
      imageElement.src = imageToLoad;
      imageElementRefs.push(imageElement);
    });

    return () => imageElementRefs.forEach((imageElement) => (imageElement.onload = null));
  }, [isTouch, handleLoadChange, onComplete, updateCircleProgress]);

  return (
    <>
      <div className={classNames(styles.underlay)}>
        <p
          className={styles.loadText}
          ref={loadTextElement}
          data-custom-cursor-text="0"
          data-custom-cursor-state={CustomCursorState.ActionBig}
        />
      </div>
      <div className={styles.loader} data-custom-cursor-state={CustomCursorState.ActionBig}>
        <svg className={styles.progressRing} height="190" width="190">
          <circle
            className={styles.circleDots}
            strokeWidth="1"
            stroke="white"
            fill="transparent"
            r="93"
            cx="95"
            cy="95"
            mask="url(#progress)"
          />

          <mask id="progress" clipPathUnits="objectBoundingBox">
            <circle
              ref={progressCircle}
              className={styles.progress}
              strokeWidth="1"
              stroke="white"
              fill="transparent"
              r="93"
              cx="95"
              cy="95"
            />
          </mask>
        </svg>
      </div>
    </>
  );
};

export { Loader };
