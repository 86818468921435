export const rootPath = '/angel-of-darkness/explore-nyc';

export const RoutePaths = {
  LANDING: `/`,
  LANDING_HERO: `/`,
  LANDING_ABOUT: `/about`,
  LANDING_TRAILER: `/trailer`,
  EXPLORE: `/explore`,
  EXTRAS: `/extras`,
  EXTRAS_CONTENT: `/extras/content`,
};
