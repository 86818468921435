import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';
import styles from './LocationSelectorDesktop.module.scss';
import gsap from 'gsap';
import { SplitText } from '../../../../vendor/SplitText';

interface LocationSelectorProps {
  activeElementIndex: number;
  locationItems: Array<LocationSelectorItemProps>;
  setLocation: (index: number) => void;
  isActive: boolean;
  onNextButtonClick?: () => void;
  onPreviousButtonClick?: () => void;
}

interface LocationSelectorItemProps {
  shortName: string;
  shortNameClass: string;
  href: string;
}

const LocationSelector: React.FC<LocationSelectorProps> = ({
  activeElementIndex,
  locationItems,
  isActive,
  setLocation,
  onNextButtonClick,
  onPreviousButtonClick,
}) => {
  const copyRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!copyRef.current) return;
    const copy = new SplitText(copyRef.current, {
      type: 'chars',
    });

    gsap.fromTo(
      copy.chars,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.3,
        delay: 0.5,
        stagger: 0.1,
      },
    );
  }, [isActive]);

  return (
    <div className={styles.locationSelector}>
      <div className={styles.indicator}>
        <span ref={copyRef} className={classNames('texture', styles.indicatorCopy)}>
          SCROLL UP/DOWN
          <br />
          TO EXPLORE
        </span>
        <button
          className={classNames(styles.navButton, styles.prevButton)}
          onClick={onPreviousButtonClick}
          data-custom-cursor-state={CustomCursorState.Pointer}
        ></button>
        <button
          className={classNames(styles.navButton, styles.nextButton)}
          onClick={onNextButtonClick}
          data-custom-cursor-state={CustomCursorState.Pointer}
        ></button>
      </div>
      <div className={classNames('texture', styles.bgHorizontalLocation)}></div>
      <div className={classNames('texture', styles.bgVerticalLocation)}></div>
      {locationItems.map((locationItem, index) => {
        return (
          <div
            key={index}
            className={classNames(
              styles.itemWrapper,
              activeElementIndex === index ? styles.isActive : '',
            )}
            onClick={() => setLocation(index)}
            data-custom-cursor-state={CustomCursorState.Pointer}
          >
            <div
              className={classNames(styles.item, styles[locationItem.shortNameClass])}
              key={index}
            >
              <div className={styles.link}>
                <div className={styles.locationNameWrapper}>
                  <span dangerouslySetInnerHTML={{ __html: locationItem.shortName }}></span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <svg viewBox="0 0 242 520" className={styles.locationPath}>
        <path
          d="M134.3 118.39c-.8 6.84-1.87 13.9-3.25 21.18-6.31 33.16-17.46 61.23-29.16 83.82-3.96 6.49-9.34 16.52-13.39 29.5-10.27 32.91-4.56 61.09-2.66 73.52 4.01 26.22 3.81 65.75-16.55 122.13"
          fill="none"
          stroke="white"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};

export { LocationSelector };
export type { LocationSelectorProps };
