import { MapLocation, MapLocationHotspot, ShareHotspot } from '../../types/nyMapTypes';

import bgMap from '../../assets/images/map/bg_map_2.jpg';
import bgMapMobile from '../../assets/images/map/bg_map_2_mobile.jpg';
import hotspot1Thumbnail from '../../assets/images/map/westside/madisonsq/madisonsq-now.jpg';
import hotspot1ThumbnailHidden from '../../assets/images/map/westside/madisonsq/madisonsq-then.jpg';
import hotspot2Thumbnail from '../../assets/images/map/westside/flatiron/flatiron-now.jpg';
import hotspot2ThumbnailHidden from '../../assets/images/map/westside/flatiron/flatiron-then.jpg';
import hotspot3Thumbnail from '../../assets/images/map/westside/unionsq/unionsq-now.jpg';
import hotspot3ThumbnailHidden from '../../assets/images/map/westside/unionsq/unionsq-then.jpg';
import hotspot4Thumbnail from '../../assets/images/map/westside/siegel/siegel.jpg';
import hotspot4aThumbnail from '../../assets/images/map/westside/siegel/siegel02.jpg';
import hotspot4bThumbnail from '../../assets/images/map/westside/siegel/siegel03.jpg';
import hotspot4cThumbnail from '../../assets/images/map/westside/siegel/siegel04.jpg';
import hotspot5Thumbnail from '../../assets/images/map/westside/broadway/broadway.jpg';
import hotspot6Video from '../../assets/videos/howard-agency-department-hide-and-seek.mp4';
import hotspot6Thumbnail from '../../assets/images/map/westside/howardagencydept/howardagencydept.jpg';
import hotspot7Thumbnail from '../../assets/images/map/westside/gasenvoort/gasenvoort.jpg';
import hotspot8Video from '../../assets/videos/howard-agency-department-the-arrival.mp4';
import hotspot8Thumbnail from '../../assets/images/map/westside/howardagencydept/howardagencydeptarrival.jpg';

const shareHotspot: ShareHotspot = {
  markerPositionDesktop: {
    x: '60%',
    y: '58%',
  },
  markerPositionMobile: {
    x: '72%',
    y: '38%',
  },
  type: 'share',
  isPostcard: true,
};

const hotspot1: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '48.5%',
    y: '20%',
  },
  markerPositionMobile: {
    x: '66%',
    y: '20%',
  },
  type: 'city',
  title: `<span>Madison</span> <i>Square G.</i>`,
  cleanTitle: 'Madison Square G.',
  description: `Home to numerous professional sports teams, New York City's most famous event center has been demolished and rebuilt four times in three locations since it was originally erected in 1925.`,
  thumbnail: {
    src: hotspot1ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot1Thumbnail,
        alt: 'landscape_1',
      },
    ],
    hiddenContent: {
      src: hotspot1ThumbnailHidden,
      alt: 'landscape_2',
    },
  },
};

const hotspot2: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '44.1%',
    y: '37%',
  },
  markerPositionMobile: {
    x: '50%',
    y: '28%',
  },
  type: 'city',
  title: `<span>Flat</span> <i>Iron</i> Building`,
  cleanTitle: 'Flat Iron Building',
  description: `The Fuller Building was the original name for this architectural beauty. Ever since it was built in 1902, people have come to behold and photograph the famously triangular structure, wedged into the plot of land with steel, limestone, and terra-cotta.`,
  thumbnail: {
    src: hotspot2ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot2Thumbnail,
        alt: 'landscape_1',
      },
    ],
    hiddenContent: {
      src: hotspot2ThumbnailHidden,
      alt: 'landscape_2',
    },
  },
  closeButtonBlack: false,
};

const hotspot3: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '39.7%',
    y: '47%',
  },
  markerPositionMobile: {
    x: '10%',
    y: '48%',
  },
  type: 'city',
  title: `<i>Union</i> <span>Square</span>`,
  cleanTitle: 'Union Square',
  titlePreview: `<i>Union</i><br><span>Square</span>`,
  description: `Once named "Union Place", this now-bustling urban hub had somber beginnings as a potter's field. Located next to the original Broadway theater district, it was converted into one of New York's most prestigious parks in the 1870s.`,
  thumbnail: {
    src: hotspot3ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot3Thumbnail,
        alt: 'landscape_1',
      },
    ],
    hiddenContent: {
      src: hotspot3ThumbnailHidden,
      alt: 'landscape_2',
    },
  },
  closeButtonBlack: false,
};

const hotspot4: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '29%',
    y: '35%',
  },
  markerPositionMobile: {
    x: '24%',
    y: '29%',
  },
  type: 'story',
  title: `<i><span>Siegel</span> Cooper <span>Dept.<span></i> Store`,
  cleanTitle: 'Siegel Cooper Dept. Store',
  titlePreview: `<i><span>Siegel</span> Cooper</i><br> <i><span>Dept.<span></i> Store`,
  description: `The Siegel & Cooper department store, on 6th Avenue and 18th Street, in the heart of Ladies' Mile, under construction in the year of its opening 1896.`,
  thumbnail: {
    src: hotspot4Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot4Thumbnail,
        alt: 'landscape_1',
      },
      {
        src: hotspot4aThumbnail,
        alt: 'landscape_1',
      },
      {
        src: hotspot4bThumbnail,
        alt: 'landscape_1',
      },
      {
        src: hotspot4cThumbnail,
        alt: 'landscape_1',
      },
    ],
  },
  closeButtonBlack: false,
};

const hotspot5: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '26%',
    y: '70%',
  },
  markerPositionMobile: {
    x: '10%',
    y: '48%',
  },
  type: 'story',
  title: `770 <i>Broadway</i>`,
  cleanTitle: '770 Broadway',
  description: `<a target="_blank" href="https://m.me/TheAlienistTNT" data-custom-cursor-state="pointer">Click through</a> to discover your turn of the century criminal match in a 1:1 Interrogation Experience with Sara Howard.`,
  thumbnail: {
    src: hotspot5Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot5Thumbnail,
        alt: '770 Broadway now',
      },
    ],
  },
};

const hotspot6: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '30%',
    y: '60%',
  },
  markerPositionMobile: {
    x: '30%',
    y: '45%',
  },
  type: 'story',
  title: `<span><i>Howard</i></span> Agency <i>Dept.</i>`,
  cleanTitle: 'Howard Agency Dept',
  titlePreview: `<span><i>Howard</i></span><br> Agency <i>Dept.</i>`,
  description: `Sara Howard, one of New York's first female detectives, set up an office for her and her staff of investigators at 808 Broadway.`,
  thumbnail: {
    src: hotspot6Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot6Video,
        alt: '',
      },
    ],
    hiddenContent: {
      src: hotspot6Thumbnail,
      alt: '',
    },
    isVideo: true,
  },
  isDarkPin: true,
};

const hotspot8: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '34%',
    y: '60%',
  },
  markerPositionMobile: {
    x: '30%',
    y: '45%',
  },
  type: 'story',
  title: `<span><i>Howard</i></span> Agency <i>Dept.</i>`,
  cleanTitle: 'Howard Agency Dept',
  titlePreview: `<span><i>Howard</i></span><br> Agency <i>Dept.</i>`,
  description: `Sara Howard, one of New York's first female detectives, set up an office for her and her staff of investigators at 808 Broadway.`,
  thumbnail: {
    src: hotspot8Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot8Video,
        alt: '',
      },
    ],
    hiddenContent: {
      src: hotspot8Thumbnail,
      alt: '',
    },
    isVideo: true,
  },
};

const hotspot7: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '21%',
    y: '32%',
  },
  markerPositionMobile: {
    x: '9%',
    y: '29%',
  },
  type: 'story',
  title: `<i><span>Gansevoort</span></i> Market`,
  cleanTitle: 'Gansevoort Market',
  titlePreview: `<i><span>Gansevoort</span></i><br> Market`,
  description: `The team discovers some dark secrets about the beginnings of Libby's criminal ways.`,
  thumbnail: {
    src: hotspot7Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot7Thumbnail,
        alt: '',
      },
    ],
  },
  isDarkPin: true,
  closeButtonBlack: false,
};

const westsideLocationData: MapLocation = {
  bg: bgMap,
  bgMobile: bgMapMobile,
  path: '/westside',
  name: 'Westside',
  cleanName: 'Westside',
  shortName: 'Westside',
  shortNameClass: 'Westside',
  description: `Home to beautiful archticture, lavish residential culture, community markets and the city's entertainment center.`,
  backgroundImagePath: '../',
  hotspots: {
    mobile: [hotspot5, hotspot2, hotspot1, hotspot6, hotspot7],
    desktop: [hotspot1, hotspot2, hotspot3, hotspot4, hotspot6, hotspot8, hotspot5, hotspot7],
  },
  shareHotspot,
};

export { westsideLocationData };
