import { ImageEffectRenderer } from 'seng-effectrenderer';
import getCoverSize from '@agilie/canvas-image-cover-position';
import gsap from 'gsap';

const shader = `
  uniform float delta;

  float smooth = 24.0;

  void mainImage( out vec4 fragColor, in vec2 fragCoord ) {
    vec2 uv = fragCoord.xy / iResolution.xy;
    uv.y = 1.0 - uv.y;

    vec4 color = texture(iChannel0, uv).rgba;

    float colorGrad = texture(iChannel1, uv).r;

    color.rgb *= color.a;
    fragColor = color * smoothstep( colorGrad - 1.0 / smooth, colorGrad, (delta * (1.0 + 1.0 / smooth) - 1.0 / smooth));
  }
`;

const transitionSpeed = 0.25;
let currentTransitionTime = 0;
let ticker = gsap.ticker;
let onTickerEnd: () => void;
let renderer: ImageEffectRenderer | null;

const loadImage = (imageSrc: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.crossOrigin = 'Anonymous';
    image.src = imageSrc;
  });
};

const loadImages = (images: Array<string>): Promise<Array<HTMLImageElement>> => {
  return Promise.all(images.map((src) => loadImage(src)));
};

const setCanvasStyle = (canvas: HTMLCanvasElement) => {
  canvas.style.top = '0';
  canvas.style.right = '0';
  canvas.style.bottom = '0';
  canvas.style.left = '0';
  canvas.style.position = 'fixed';
  canvas.style.zIndex = '5';
};

const createBackgroundCanvas = (image: HTMLImageElement, canvas: HTMLCanvasElement) => {
  const { offsetLeft, offsetTop, width, height } = getCoverSize(
    image.naturalWidth,
    image.naturalHeight,
    canvas.width,
    canvas.height,
  );

  const coverCanvas = document.createElement('canvas');
  coverCanvas.width = canvas.width;
  coverCanvas.height = canvas.height;

  const context = coverCanvas.getContext('2d');
  context?.drawImage(image, offsetLeft, offsetTop, width, height);

  return coverCanvas;
};

const handleTick = (time: number, deltaTime: number, frame: number) => {
  currentTransitionTime += deltaTime * transitionSpeed;
  renderer!.setUniformFloat('delta', currentTransitionTime / 1000);
  renderer!.draw();

  if (currentTransitionTime > 400) {
    // the transition delta should technically go from 0 to 1,
    // but the shader is finishing the transition before .4
    onTickerEnd();
  }
};

export const transitionWithShader = async (
  images: Array<string>,
  action: () => void,
  onComplete: () => void,
  zIndex: number = 5,
) => {
  const element = document.querySelector('#transitionCanvas') as HTMLElement | null;

  if (images.length === 0 || renderer || !element) return;

  if (zIndex) element.style.zIndex = `${zIndex}`;

  renderer = ImageEffectRenderer.createTemporary(element, shader, false, true);

  const canvas = renderer.getCanvas();
  setCanvasStyle(canvas);

  const [image1, image2] = await loadImages(images);
  const backgroundCanvas = createBackgroundCanvas(image1, canvas);
  renderer.addImage(backgroundCanvas, 0);
  renderer.addImage(image2, 1);

  element.style.pointerEvents = 'auto';

  onTickerEnd = () => {
    ticker.remove(handleTick);
    renderer = null;
    currentTransitionTime = 0;

    gsap.to(canvas, 2.5, {
      autoAlpha: 0,
      onStart: () => {
        action();
        element.style.pointerEvents = 'none';
      },
      onComplete: () => {
        onComplete();
        canvas.remove();
      },
    });
  };

  ticker.tick(); // intentional tick to avoid first delta time to be equal to time
  ticker.add(handleTick);
};
