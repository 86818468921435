import React, { FunctionComponent, useState, useRef, useCallback, useEffect } from 'react';

import classNames from 'classnames';
import styles from './VideoPlayer.module.scss';

import { CustomCursorState } from '../CustomCursor/CustomCursor';
import { useDeviceState } from '../../hooks/useDeviceState';
import { RoundedCta } from '../RoundedCta/RoundedCta';

interface VideoPlayerProps {
  src: string;
  visiblePosterSrc?: string;
  isModalMobile?: boolean;
  buttonStyles?: string;
  onFirstPlay?: () => void;
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  src,
  visiblePosterSrc,
  isModalMobile,
  buttonStyles,
  onFirstPlay,
}) => {
  const { isTouch } = useDeviceState();

  const [isActive, setIsActive] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [cursorText, setCursorText] = useState('<i>Play</i>');

  const toggleVideoState = () => {
    if (videoRef.current?.paused) {
      playVideo();
    } else {
      pauseVideo();
    }
  };

  const pauseVideo = useCallback(() => {
    videoRef.current?.pause();
    setCursorText('<i>Play</i>');
  }, [videoRef]);

  const playVideo = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    if (!isActive && onFirstPlay) {
      onFirstPlay();
    }

    setCursorText('<i>Pause</i>');
    videoRef.current.play();
    setIsActive(true);
  }, [videoRef, isActive, onFirstPlay]);

  useEffect(() => {
    setIsActive(false);
    pauseVideo();

    if (!videoRef.current) return;
    videoRef.current.src = src;
  }, [src, pauseVideo]);

  return (
    <>
      <div className={classNames('background', isModalMobile && styles.videoWrapper)}>
        <video
          ref={videoRef}
          className={classNames(styles.isActive, styles.video)}
          onEnded={() => {
            pauseVideo();
            setIsActive(false);
          }}
          playsInline
        >
          <source src={src} type="video/mp4" />
        </video>

        {!isTouch ? (
          <button className={classNames(styles.playOverlay)} onClick={toggleVideoState}>
            <div
              className={classNames(styles.poster, !isActive && styles.show)}
              style={{ backgroundImage: `url(${visiblePosterSrc})` }}
              data-custom-cursor-state={CustomCursorState.Action}
              data-custom-cursor-text={cursorText}
            ></div>
          </button>
        ) : (
          <>
            <button
              className={classNames(
                styles.circleButton,
                buttonStyles || styles.defaultCircleButton,
                (!videoRef.current || videoRef.current?.paused) && styles.isVisible,
              )}
              onClick={toggleVideoState}
            >
              <RoundedCta copy={cursorText || 'Play'} />
            </button>
            <div className={classNames(styles.maskedPoster, !isActive && styles.show)}>
              <div
                className={classNames(styles.poster, !isActive && styles.show)}
                style={{ backgroundImage: `url(${visiblePosterSrc})` }}
              ></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
