import React from 'react';
import { Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { useDeviceState } from '../../hooks/useDeviceState';

export interface WithSlideTransitionProps {
  isVisible: boolean;
  endTransition: () => void;
}

export const withSlideTransition = <T extends WithSlideTransitionProps>(
  WrappedComponent: React.FC<T>,
) => (props: T) => {
  const { deviceState } = useDeviceState();
  const { state } = useLocation<{ fromRight: boolean }>();
  const fromRight = state ? state.fromRight : false;

  return (
    <Transition
      unmountOnExit
      in={props.isVisible}
      onEnter={(node: HTMLElement) =>
        gsap.set(node, {
          [deviceState >= 2 ? 'x' : 'y']: fromRight ? '100%' : '-100%',
        })
      }
      addEndListener={(node, done) => {
        gsap.to(node, 2, {
          [deviceState >= 2 ? 'x' : 'y']: props.isVisible ? '0%' : fromRight ? '-100%' : '100%',
          ease: 'expo.inOut',
          onComplete: () => {
            done();
            props.endTransition();
          },
        });
      }}
    >
      <WrappedComponent {...props} />
    </Transition>
  );
};
