import React from 'react';
import classNames from 'classnames';

import styles from './LocationSelectorMobile.module.scss';
import { LocationSelectorProps } from './LocationSelector';

const LocationSelectorMobile: React.FC<LocationSelectorProps> = ({
  activeElementIndex,
  locationItems,
  setLocation,
  isActive,
}) => {
  return (
    <div className={classNames(styles.locationSelector, isActive && styles.isActive)}>
      <ul>
        {locationItems.map((locationItem, index) => {
          return (
            <li
              className={classNames(
                styles.item,
                activeElementIndex === index ? styles.isActive : '',
                styles[locationItem.shortNameClass],
              )}
              key={index}
            >
              <div onClick={() => setLocation(index)}>
                <span dangerouslySetInnerHTML={{ __html: locationItem.shortName }}></span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { LocationSelectorMobile };
