import React, { useState, useEffect, useRef } from 'react';
import { Postcard } from '../../../Postcard/Postcard';
import { availablePostcards } from '../../../../data/postcards';

import styles from './PostcardMaker.module.scss';
import classNames from 'classnames';
import { useDeviceState } from '../../../../hooks/useDeviceState';
import { Modal, ModalProps } from '../Modal/Modal';
import { rootPath } from '../../../../data/enum/RoutePaths';
import image from '../../../../assets/images/postcards/postcard1_full.jpg';
import { useHistory } from 'react-router-dom';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';
import {
  handlePostcardViewAnalytics,
  handlePostcardLinkCopyAnalytics,
} from '../../../../utils/analyticsWrapper';

interface PostcardMakerProps extends ModalProps {
  currentLocation: string;
}

const PostcardMaker: React.FC<PostcardMakerProps> = ({
  isVisible,
  closeClick,
  currentLocation,
}) => {
  const { isMobile, isTouch } = useDeviceState();
  const { push, location } = useHistory();

  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [activePostcardIndex, setActivePostcardIndex] = useState(0);
  const [shareUrl, setShareUrl] = useState('');

  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);

  const [showCopiedToClipboardMessage, setCopiedToClipboardMessageVisibility] = useState(false);

  const urlInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const newUrl = new URL(window.location.origin + rootPath);

    push(location.pathname, { hiddenHeader: isVisible ? true : false });

    newUrl.searchParams.append('from', fromValue);
    newUrl.searchParams.append('to', toValue);
    newUrl.searchParams.append('postcard', activePostcardIndex.toString());
    newUrl.searchParams.append('cid', 'postcard');

    setShareUrl(newUrl.href);
  }, [fromValue, toValue, activePostcardIndex, shareUrl, isVisible, location.pathname, push]);

  const copyUrlToClipboard = () => {
    if (!urlInputRef.current || fromError || toError) return;

    urlInputRef.current.select();
    urlInputRef.current.setSelectionRange(0, 99999); // for mobile devices

    document.execCommand('copy');
    setCopiedToClipboardMessageVisibility(true);

    handlePostcardLinkCopyAnalytics(currentLocation);
  };

  useEffect(() => {
    !fromValue ? setFromError(true) : setFromError(false);
    !toValue ? setToError(true) : setToError(false);
  }, [fromValue, toValue]);

  useEffect(() => {
    if (showCopiedToClipboardMessage) {
      setTimeout(() => {
        setCopiedToClipboardMessageVisibility(false);
      }, 1500);
    }
  }, [showCopiedToClipboardMessage]);

  useEffect(() => {
    if (isVisible) {
      handlePostcardViewAnalytics(currentLocation);
    }
  }, [isVisible, currentLocation]);

  return (
    <Modal isPostcard isVisible={isVisible} closeClick={closeClick}>
      <div className={styles.postcardMakerWrapper}>
        <div className={styles.postcardWrapper}>
          <Postcard
            fromValue={fromValue}
            toValue={toValue}
            images={availablePostcards.map((postcard) => postcard.imageSrc)}
            activeImageIndex={activePostcardIndex}
            mainCopy={availablePostcards[activePostcardIndex].copy}
            location={availablePostcards[activePostcardIndex].location}
            onActivePostcardChange={setActivePostcardIndex}
            canEdit={true}
          />
        </div>
        <div className={styles.backgroundImageWrapper}>
          <img src={image} alt="" />
          {isTouch && <span className={styles.mobileOverlay} />}
        </div>
        {isMobile && (
          <div className={styles.selectorButtonWrapper}>
            {availablePostcards.map((postcard, index) => (
              <button
                className={classNames(
                  styles.postcardSelectorButton,
                  activePostcardIndex === index && styles.isActive,
                )}
                onClick={() => setActivePostcardIndex(index)}
                key={index}
              ></button>
            ))}
          </div>
        )}

        <div
          className={classNames(styles.copiedCopy, showCopiedToClipboardMessage && styles.isActive)}
        >
          <span>
            <i>
              Link copied to
              <br />
              your
            </i>{' '}
            clipboard
          </span>
        </div>

        <div className={styles.infoWrapper}>
          <div className="texture">
            <div className={styles.titleWrapper}>
              <h2 className={styles.title}>
                NY <i>in the time of THE ALIENIST</i>
              </h2>
            </div>

            <div className={styles.contentWrapper}>
              <span className={styles.caption}>Postcard</span>

              <div className={styles.formWrapper}>
                <p className={styles.copy}>Create and share a postcard for your friend!</p>

                <div className={styles.inputsWrapper}>
                  <div className={styles.inputWrapper}>
                    <label>From:</label>
                    <input
                      placeholder="FILL WITH YOUR NAME"
                      value={fromValue}
                      onChange={(event) => setFromValue(event.target.value)}
                      maxLength={15}
                      className={classNames(fromError && styles.inputError)}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <label>To:</label>
                    <input
                      placeholder="FILL WITH A NAME"
                      value={toValue}
                      onChange={(event) => setToValue(event.target.value)}
                      maxLength={15}
                      className={classNames(toError && styles.inputError)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.shareWrapper}>
              <input ref={urlInputRef} value={shareUrl} readOnly></input>
              <div className={styles.buttonWrapper}>
                <button onClick={copyUrlToClipboard}>
                  <span data-custom-cursor-state={CustomCursorState.Pointer}>
                    Click here to copy the link
                  </span>{' '}
                  and share it with your friend!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { PostcardMaker };
