import React, { useEffect } from 'react';

import './EmailSignUpForm.scss';

const scriptSrc = 'https://dnsl4xr6unrmf.cloudfront.net/js/campaign.js';

const EmailSignUpForm: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      className={'emailSignUpWrapper'}
      id="wyng-experience"
      data-wyng-id="5f0cc720ce87c4003ee44443"
      data-wyng-token="sPkiAnWBjXHvfYHbyWwtxCqhrwqVPNsHQOjbenQF5f0cc7218004490087a370a0"
    ></div>
  );
};

export { EmailSignUpForm };
