import React from 'react';

import ReactCompareImage from 'react-compare-image';

import styles from './ImageComparerMobile.module.scss';
import { ImageComparerProps } from './ImageComparer';

const ImageComparerMobile: React.FC<ImageComparerProps> = ({ visibleContent, hiddenContent }) => {
  return (
    <div className={styles.imageComparer}>
      <div className={styles.imageComparerWrapper}>
        <ReactCompareImage
          handle={<button className={styles.handle}></button>}
          leftImageCss={{ minHeight: '250px' }}
          rightImageCss={{ minHeight: '250px' }}
          sliderLineWidth={1}
          leftImage={visibleContent.src}
          rightImage={hiddenContent.src}
        />
      </div>
    </div>
  );
};

export { ImageComparerMobile };
