import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';
import { Extras } from '../../types/extrasTypes';
import { TrailerPlayer } from './TrailerPlayer';
import gsap from 'gsap';
import { useDeviceState } from '../../hooks/useDeviceState';
import { handlExtrasVideoPlayAnalytics } from '../../utils/analyticsWrapper';

interface ExtrasPlayerProps {
  isVisible: boolean;
  video: Extras;
  onClose: () => void;
}

export const ExtrasPlayer: FunctionComponent<ExtrasPlayerProps> = ({
  isVisible,
  video,
  onClose,
}) => {
  const { deviceState } = useDeviceState();

  const initialState = (node: HTMLElement) => {
    gsap.set(node, { autoAlpha: 0 });
  };

  const transitionElement = (node: HTMLElement, done: () => void) => {
    gsap.to(node, {
      autoAlpha: isVisible ? 1 : 0,
      duration: isVisible ? 0.75 : 0.25,
      delay: isVisible ? 1.25 : 0,
      ease: 'expo.inOut',
      onComplete: done,
    });
  };

  return (
    <Transition
      unmountOnExit
      in={isVisible}
      onEnter={initialState}
      addEndListener={transitionElement}
    >
      <TrailerPlayer
        canPlayVideo
        src={video.video}
        visiblePosterSrc={deviceState < 2 ? video.poster.mobile : video.poster.desktop}
        hiddenPosterSrc=""
        cursorText="Play"
        onActiveChange={(isActive: boolean) => !isActive && onClose()}
        title={video.description}
        onFirstPlay={() => handlExtrasVideoPlayAnalytics(video.cleanDescription)}
      />
    </Transition>
  );
};
