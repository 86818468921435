import React, { FunctionComponent, useEffect, useRef } from 'react';
import classNames from 'classnames';
import gsap from 'gsap';
import styles from './BackgroundImage.module.scss';

export type BackgroundImageType = 'homepage' | 'episodes' | 'discover' | 'extras' | '';

interface BackgroundImageProps {
  isVisible: boolean;
  background: BackgroundImageType;
}

export const BackgroundImage: FunctionComponent<BackgroundImageProps> = ({
  isVisible,
  background,
}) => {
  const backgroundImageRef = useRef(null);

  const handleMouseMove = ({ clientX, clientY }: MouseEvent) => {
    gsap.to(backgroundImageRef.current, 1, {
      x: clientX * 1,
      y: clientY * 0.25,
    });
  };

  useEffect(() => {
    gsap.to(backgroundImageRef.current, 0.25, {
      autoAlpha: isVisible ? 0.2 : 0,
      ease: 'expo.easeInOut',
    });
  }, [isVisible]);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return <div ref={backgroundImageRef} className={classNames(styles.image, styles[background])} />;
};
