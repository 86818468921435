import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import { CustomCursorState } from '../CustomCursor/CustomCursor';

import { RoutePaths } from '../../data/enum/RoutePaths';
import { useHistory, useLocation } from 'react-router-dom';

import inkTransition from '../../assets/images/ink-transition3.jpg';
import heroBackground from '../../assets/images/landing/hero/background.jpg';
import heroMobileBackground from '../../assets/images/landing/hero/background-mobile.jpg';

import gsap from 'gsap';
import { SplitText } from '../../vendor/SplitText';

import classNames from 'classnames';
import styles from './SiteHeader.module.scss';

import { SiteNavigation } from '../SiteNavigation/SiteNavigation';

import { useDeviceState } from '../../hooks/useDeviceState';
import { transitionWithShader } from '../../utils/transitionWithShader';
import { handleWatchShowLinkClickAnalytics } from '../../utils/analyticsWrapper';

interface SiteHeaderProps {
  isNavigationOpen: boolean;
  onMenuOpen?: (isOpened: boolean) => void;
}

export const SiteHeader: FunctionComponent<SiteHeaderProps> = ({
  isNavigationOpen,
  onMenuOpen,
}) => {
  const { isTouch, deviceState } = useDeviceState();
  const { push, location } = useHistory();

  const menuCopyRef = useRef<HTMLParagraphElement>(null);
  const logoRef = useRef<HTMLButtonElement>(null);
  const lineRef = useRef<HTMLSpanElement>(null);
  const episodesLinkRef = useRef<HTMLAnchorElement>(null);
  const headerRef = useRef<HTMLHeadElement>(null);
  const menuIconRef = useRef<HTMLSpanElement>(null);

  const { state, pathname } = useLocation<{ noTransition: boolean; hiddenHeader: boolean }>();
  const locationState = useRef(state);

  const [isHidden, setIsHidden] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(pathname === RoutePaths.EXTRAS_CONTENT);

  const handleTransitions = () => {
    if (locationState.current?.noTransition || !menuCopyRef.current) return;

    const timeline = gsap.timeline();

    const splitMenuCopy = new SplitText(menuCopyRef.current, { type: 'words, chars' });

    timeline.fromTo(
      splitMenuCopy.chars,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.1,
        duration: 0.6,
        ease: 'power2.in',
      },
      0,
    );
  };

  const transitionFromLogo = () => {
    onMenuOpen && onMenuOpen(false);

    if (
      location.pathname === RoutePaths.LANDING_HERO ||
      location.pathname === RoutePaths.LANDING_ABOUT ||
      location.pathname === RoutePaths.LANDING_TRAILER
    ) {
      return;
    }

    transitionWithShader(
      [isTouch ? heroMobileBackground : heroBackground, inkTransition],
      () => push(RoutePaths.LANDING, { noTransition: true }),
      () => {},
      5,
    );
  };

  useEffect(() => {
    if (!headerRef.current) return;

    const timeline = gsap.timeline();

    timeline.to(
      headerRef.current,
      {
        opacity: 1,
        duration: 0,
        ease: 'power2.in',
      },
      0,
    );

    if (locationState.current?.noTransition) return;

    timeline.fromTo(
      logoRef.current,
      {
        opacity: 0,
        y: 5,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: 'power2.in',
      },
      0,
    );

    lineRef.current &&
      timeline.fromTo(
        lineRef.current,
        {
          opacity: 0,
          translateX: -100,
        },
        {
          opacity: 1,
          translateX: 0,
          duration: 0.8,
          ease: 'power2.in',
        },
        0,
      );

    timeline.fromTo(
      episodesLinkRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.8,
        ease: 'power2.in',
      },
      0,
    );

    const timelineMenuIcon = gsap.timeline({ delay: 0.5 });
    gsap.set(menuIconRef.current, {
      clipPath: 'polygon(0 50%, 30% 0, 30% 0, 0 50%, 0% 50%, 0% 100%, 0 100%)',
    });
    timelineMenuIcon.fromTo(
      menuIconRef.current,
      { clipPath: 'polygon(0 50%, 30% 0, 30% 0, 0 50%, 0% 50%, 0% 100%, 0 100%)' },
      {
        clipPath: 'polygon(0 50%, 30% 0, 100% 0, 85% 50%, 0% 50%, 0% 100%, 0 100%)',
        duration: 0.2,
      },
    );
    timelineMenuIcon.to(menuIconRef.current, {
      clipPath: 'polygon(0 50%, 30% 0, 100% 0, 100% 50%, 100% 50%, 80% 100%, 0 100%)',
      duration: 0.2,
    });
  }, []);

  const animateMenuIcon = () => {
    if (isNavigationOpen) {
      const timelineMenuIcon = gsap.timeline();
      gsap.set(menuIconRef.current, {
        clipPath: 'polygon(0 50%, 30% 0, 30% 0, 0 50%, 0% 50%, 0% 100%, 0 100%)',
      });
      timelineMenuIcon.fromTo(
        menuIconRef.current,
        { clipPath: 'polygon(0 50%, 30% 0, 30% 0, 0 50%, 0% 50%, 0% 100%, 0 100%)' },
        {
          clipPath: 'polygon(0 50%, 30% 0, 100% 0, 85% 50%, 0% 50%, 0% 100%, 0 100%)',
          duration: 0.2,
        },
      );
      timelineMenuIcon.to(menuIconRef.current, {
        clipPath: 'polygon(0 50%, 30% 0, 100% 0, 100% 50%, 100% 50%, 80% 100%, 0 100%)',
        duration: 0.2,
      });
    }
  };

  useEffect(() => {
    state && setIsHidden(state.hiddenHeader);
    setIsDarkMode(pathname === RoutePaths.EXTRAS_CONTENT);
  }, [pathname, state]);

  useEffect(() => {
    handleTransitions();
  }, [isNavigationOpen]);

  const handleWatchTheEpisodesClick = () => {
    handleWatchShowLinkClickAnalytics(isNavigationOpen ? 'Menu' : 'Landing');
  };

  return (
    <header
      ref={headerRef}
      className={classNames(
        styles.siteHeader,
        isDarkMode && deviceState >= 2 && styles.hasDarkMode,
        isHidden && styles.isHidden,
      )}
    >
      <SiteNavigation
        isOpen={isNavigationOpen}
        onExitTransition={() => onMenuOpen && onMenuOpen(false)}
      />

      <div className={styles.wrapper}>
        <div className={styles.nav}>
          <button
            className={classNames(styles.menuButton, isHidden && styles.isHidden)}
            data-custom-cursor-state={CustomCursorState.Pointer}
            onClick={() => {
              animateMenuIcon();
              onMenuOpen && onMenuOpen(!isNavigationOpen);
            }}
          >
            <span
              ref={menuIconRef}
              className={classNames(styles.menuIcon, isNavigationOpen && styles.isNavigationOpen)}
            />
            {deviceState >= 2 && (
              <p
                ref={menuCopyRef}
                className={classNames(styles.menuCopy, 'globalButton02 texture')}
              >
                {isNavigationOpen ? 'Close' : 'Menu'}
              </p>
            )}
          </button>

          <button
            ref={logoRef}
            className={classNames(styles.logo, isHidden && styles.isHidden)}
            data-custom-cursor-state={CustomCursorState.Pointer}
            onClick={transitionFromLogo}
          />

          <span className={styles.linkWrapper}>
            {deviceState >= 4 && (
              <span className={styles.lineWrapper}>
                <span ref={lineRef} className={classNames(styles.line, 'texture')}></span>
              </span>
            )}
            <a
              ref={episodesLinkRef}
              href="https://tnt.app.link/tcpNOeSqX7"
              target="_blank"
              rel="noopener noreferrer"
              className={classNames(styles.link, 'globalButton02', isHidden && styles.isHidden)}
              data-custom-cursor-state={CustomCursorState.Pointer}
              onClick={handleWatchTheEpisodesClick}
            >
              {deviceState >= 4 && <span className="texture">Watch the Episodes</span>}
              <span className={styles.tntLogo} />
            </a>
          </span>
        </div>
      </div>
    </header>
  );
};
