import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import gsap from 'gsap';

import classNames from 'classnames';
import styles from './SiteFooter.module.scss';

import { CustomCursorState } from '../CustomCursor/CustomCursor';

import { useDeviceState } from '../../hooks/useDeviceState';
import { doNotSellItHandler } from '../../vendor/DoNotSellIt';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from '../../data/enum/RoutePaths';

interface SiteFooterProps {}

export const SiteFooter: FunctionComponent<SiteFooterProps> = () => {
  const { deviceState } = useDeviceState();
  const { pathname } = useLocation();

  const backgroundRef = useRef<HTMLSpanElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const adChoicesAnchorRef = useRef<HTMLDivElement>(null);

  const [isDarkMode, setIsDarkMode] = useState(pathname === RoutePaths.EXTRAS_CONTENT);

  useEffect(() => {
    setIsDarkMode(pathname === RoutePaths.EXTRAS_CONTENT);
  }, [pathname]);

  useEffect(() => {
    const timeline = gsap.timeline();

    backgroundRef.current &&
      timeline.to(
        backgroundRef.current,
        {
          opacity: 0.7,
          duration: 0.6,
          ease: 'power2.in',
        },
        0,
      );

    listRef.current &&
      timeline.fromTo(
        listRef.current.children,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
          stagger: 0.2,
          ease: 'power2.out',
        },
        0.6,
      );
  }, []);

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src =
      'https://consent.truste.com/notice?domain=turner.com&amp;c=tnt_adchoices&amp;text=true&amp;country=us';
    script1.id = 'truste_0.4795974615887728';

    const script2 = document.createElement('script');
    script2.src = 'https://consent.trustarc.com/asset/notice.js/v/v1.7-14';
    script2.async = true;
    script2.crossOrigin = '';

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    script1.onload = updateCustomCursorOnChildren;
    script1.onload = updateCustomCursorOnChildren;
  }, []);

  // This is a hacky method to add the custom cursor attribute into the embedded element by an external script
  const updateCustomCursorOnChildren = () => {
    process.nextTick(() => {
      if (!adChoicesAnchorRef.current) {
        return;
      }

      if (adChoicesAnchorRef.current.children[0]) {
        adChoicesAnchorRef.current.children[0].setAttribute('data-custom-cursor-state', 'pointer');
      }
    });
  };

  return (
    <footer
      className={classNames(
        styles.siteFooter,
        isDarkMode && deviceState >= 2 && styles.hasDarkMode,
      )}
    >
      <div className={styles.wrapper}>
        {deviceState < 2 && <span ref={backgroundRef} className={styles.mobileBackground} />}
        <ul ref={listRef} className={styles.list}>
          <li className={styles.item}>
            {/* eslint-disable-next-line */}
            <div
              id="tnt_adchoices"
              // @ts-ignore
              consent="undefined"
              className={classNames(styles.link, 'globalButton02 texture')}
              data-custom-cursor-state={CustomCursorState.Pointer}
              ref={adChoicesAnchorRef}
            />
          </li>

          <li className={styles.item}>
            <a
              href="https://www.tntdrama.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              data-custom-cursor-state={CustomCursorState.Pointer}
              className={classNames(styles.link, 'globalButton02 texture')}
            >
              Terms of Use
            </a>
          </li>

          <li className={styles.item}>
            <a
              href="https://www.tntdrama.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              data-custom-cursor-state={CustomCursorState.Pointer}
              className={classNames(styles.link, 'globalButton02 texture')}
            >
              Privacy Policy
            </a>
          </li>

          <li className={styles.item}>
            <button
              type="button"
              onClick={() =>
                // @ts-ignore
                doNotSellItHandler(window.Drupal, window.jQuery, window.WM.UserConsent)
              }
              data-custom-cursor-state={CustomCursorState.Pointer}
              className={classNames(styles.link, 'globalButton02 texture')}
            >
              Do Not Sell My Personal Information
            </button>
          </li>
        </ul>
      </div>
    </footer>
  );
};
