import React, { useCallback } from 'react';
import { HotspotMarkerMobile } from './HotspotMarker/HotspotMarkerMobile';
import { HotspotModalMobile } from './HotspotModal/HotspotModalMobile';
import {
  withLocationTransition,
  WithLocationTransitionProps,
} from '../../hoc/withLocationTransition';
import classNames from 'classnames';

import styles from './NYMapMobile.module.scss';
import { PostcardMaker } from './PostcardMaker/PostcardMaker';
import { MapLocation } from '../../../types/nyMapTypes';

interface NYMapPropsMobile extends WithLocationTransitionProps {
  showActiveMarker: boolean;
  currentHotspotIndex: number;
  onPostcardMakerVisibilityToggle: (newState: boolean) => void;
  showModal: boolean;
  onModalClose: () => void;
  showPostcardMaker: boolean;
  location: MapLocation;
  onHotspotSelection: (hotspotIndex: number) => void;
  isTransitioning: boolean;
}

const NYMapMobile: React.FC<NYMapPropsMobile> = withLocationTransition(
  ({
    location,
    onHotspotSelection,
    showActiveMarker,
    currentHotspotIndex,
    showModal,
    onModalClose,
    showPostcardMaker,
    onPostcardMakerVisibilityToggle,
  }) => {
    const nextHotspot = useCallback(() => {
      const totalHotspotsCount = location.hotspots.mobile.length;
      if (onHotspotSelection) {
        onHotspotSelection((currentHotspotIndex + 1) % totalHotspotsCount);
      }
    }, [currentHotspotIndex, onHotspotSelection, location.hotspots.mobile.length]);

    const previousHotspot = useCallback(() => {
      const totalHotspotsCount = location.hotspots.mobile.length;
      if (onHotspotSelection) {
        onHotspotSelection((currentHotspotIndex + totalHotspotsCount - 1) % totalHotspotsCount);
      }
    }, [currentHotspotIndex, onHotspotSelection, location.hotspots.mobile.length]);

    return (
      <>
        <div
          className={classNames(
            styles.mapMainWrapper,
            showActiveMarker && styles.isSliderOpen,
            styles[location.shortName],
          )}
        >
          <div className={styles.mapMain}>
            <div className={styles.hotspots}>
              {location.hotspots.mobile.map((hotspotData, index) => {
                return (
                  <HotspotMarkerMobile
                    key={index}
                    isActive={currentHotspotIndex === index && showActiveMarker}
                    copy={(index + 1).toString()}
                    hotspotData={hotspotData}
                    onMarkerClick={() => {
                      if (onHotspotSelection) {
                        onHotspotSelection(index);
                      }
                    }}
                  />
                );
              })}
              <HotspotMarkerMobile
                copy=""
                hotspotData={location.shareHotspot}
                onMarkerClick={() => onPostcardMakerVisibilityToggle(true)}
              />
            </div>

            <img
              alt={location.name}
              className={classNames(styles.bgMap, styles[location.shortName])}
              src={location.bgMobile}
            />
          </div>
        </div>

        <span className={styles.selectorCopy}>
          Choose ONE HOTSPOT
          <br />
          To START EXPLORING
        </span>

        <PostcardMaker
          isVisible={showPostcardMaker}
          closeClick={() => onPostcardMakerVisibilityToggle(false)}
          currentLocation={location.cleanName}
        />

        <HotspotModalMobile
          currentLocation={location.cleanName}
          isVisible={showModal}
          currentHotspotCopy={currentHotspotIndex + 1}
          totalHotspotsCopy={location.hotspots.mobile.length}
          hotspotData={location.hotspots.mobile[currentHotspotIndex]}
          previousHotspotClick={previousHotspot}
          nextHotspotClick={nextHotspot}
          closeClick={onModalClose}
        />
      </>
    );
  },
);

export { NYMapMobile };
