import React, { useEffect, useRef } from 'react';

import gsap from 'gsap';
import styles from './Trailer.module.scss';

import {
  handleTrailerPageViewAnalytics,
  handleTrailerStartAnalytics,
} from '../../../../utils/analyticsWrapper';

import { TrailerPlayer } from '../../../VideoPlayer/TrailerPlayer';

import backgroundMobileVisible from '../../../../assets/images/landing/trailer/background-mobile.jpg';
import backgroundDesktopVisible from '../../../../assets/images/landing/trailer/background-desktop.jpg';
import backgroundHidden from '../../../../assets/images/landing/trailer/background-hidden.jpg';
import video from '../../../../assets/videos/trailer.mp4';

import { useLocation } from 'react-router-dom';
import { useDeviceState } from '../../../../hooks/useDeviceState';

interface TrailerProps {
  canPlayVideo: boolean;
}

export const Trailer: React.FC<TrailerProps> = ({ canPlayVideo }) => {
  const { deviceState, isMobile } = useDeviceState();

  const maskRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { state } = useLocation<{ noTransition: boolean }>();
  const locationState = useRef(state);

  const handleVideoPlay = () => {
    handleTrailerStartAnalytics();
  };

  const handleVideoActiveChange = (isActive: boolean) => {
    if (isMobile) {
      wrapperRef.current!.style.zIndex = isActive ? '12' : '0';
    } else {
      wrapperRef.current!.style.zIndex = isActive ? '1' : '0';
    }
  };

  useEffect(() => {
    handleTrailerPageViewAnalytics();
  }, []);

  useEffect(() => {
    if (locationState.current?.noTransition) {
      gsap.set(maskRef.current, { autoAlpha: 0 });
      return;
    }

    const timeline = gsap.timeline();

    timeline.to(
      maskRef.current,
      {
        opacity: 0,
        duration: 0.6,
        ease: 'power2.in',
      },
      0.2,
    );
  }, []);

  return (
    <div className={styles.trailer} ref={wrapperRef}>
      <div ref={maskRef} className={styles.mask} />
      <TrailerPlayer
        src={video}
        cursorText="Watch</br><i>The trailer</i>"
        visiblePosterSrc={deviceState < 2 ? backgroundMobileVisible : backgroundDesktopVisible}
        hiddenPosterSrc={backgroundHidden}
        buttonStyles={styles.mobileCta}
        onFirstPlay={handleVideoPlay}
        onActiveChange={handleVideoActiveChange}
        canPlayVideo={canPlayVideo}
        title="Official <i>Trailer</i>"
      />
    </div>
  );
};
