import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

import 'typeface-roboto-mono';
import 'typeface-cormorant';

import bowser from 'bowser';

const rootElement = document.getElementById('root');

// Add browser and platform attributes for scss mixins
const bowserData = bowser.getParser(window.navigator.userAgent);
rootElement.setAttribute('data-browser', bowserData.getBrowserName());
rootElement.setAttribute('data-device', bowserData.getPlatformType());

// Set root height
rootElement.style.height = `${window.innerHeight}px`;

window.addEventListener('resize', () => {
  rootElement.style.height = `${window.innerHeight}px`;
});

ReactDOM.render(<App dev="true" />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export { bowserData };
