import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import { withSlideTransition, WithSlideTransitionProps } from '../../../hoc/withSlideTransition';
import classNames from 'classnames';

import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import gsap from 'gsap';

import styles from './Slider.module.scss';

import { Slide } from '../Slide/Slide';

import { Extras } from '../../../../types/extrasTypes';
import { extrasContent } from '../../../../data/extrasData';

import { handlExtrasVideoViewAnalytics } from '../../../../utils/analyticsWrapper';

import { useSwipeable, EventData } from 'react-swipeable';
import { useDeviceState } from '../../../../hooks/useDeviceState';
import { RoundedCta } from '../../../RoundedCta/RoundedCta';
import { ExtrasPlayer } from '../../../VideoPlayer/ExtrasPlayer';
import { useLocation } from 'react-router-dom';

export const Slider: FunctionComponent<WithSlideTransitionProps> = withSlideTransition(
  ({ isVisible }) => {
    const [isVideoActive, setIsVideoActive] = useState(false);
    const [activeVideo, setActiveVideo] = useState<Extras>(extrasContent[0]);

    const [itemWidth, setItemWidth] = useState(0);
    const [itemMargin, setItemMargin] = useState(0);

    const [yScrolled, setYScrolled] = useState(0);
    const [xScrolled, setXScrolled] = useState(0);
    const [maxX, setMaxX] = useState(0);

    const backgroundCircleRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLUListElement>(null);
    const barRef = useRef<HTMLDivElement>(null);
    const thumbRef = useRef<HTMLSpanElement>(null);
    const mobileBarRef = useRef<HTMLDivElement>(null);
    const mobileThumbRef = useRef<HTMLSpanElement>(null);
    const mobileHeadingRef = useRef<HTMLDivElement>(null);
    const mobileButtonRef = useRef<Array<HTMLButtonElement | null>>([]);

    const { isTouch, deviceState } = useDeviceState();
    const { state } = useLocation();

    const swipeHandlers = useSwipeable({
      onSwipedLeft: (event) => swipeHandler(event),
      onSwipedRight: (event) => swipeHandler(event),
    });

    const wheelHandler = (event: React.WheelEvent) => {
      event.persist();

      const _wheelHandler = throttle(() => {
        const amountToScroll =
          event.deltaY >= -0
            ? Math.max(event.deltaY, event.deltaX)
            : Math.min(event.deltaY, event.deltaX);

        if (xScrolled + amountToScroll < 0 || xScrolled + amountToScroll > maxX) return;

        setXScrolled(xScrolled + amountToScroll);
      }, 2500);

      _wheelHandler();
    };

    const scrollHandler = (event: React.UIEvent) => {
      if (deviceState >= 2) return;

      event.persist();

      const _scrollHandler = throttle(() => {
        const sliderElement = event.target as HTMLElement;
        setYScrolled(sliderElement.scrollTop);
      }, 100);

      _scrollHandler();
    };

    const swipeHandler = (event: EventData) => {
      if (xScrolled + event.deltaX < 0) {
        return setXScrolled(0);
      } else if (xScrolled + event.deltaX > maxX) {
        return setXScrolled(maxX);
      }

      setXScrolled(xScrolled + event.deltaX);
    };

    const keyDownHandler = (event: React.KeyboardEvent) => {
      event.persist();

      const amountToScroll = (10 * maxX) / 100;

      if (event.key === 'ArrowRight') {
        setXScrolled(xScrolled + amountToScroll);
      } else if (event.key === 'ArrowLeft') {
        setXScrolled(xScrolled - amountToScroll);
      }
    };

    const resizeHandler = debounce(() => {
      const sliderElement = listRef.current;

      if (!sliderElement || !sliderElement.firstElementChild) return;

      if (deviceState >= 2) {
        setYScrolled(0);
      } else {
        gsap.to(listRef.current, { x: 0, duration: 1 });
      }

      const itemWidth = sliderElement.firstElementChild.getBoundingClientRect().width;
      const itemMargin = parseInt(getComputedStyle(sliderElement.firstElementChild).marginRight);

      setItemWidth(itemWidth);
      setItemMargin(itemMargin);
    }, 100);

    const onVideoPlayHandler = (event: React.MouseEvent, item: Extras) => {
      const initialRadius = (backgroundCircleRef.current?.clientWidth || 10) / 2;
      const finalRadius = calculateCircleRadius(event.pageX, event.pageY);

      handlExtrasVideoViewAnalytics(item.cleanDescription);

      gsap.fromTo(
        backgroundCircleRef.current,
        {
          x: event.pageX,
          y: event.pageY,
          autoAlpha: 1,
          scale: 0,
        },
        {
          scale: finalRadius / initialRadius,
          duration: 1,
          onStart: () => {
            setIsVideoActive(true);
            setActiveVideo(item);
          },
          onComplete: () => {
            gsap.to(backgroundCircleRef.current, {
              autoAlpha: 0,
              delay: 1,
            });
          },
        },
      );
    };

    const calculateCircleRadius = (x: number, y: number) => {
      const { innerWidth, innerHeight } = window;

      const distanceToX = Math.max(innerWidth - x, x);
      const distanceToY = Math.max(innerHeight - y, y);

      return Math.sqrt(Math.pow(distanceToX, 2) + Math.pow(distanceToY, 2));
    };

    useLayoutEffect(() => {
      const sliderElement = listRef.current;

      if (!sliderElement || !sliderElement.children) return;

      const sliderItems = sliderElement.children;
      const sliderWidth = sliderElement.getBoundingClientRect().width;

      setMaxX((itemWidth + itemMargin) * sliderItems.length - sliderWidth);
    }, [itemWidth, itemMargin]);

    const handleTransitions = useCallback(() => {
      const delay = state ? 1 : 0;
      const timeline = gsap.timeline({ delay });

      mobileButtonRef.current &&
        mobileButtonRef.current.forEach((element) => {
          timeline.fromTo(
            element,
            {
              opacity: isVisible ? 0 : 1,
            },
            {
              opacity: 1,
              duration: 0.8,
              ease: 'power2.in',
            },
            0.3,
          );
        });

      mobileHeadingRef.current &&
        timeline.fromTo(
          mobileHeadingRef.current,
          {
            opacity: isVisible ? 0 : 1,
            yPercent: isVisible ? -100 : 0,
          },
          {
            opacity: 1,
            yPercent: 0,
            duration: 0.8,
            ease: 'power2.inOut',
          },
          0,
        );

      mobileBarRef.current &&
        timeline.fromTo(
          mobileBarRef.current,
          {
            xPercent: isVisible ? -100 : 0,
          },
          {
            xPercent: 0,
            duration: 0.8,
            ease: 'power2.inOut',
          },
          0.3,
        );

      barRef.current &&
        timeline.fromTo(
          barRef.current,
          {
            opacity: isVisible ? 0 : 1,
          },
          {
            opacity: 1,
            duration: 0.6,
            ease: 'power2.in',
          },
          0.4,
        );
    }, [isVisible, state]);

    useEffect(() => {
      handleTransitions();
    }, [handleTransitions]);

    useEffect(() => {
      if (!barRef.current || !thumbRef.current) return;

      const barWidth = barRef.current.getBoundingClientRect().width;
      const thumbWidth = thumbRef.current.getBoundingClientRect().width;

      const diff = barWidth / thumbWidth - thumbWidth / 100;
      const percentageScrolled = (xScrolled * 100) / maxX;

      if (xScrolled !== 0 && xScrolled > maxX) {
        setXScrolled(maxX);
      }

      if (xScrolled < 0) {
        setXScrolled(0);
      }

      gsap.to(thumbRef.current, {
        x: percentageScrolled * diff,
        duration: 0.5,
      });

      gsap.to(listRef.current, {
        x: -xScrolled,
        duration: 1,
      });
    }, [xScrolled, maxX]);

    useEffect(() => {
      if (!listRef.current || !mobileThumbRef.current) return;

      const { scrollHeight, offsetHeight } = listRef.current;
      const percentageScrolled = (yScrolled * 100) / (scrollHeight - offsetHeight);

      gsap.to(mobileThumbRef.current, {
        width: `${percentageScrolled}%`,
        duration: 0.5,
      });
    }, [yScrolled]);

    useEffect(() => {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      return () => window.removeEventListener('resize', resizeHandler);
    }, [resizeHandler]);

    return (
      <>
        <div className={styles.slider}>
          <div ref={backgroundCircleRef} className={styles.backgroundCircle} />

          {deviceState < 2 && (
            <div ref={mobileHeadingRef} className={styles.mobileHeading}>
              <div ref={mobileBarRef} className={styles.mobileBar}>
                <span ref={mobileThumbRef} className={styles.mobileThumb} />
              </div>
            </div>
          )}

          <div
            tabIndex={0}
            className={styles.content}
            onWheel={wheelHandler}
            onScroll={scrollHandler}
            onKeyDown={keyDownHandler}
            {...swipeHandlers}
          >
            <ul className={styles.list} ref={listRef}>
              {extrasContent.map((item, index) => (
                <li className={styles.item} key={index}>
                  <Slide
                    current={index + 1}
                    total={extrasContent.length}
                    title={item.title}
                    description={item.description}
                    length={item.length}
                    image={item.image}
                    onClick={(event) => onVideoPlayHandler(event, item)}
                    isVisible={isVisible}
                  />
                  {isTouch && (
                    <button
                      ref={(el) => (mobileButtonRef.current[index] = el)}
                      className={classNames(styles.mobileButton)}
                      onClick={(event) => onVideoPlayHandler(event, item)}
                    >
                      <RoundedCta copy="Open<br><i>Video</i>" />
                    </button>
                  )}
                </li>
              ))}
            </ul>

            {deviceState >= 2 && (
              <div className={styles.bar} ref={barRef}>
                <span className={styles.thumb} ref={thumbRef} />
              </div>
            )}
          </div>
        </div>

        <div className={classNames(styles.playerWrapper, isVideoActive && styles.isActive)}>
          <ExtrasPlayer
            isVisible={isVideoActive}
            video={activeVideo}
            onClose={() => setIsVideoActive(false)}
          />
        </div>
      </>
    );
  },
);
