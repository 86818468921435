import React, { useEffect, useState, useCallback } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import throttle from 'lodash/throttle';
import { NYMap } from './NYMap';
import { NYMapMobile } from './NYMapMobile';
import { RoutePaths } from '../../../data/enum/RoutePaths';
import { nyData } from '../../../data/nyMapData';
import { useDeviceState } from '../../../hooks/useDeviceState';
import styles from './NYMapView.module.scss';
import { MapInformation } from './MapInformation/MapInformation';
import { LocationSelector } from './LocationSelector/LocationSelector';
import { LocationSelectorMobile } from './LocationSelector/LocationSelectorMobile';
import { handleMapViewAnalytics } from '../../../utils/analyticsWrapper';
import { useSwipeable } from 'react-swipeable';
import { HotspotSelector } from './HotspotSelector/HotspotSelector';

const locationItems = nyData.map((location) => ({
  name: location.name,
  cleanName: location.cleanName,
  shortName: location.shortName,
  shortNameClass: location.shortNameClass,
  description: location.description,
  href: RoutePaths.EXPLORE + location.path,
}));

const NYMapView: React.FC = () => {
  const { isTouch } = useDeviceState();

  const [isTransitioning, setIsTransitioning] = useState(false);

  const { location, push } = useHistory();
  const { state } = useLocation<{ noTransition: boolean }>();

  const [activeRouteIndex, setActiveRouteIndex] = useState(
    locationItems.findIndex((route) => route.href === location.pathname),
  );

  const [showPostcardMaker, setPostcardMakerVisibility] = useState(false);
  const [showHotspotModal, setHotspotModalVisibility] = useState(false);

  // mobile
  const [selectedHotspotMobile, setSelectedHotspotMobile] = useState(0);
  const [showHotspotModalMobile, setHotspotModalMobileVisibility] = useState(false);
  const [userHasSelectedHotspot, setUserHasSelectedHotspot] = useState(false); // the user has selected a hotspot manually (used in mobile)

  const getActiveIndex = useCallback(() => {
    const { pathname } = location;
    const splittedPathName = pathname.split(RoutePaths.EXPLORE)[1];
    return nyData.findIndex((location) => location.path === splittedPathName);
  }, [location]);

  const updateLocation = (indexOffset: number) => {
    if (isTransitioning || showHotspotModalMobile || showPostcardMaker || showHotspotModal) return;
    setIsTransitioning(true);
    setSelectedHotspotMobile(0);

    const currentRouteIndex = getActiveIndex();
    const newRouteIndex = (currentRouteIndex + indexOffset + nyData.length) % nyData.length;
    push(RoutePaths.EXPLORE + nyData[newRouteIndex].path);
    setActiveRouteIndex(newRouteIndex);
  };

  const setLocation = (index: number) => {
    if (isTransitioning || index === activeRouteIndex) return;
    setSelectedHotspotMobile(0);

    setIsTransitioning(true);
    push(RoutePaths.EXPLORE + nyData[index].path);
    setActiveRouteIndex(index);
  };

  const handleWheelScroll = (event: React.WheelEvent) => {
    event.persist();

    const _handleWheelScroll = throttle(() => {
      if (Math.abs(event.deltaY) >= 3) {
        updateLocation(-Math.sign(event.deltaY));
      }
    }, 100);

    _handleWheelScroll();
  };

  const swipeHandlers = useSwipeable({
    delta: 25,
    onSwipedUp: () => swipeHandler('up'),
    onSwipedDown: () => swipeHandler('down'),
  });

  const swipeHandler = (direction: 'up' | 'down') => {
    updateLocation(direction === 'up' ? +1 : -1);
  };

  useEffect(() => {
    const currentRouteIndex = getActiveIndex();
    setActiveRouteIndex(currentRouteIndex);

    if (!state || state.noTransition) {
      handleMapViewAnalytics(locationItems[currentRouteIndex].cleanName);
    }
  }, [getActiveIndex, state]);

  return isTouch ? (
    <div className={styles.mapMobile} {...swipeHandlers}>
      {nyData.map((location) => (
        <Route exact path={RoutePaths.EXPLORE + location.path} key={location.path}>
          {({ match }) => (
            <NYMapMobile
              currentHotspotIndex={selectedHotspotMobile}
              location={location}
              isVisible={match !== null}
              isTransitioning={isTransitioning}
              endTransition={() => setIsTransitioning(false)}
              showActiveMarker={userHasSelectedHotspot}
              onHotspotSelection={(hotspotIndex) => {
                setSelectedHotspotMobile(hotspotIndex);
                setUserHasSelectedHotspot(true);
                setHotspotModalMobileVisibility(true);
              }}
              onModalClose={() => setHotspotModalMobileVisibility(false)}
              showModal={showHotspotModalMobile}
              showPostcardMaker={showPostcardMaker}
              onPostcardMakerVisibilityToggle={setPostcardMakerVisibility}
            />
          )}
        </Route>
      ))}

      <HotspotSelector
        isVisible={userHasSelectedHotspot}
        activeHotspotIndex={selectedHotspotMobile}
        hotspots={nyData[activeRouteIndex].hotspots.mobile}
        onHotspotSelect={(hotspotIndex) => {
          if (hotspotIndex === selectedHotspotMobile) {
            setHotspotModalMobileVisibility(true);
          }
          setSelectedHotspotMobile(hotspotIndex);
        }}
      />

      <LocationSelectorMobile
        isActive={userHasSelectedHotspot}
        activeElementIndex={activeRouteIndex}
        locationItems={locationItems}
        setLocation={setLocation}
      />
    </div>
  ) : (
    <div className={styles.mapDesktop} onWheel={handleWheelScroll}>
      {nyData.map((location) => (
        <Route exact path={RoutePaths.EXPLORE + location.path} key={location.path}>
          {({ match }) => (
            <NYMap
              location={location}
              isVisible={match !== null}
              isTransitioning={isTransitioning}
              endTransition={() => setIsTransitioning(false)}
              showPostcardMaker={showPostcardMaker}
              onPostcardMakerVisibilityToggle={setPostcardMakerVisibility}
              onModalOpen={() => setHotspotModalVisibility(true)}
              onModalClose={() => setHotspotModalVisibility(false)}
              showModal={showHotspotModal}
            />
          )}
        </Route>
      ))}

      <LocationSelector
        isActive={true}
        activeElementIndex={activeRouteIndex}
        locationItems={locationItems}
        setLocation={setLocation}
        onNextButtonClick={() => swipeHandler('down')}
        onPreviousButtonClick={() => swipeHandler('up')}
      />

      <MapInformation
        isVisible={true}
        locationIndex={activeRouteIndex}
        locationName={locationItems[activeRouteIndex].name}
        locationDescription={locationItems[activeRouteIndex].description}
      />
    </div>
  );
};

export { NYMapView };
