import { MapLocation, MapLocationHotspot, ShareHotspot } from '../../types/nyMapTypes';

import bgMap from '../../assets/images/map/bg_map_1.jpg';
import bgMapMobile from '../../assets/images/map/bg_map_1_mobile.jpg';
// import hotspot1Thumbnail from '../../assets/images/map/uptown/hudsonriver/hudsonriver.jpg';
import hotspot2Thumbnail from '../../assets/images/map/uptown/centralpark/centralpark.jpg';
import hotspot2aThumbnail from '../../assets/images/map/uptown/centralpark/centralpark02.jpg';
import hotspot3Thumbnail from '../../assets/images/map/uptown/met/met-now.jpg';
import hotspot3ThumbnailHidden from '../../assets/images/map/uptown/met/met-then.jpg';
import hotspot4Thumbnail from '../../assets/images/map/uptown/grandcentral/grandcentral-now.jpg';
import hotspot4ThumbnailHidden from '../../assets/images/map/uptown/grandcentral/grandcentral-then.jpg';
import hotspot5Thumbnail from '../../assets/images/map/uptown/timessquare/timessquare-now.jpg';
import hotspot5ThumbnailHidden from '../../assets/images/map/uptown/timessquare/timessquare-then.jpg';
import hotspot6Video from '../../assets/videos/spanish-consulate-childhood.mp4';
import hotspot6Thumbnail from '../../assets/images/map/uptown/spanishconsulate/spanishconsulate.jpg';
import hotspot7Thumbnail from '../../assets/images/map/uptown/5thavenue/5thavenue.jpg';

// const hotspot1: MapLocationHotspot = {
//   markerPositionDesktop: {
//     x: '39.7%',
//     y: '14%',
//   },
//   markerPositionMobile: {
//     x: '70%',
//     y: '20%',
//   },
//   type: 'story',
//   title: '<span><i>Hudson</i></span> <span>River</span>',
//   cleanTitle: 'Hudson River',
//   description: '',
//   thumbnail: {
//     src: hotspot1Thumbnail,
//     alt: 'alt',
//     positionX: 'right',
//     positionY: 'bottom',
//   },
//   mediaContent: {
//     src: hotspot1Thumbnail,
//     alt: 'landscape_1',
//   },
// };

const shareHotspot: ShareHotspot = {
  markerPositionDesktop: {
    x: '25%',
    y: '23%',
  },
  markerPositionMobile: {
    x: '30%',
    y: '30%',
  },
  type: 'share',
  isPostcard: true,
};

const hotspot2: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '56%',
    y: '32%',
  },
  markerPositionMobile: {
    x: '85%',
    y: '44%',
  },
  type: 'story',
  title: '<span>Central</span> <i>Park</i>',
  cleanTitle: 'Central Park',
  description: `In 1853, the first great park was nestled in the heart of NYC. Pedestrians stroll its 750 acres while cars, carriages and equestrians wind through 6 miles of driving paths. In the 1980s, conservancy efforts to protect and maintain the park kept it flourishing in the heart of a city that never sleeps. `,
  thumbnail: {
    src: hotspot2Thumbnail,
    alt: 'alt',
    positionX: 'left',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot2Thumbnail,
        alt: 'Central Park',
      },
      {
        src: hotspot2aThumbnail,
        alt: 'Central Park',
      },
    ],
    isVideo: false,
  },
};

const hotspot3: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '70%',
    y: '40%',
  },
  markerPositionMobile: {
    x: '40%',
    y: '60%',
  },
  type: 'city',
  title: '<i>Met.</i> <span>Museum</span> of <i>Art</i>',
  cleanTitle: 'Met. Museum of Art',
  titlePreview: '<i>Met.</i> <span>Museum</span><br> of <i>Art</i>',
  description:
    'The Metropolitan Museum of Art houses 2 million works of art with more than 2 million square feet of floor space. Located in a once undesirable neighborhood, The Met has become one of the largest, most visited museums in the world.',
  thumbnail: {
    src: hotspot3ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot3Thumbnail,
        alt: 'landscape_1',
      },
    ],
    hiddenContent: {
      src: hotspot3ThumbnailHidden,
      alt: 'landscape_1',
    },
    isVideo: false,
  },
  closeButtonBlack: false,
};

const hotspot4: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '44.8%',
    y: '73%',
  },
  markerPositionMobile: {
    x: '23%',
    y: '56%',
  },
  type: 'city',
  title: '<span><i>Grand</i></span> Central <i>Terminal</i>',
  cleanTitle: 'Grand Central Terminal',
  description: `Midtown Manhattan's world-famous transportation hub. Originally opened as a train depot in 1871, Grand Central Terminal is now a marvel of engineering and art serving millions of visitors each year.`,
  thumbnail: {
    src: hotspot4ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot4Thumbnail,
        alt: 'landscape_1',
      },
    ],
    hiddenContent: {
      src: hotspot4ThumbnailHidden,
      alt: 'landscape_1',
    },
    isVideo: false,
  },
  closeButtonBlack: false,
};

const hotspot5: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '14%',
    y: '72%',
  },
  markerPositionMobile: {
    x: '10%',
    y: '69%',
  },
  type: 'city',
  title: '<i>Times</i> <span>SQUARE</span>',
  cleanTitle: 'Times SQUARE',
  description:
    'First named "Longacre Square", this world famous city destination with Broadway theater shows, skyscraping billboards, and 24-hour vibrance was aptly renamed in 1904 when The New York Times moved into the area.',
  thumbnail: {
    src: hotspot5ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot5Thumbnail,
        alt: 'landscape_1',
      },
    ],
    hiddenContent: {
      src: hotspot5ThumbnailHidden,
      alt: 'landscape_1',
    },
    isVideo: false,
  },
  closeButtonBlack: false,
};

const hotspot6: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '76%',
    y: '54%',
  },
  markerPositionMobile: {
    x: '86%',
    y: '69%',
  },
  type: 'story',
  title: '<span><i>Spanish</i></span>  <i>Consulate</i>',
  cleanTitle: 'Spanish Consulate',
  description: `A child goes missing in the night, and Sara Howard, John Moore and Dr. Kreziler must find her before she becomes the next victim.`,
  thumbnail: {
    src: hotspot6Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot6Video,
        alt: '',
      },
    ],
    hiddenContent: {
      src: hotspot6Thumbnail,
      alt: '',
    },
    isVideo: true,
  },
  isDarkPin: true,
};

const hotspot7: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '42%',
    y: '62%',
  },
  markerPositionMobile: {
    x: '38%',
    y: '63%',
  },
  type: 'story',
  title: '<span>Fifth</span> <i>Avenue</i>',
  titlePreview: '<span>Fifth</span><br> <i>Avenue</i>',
  cleanTitle: '5th Avenue',
  description: `A stroll down 5th Avenue for Señora Linares leads to much more than just a day in Central Park.`,
  thumbnail: {
    src: hotspot7Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot7Thumbnail,
        alt: 'landscape_1',
      },
    ],
    isVideo: false,
  },
  closeButtonBlack: false,
};

const uptownLocationData: MapLocation = {
  bg: bgMap,
  bgMobile: bgMapMobile,
  path: '/uptown',
  name: 'Uptown',
  cleanName: 'Uptown',
  shortName: 'Uptown',
  shortNameClass: 'Uptown',
  description: `The epitome of NYC's modern hustle and bustle. Visitors flock to its world-renowned museums, theaters and famous destinations.`,
  backgroundImagePath: '../',
  hotspots: {
    mobile: [hotspot2, hotspot4, hotspot5, hotspot7, hotspot6],
    desktop: [hotspot2, hotspot3, hotspot4, hotspot5, hotspot7, hotspot6],
  },
  shareHotspot,
};

export { uptownLocationData };
