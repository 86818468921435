import React, { FunctionComponent } from 'react';

import classNames from 'classnames';
import styles from './RoundedCta.module.scss';

interface RoundedCtaProps {
  copy: any;
}

export const RoundedCta: FunctionComponent<RoundedCtaProps> = ({ copy }) => {
  return (
    <div className={styles.roundedCta}>
      <span
        className={classNames(styles.copy, 'globalButton01')}
        dangerouslySetInnerHTML={{ __html: copy }}
      />
    </div>
  );
};
