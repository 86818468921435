import { Postcard } from '../types/postcardTypes';

import postcard1DownloadableImage from '../assets/images/postcards/postcard1.png';
import postcard2DownloadableImage from '../assets/images/postcards/postcard2.png';
import postcard3DownloadableImage from '../assets/images/postcards/postcard3.png';

import imageSrc1 from '../assets/images/postcards/img_postcard1.jpg';
import imageSrc2 from '../assets/images/postcards/img_postcard2.jpg';
import imageSrc3 from '../assets/images/postcards/img_postcard3.jpg';

const postcard1: Postcard = {
  copy: `In 1883, the Brooklyn Bridge became the world's largest suspension bridge and first to utilize steel, connecting Brooklyn and Manhattan for the first time.`,
  imageSrc: imageSrc1,
  location: 'Brooklyn Bridge',
  downloadableImageSrc: postcard1DownloadableImage,
};

const postcard2: Postcard = {
  copy:
    'Originally opened as a train depot in 1871, Grand Central Terminal is now a marvel of engineering and art, serving millions of visitors each year.',
  imageSrc: imageSrc2,
  location: 'Grand Central Station',
  downloadableImageSrc: postcard2DownloadableImage,
};

const postcard3: Postcard = {
  copy:
    'This world famous city destination — with Broadway theater shows, skyscraping billboards and 24-hour vibrance — was aptly renamed Times Square in 1904.',
  imageSrc: imageSrc3,
  location: 'Times Square',
  downloadableImageSrc: postcard3DownloadableImage,
};

const availablePostcards: Postcard[] = [postcard1, postcard3, postcard2];

export { availablePostcards };
