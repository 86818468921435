import React, { FunctionComponent, useRef, useEffect, useCallback } from 'react';

import styles from './Noise.module.scss';

interface NoiseProps {}

let ctx: CanvasRenderingContext2D | null;
let viewWidth = 0;
let viewHeight = 0;

const patternSize = 64;
const patternScaleX = 1;
const patternScaleY = 1;
const patternRefreshInterval = 2;
const patternAlpha = 15;

const patternPixelDataLength = patternSize * patternSize * 4;
let patternCanvas: HTMLCanvasElement;
let patternCtx: CanvasRenderingContext2D | null;
let patternData: ImageData | null;
let frame = 0;

const initGrain = () => {
  patternCanvas = document.createElement('canvas');
  patternCanvas.width = patternSize;
  patternCanvas.height = patternSize;
  patternCtx = patternCanvas.getContext('2d');
  patternData = patternCtx && patternCtx.createImageData(patternSize, patternSize);
};

const draw = () => {
  if (ctx) {
    ctx.clearRect(0, 0, viewWidth, viewHeight);

    ctx.fillStyle = ctx.createPattern(patternCanvas, 'repeat') as CanvasPattern;
    ctx.fillRect(0, 0, viewWidth, viewHeight);
  }
};

const loop = () => {
  if (++frame % patternRefreshInterval === 0) {
    update();
    draw();
  }

  requestAnimationFrame(loop);
};

const update = () => {
  let value;

  for (let i = 0; i < patternPixelDataLength; i += 4) {
    value = (Math.random() * 255) | 0;

    if (patternData) {
      patternData.data[i] = value;
      patternData.data[i + 1] = value;
      patternData.data[i + 2] = value;
      patternData.data[i + 3] = patternAlpha;
    }
  }

  patternCtx && patternData && patternCtx.putImageData(patternData, 0, 0);
};

export const Noise: FunctionComponent<NoiseProps> = () => {
  const canvasElement = useRef<HTMLCanvasElement | null>(null);

  const initCanvas = useCallback(() => {
    if (canvasElement.current) {
      viewWidth = canvasElement.current.width = canvasElement.current.clientWidth;
      viewHeight = canvasElement.current.height = canvasElement.current.clientHeight;
      ctx = canvasElement.current.getContext('2d');
    }

    ctx && ctx.scale(patternScaleX, patternScaleY);
  }, []);

  useEffect(() => {
    initCanvas();
    initGrain();
    requestAnimationFrame(loop);
  }, [initCanvas]);

  return <canvas ref={canvasElement} className={styles.noise}></canvas>;
};
