import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { CustomCursorState } from '../CustomCursor/CustomCursor';
import { useLocation } from 'react-router-dom';

import gsap from 'gsap';

import classNames from 'classnames';
import styles from './SocialMedia.module.scss';

import { handleSocialMediaClickAnalytics } from '../../utils/analyticsWrapper';
import { RoutePaths } from '../../data/enum/RoutePaths';

interface SocialMediaProps {
  isInsideNavigation?: boolean;
  menuIsOpen: boolean;
}

export const SocialMedia: FunctionComponent<SocialMediaProps> = ({
  isInsideNavigation,
  menuIsOpen,
}) => {
  const socialMediaRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const { pathname } = useLocation();

  const [isDarkMode, setIsDarkMode] = useState(pathname === RoutePaths.EXTRAS_CONTENT);

  const handleFacebookAnchorClick = () => {
    handleSocialMediaClickAnalytics('Facebook Link Click', menuIsOpen ? 'Menu' : 'Landing');
  };

  const handleTwitterAnchorClick = () => {
    handleSocialMediaClickAnalytics('Twitter Link Click', menuIsOpen ? 'Menu' : 'Landing');
  };

  const handleInstagramAnchorClick = () => {
    handleSocialMediaClickAnalytics('Instagram Link Click', menuIsOpen ? 'Menu' : 'Landing');
  };

  const handleYoutubeAnchorClick = () => {
    handleSocialMediaClickAnalytics('Youtube Link Click', menuIsOpen ? 'Menu' : 'Landing');
  };

  useEffect(() => {
    setIsDarkMode(pathname === RoutePaths.EXTRAS_CONTENT);
  }, [pathname]);

  useEffect(() => {
    const timeline = gsap.timeline();

    timeline.to(
      socialMediaRef.current,
      {
        opacity: 1,
        duration: 0,
        ease: 'power2.in',
      },
      0,
    );

    listRef.current &&
      timeline.fromTo(
        listRef.current.children,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: 'power2.in',
        },
        0,
      );
  }, []);

  return (
    <div
      ref={socialMediaRef}
      className={classNames(
        styles.socialMedia,
        isInsideNavigation && styles.isInsideNavigation,
        isDarkMode && styles.hasDarkMode,
      )}
    >
      <ul ref={listRef} className={styles.socialList}>
        <li className={styles.socialItem}>
          <a
            href="https://www.facebook.com/TheAlienistTNT/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
            data-custom-cursor-state={CustomCursorState.Pointer}
            onClick={handleFacebookAnchorClick}
          >
            <span className={classNames(styles.icon, styles.facebook)} />
          </a>
        </li>
        <li className={styles.socialItem}>
          <a
            href="https://twitter.com/theAlienistTNT"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
            data-custom-cursor-state={CustomCursorState.Pointer}
            onClick={handleTwitterAnchorClick}
          >
            <span className={classNames(styles.icon, styles.twitter)} />
          </a>
        </li>
        <li className={styles.socialItem}>
          <a
            href="https://www.instagram.com/theAlienistTNT/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
            data-custom-cursor-state={CustomCursorState.Pointer}
            onClick={handleInstagramAnchorClick}
          >
            <span className={classNames(styles.icon, styles.instagram)} />
          </a>
        </li>
        <li className={styles.socialItem}>
          <a
            href="https://www.youtube.com/user/tntweknowdrama"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
            data-custom-cursor-state={CustomCursorState.Pointer}
            onClick={handleYoutubeAnchorClick}
          >
            <span className={classNames(styles.icon, styles.youtube)} />
          </a>
        </li>
      </ul>
    </div>
  );
};
