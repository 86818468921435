import React, { FunctionComponent } from 'react';
import styles from './ExtrasView.module.scss';
import { Route } from 'react-router-dom';
import { RoutePaths } from '../../../data/enum/RoutePaths';
import { Slider } from './Slider/Slider';
import { Extras } from './Extras';
import { SocialMedia } from '../../SocialMedia/SocialMedia';
import { useDeviceState } from '../../../hooks/useDeviceState';

export const ExtrasView: FunctionComponent = () => {
  const { deviceState } = useDeviceState();

  return (
    <div className={styles.extrasView}>
      {deviceState >= 2 && <SocialMedia menuIsOpen={false} />}
      <Route exact path={RoutePaths.EXTRAS_CONTENT}>
        {({ match }) => <Slider isVisible={match !== null} endTransition={() => {}} />}
      </Route>
      <Route exact path={RoutePaths.EXTRAS}>
        {({ match }) => <Extras isVisible={match !== null} endTransition={() => {}} />}
      </Route>
    </div>
  );
};
