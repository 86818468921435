import React, { useState, useRef, useCallback, useEffect } from 'react';

import classNames from 'classnames';
import styles from './VideoPlayer.module.scss';
import throttle from 'lodash/throttle';

import { useDeviceState } from '../../hooks/useDeviceState';
import { secondsToTime } from '../../utils/secondsToTime';
import { CustomCursorState } from '../CustomCursor/CustomCursor';
import { MaskedImage } from '../MaskedImage/MaskedImage';
import { RoundedCta } from '../RoundedCta/RoundedCta';

import { ReactComponent as PlayIcon } from '../../assets/svg/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/svg/pause.svg';
import { ReactComponent as Border } from '../../assets/svg/player-button-border.svg';

interface VideoPlayerProps {
  src: string;
  visiblePosterSrc: string;
  hiddenPosterSrc: string;
  cursorText?: string;
  buttonStyles?: string;
  onFirstPlay?: () => void;
  onActiveChange?: (isActive: boolean) => void;
  canPlayVideo: boolean;
  title: string;
}

const TrailerPlayer: React.FC<VideoPlayerProps> = ({
  src,
  cursorText,
  visiblePosterSrc,
  hiddenPosterSrc,
  buttonStyles,
  onFirstPlay,
  onActiveChange,
  canPlayVideo,
  title,
}) => {
  const { isTouch, isTablet, isMobile } = useDeviceState();

  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');
  const [isActive, setIsActive] = useState(false);
  const [showControls, setControlsVisibility] = useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);

  const volumeBox = useRef<HTMLSpanElement>(null);
  const volumeSlide = useRef<HTMLSpanElement>(null);

  const currentCursorText = cursorText || `<i>${videoRef.current?.paused ? 'Play' : 'Close'}</i>`;

  const resumeTimeout = useRef<number | null>(null);

  const toggleVideoState = () => {
    if (videoRef.current?.paused) {
      playVideo();
    } else {
      pauseVideo();
    }
  };

  const closeVideo = () => {
    pauseVideo();
    setIsActive(false);
    if (onActiveChange) {
      onActiveChange(!isActive);
    }

    resumeTimeout.current = window.setTimeout(() => {
      // give the user a short period of time to resume
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
      }
    }, 1000);
  };

  const pauseVideo = useCallback(() => {
    videoRef.current?.pause();
  }, [videoRef]);

  const playVideo = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    if (!isActive && onFirstPlay) {
      onFirstPlay();
    }

    if (resumeTimeout.current) {
      clearTimeout(resumeTimeout.current);
    }

    if (onActiveChange && isActive === false) {
      onActiveChange(!isActive);
    }

    setIsActive(true);
    videoRef.current.play();
    if (isMobile && videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    }
  }, [videoRef, isActive, onFirstPlay, onActiveChange, isMobile]);

  const handleTimeUpdate = useCallback(() => {
    if (!videoRef.current) return;

    const progress = (videoRef.current.currentTime * 100) / videoRef.current.duration;
    const currentTimeObj = secondsToTime(videoRef.current.currentTime);

    setCurrentTime(`${currentTimeObj.minutes}:${currentTimeObj.seconds}`);
    setCurrentProgress(progress);
  }, [setCurrentTime, setCurrentProgress]);

  useEffect(() => {
    let moveTimeout: number = 0;
    const handleMouseMove = throttle(() => {
      if (moveTimeout) {
        clearTimeout(moveTimeout);
      }

      setControlsVisibility(true);
      moveTimeout = window.setTimeout(() => {
        setControlsVisibility(false);
      }, 3000);
    }, 500);

    if (isTablet) {
      window.addEventListener('touchstart', handleMouseMove);
    } else {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (isTablet) {
        window.removeEventListener('touchstart', handleMouseMove);
      } else {
        window.removeEventListener('mousemove', handleMouseMove);
      }
      clearTimeout(moveTimeout);
    };
  }, [isTablet]);

  useEffect(() => {
    if (!volumeBox.current) {
      return;
    }

    const volumeBoxRef = volumeBox.current;
    let isClicking = false;

    const handleVolumeChange = (localPositionX: number, targetClientWidth: number) => {
      videoRef.current!.volume = Math.max(0, Math.min(1, localPositionX / targetClientWidth));

      volumeSlide.current!.style.transform = `translateX(${
        localPositionX - volumeSlide.current!.getBoundingClientRect().width
      }px)`;
    };

    const handleMouseDown = (event: MouseEvent) => {
      isClicking = true;
      if (event.target instanceof Element) {
        handleVolumeChange(
          event.clientX - event.target.getBoundingClientRect().x,
          event.target.clientWidth,
        );
      }
    };

    const handleMouseUp = (event: MouseEvent) => (isClicking = false);
    const handleMouseMove = (event: MouseEvent) => {
      if (!isClicking) {
        return;
      }

      if (event.target instanceof Element) {
        handleVolumeChange(
          event.clientX - event.target.getBoundingClientRect().x,
          event.target.clientWidth,
        );
      }
    };

    volumeBoxRef.addEventListener('mousedown', handleMouseDown);
    volumeBoxRef.addEventListener('mouseup', handleMouseUp);
    volumeBoxRef.addEventListener('mousemove', handleMouseMove);

    return () => {
      volumeBoxRef.removeEventListener('mouseover', handleMouseDown);
      volumeBoxRef.removeEventListener('mouseup', handleMouseUp);
      volumeBoxRef.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isActive]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    if (canPlayVideo) {
      if (isTouch) {
        return;
      }
      playVideo();
    } else {
      pauseVideo();
    }
  }, [canPlayVideo, pauseVideo, playVideo, isActive, isTouch]);

  return (
    <div className={classNames('background')}>
      <video
        ref={videoRef}
        className={classNames(
          styles.isActive,
          styles.video,
          !videoRef.current?.paused && styles.isPlaying,
        )}
        onTimeUpdate={handleTimeUpdate}
        onEnded={closeVideo}
      >
        <source src={src} type="video/mp4" />
      </video>

      {!isTouch ? (
        <button
          className={classNames(styles.playOverlay)}
          onClick={isActive ? closeVideo : toggleVideoState}
        >
          <div
            className={classNames(styles.maskedPoster, !isActive && styles.show)}
            data-custom-cursor-state={CustomCursorState.Action}
            data-custom-cursor-text="Close"
          >
            <div className={classNames(isActive && styles.showMaskedImage)}>
              <MaskedImage
                visibleImageSrc={visiblePosterSrc || ''}
                hiddenImageSrc={hiddenPosterSrc || ''}
                cursorText={currentCursorText}
              />
            </div>
          </div>
        </button>
      ) : (
        <>
          {isTouch && !isActive && (
            <>
              <span className={classNames(styles.mobileOverlay, isActive && styles.isActive)} />
              <button
                className={classNames(
                  styles.circleButton,
                  buttonStyles || styles.defaultCircleButton,
                  (!videoRef.current || videoRef.current?.paused) && styles.isVisible,
                )}
                onClick={toggleVideoState}
              >
                <RoundedCta copy={cursorText || 'Play'} />
              </button>
            </>
          )}
          <div className={classNames(styles.maskedPoster, !isActive && styles.show)}>
            <div
              className={classNames(styles.poster, !isActive && styles.show)}
              style={{ backgroundImage: `url(${visiblePosterSrc})` }}
            ></div>
          </div>

          <button
            className={classNames(
              styles.closeButton,
              isActive && styles.isActive,
              'globalButton01',
            )}
            onClick={closeVideo}
          >
            Close
          </button>
        </>
      )}

      {isActive && (isTablet || !isTouch) && (
        <div
          className={classNames(styles.controls, showControls && styles.show)}
          data-custom-cursor-state={CustomCursorState.Default}
        >
          <button
            className={styles.button}
            onClick={toggleVideoState}
            data-custom-cursor-state={CustomCursorState.Pointer}
          >
            <Border className={styles.border} />
            {videoRef.current?.paused ? (
              <PlayIcon className={styles.icon} />
            ) : (
              <PauseIcon className={styles.icon} />
            )}
          </button>

          <div className={styles.progressAndTitle}>
            <div className={styles.line}>
              <span className={styles.progressBar} style={{ width: `${currentProgress}%` }}></span>
            </div>
            <p
              className={classNames(styles.title, 'globalButton02')}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>

          <div className={styles.durationAndVolume}>
            <p className={classNames(styles.duration, 'globalBody03')}>{currentTime}</p>
            <div className={styles.volume}>
              <span
                className={styles.volumeCurrent}
                ref={volumeSlide}
                data-custom-cursor-state={CustomCursorState.Default}
              ></span>
              <span ref={volumeBox} className={styles.volumeTotal}></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { TrailerPlayer };
