import { MapLocation } from '../../types/nyMapTypes';
import { downtownLocationData } from './downtownLocationData';
import { uptownLocationData } from './uptownLocationData';
import { westsideLocationData } from './westsideLocationData';
import { midtownLocationData } from './midtownLocationData';

const nyData: Array<MapLocation> = [
  downtownLocationData,
  midtownLocationData,
  westsideLocationData,
  uptownLocationData,
];

export { nyData };
