import React, { FunctionComponent, useState, useEffect, useCallback, useRef } from 'react';
import { RoutePaths } from '../../../data/enum/RoutePaths';
import { useHistory } from 'react-router-dom';
import throttle from 'lodash/throttle';

import gsap from 'gsap';
import { SplitText } from '../../../vendor/SplitText';
import { withSlideTransition, WithSlideTransitionProps } from '../../hoc/withSlideTransition';

import { handlExtrasViewAnalytics } from '../../../utils/analyticsWrapper';

import classNames from 'classnames';
import styles from './Extras.module.scss';

import { CustomCursorState } from '../../CustomCursor/CustomCursor';
import { RoundedCta } from '../../RoundedCta/RoundedCta';

import { useDeviceState } from '../../../hooks/useDeviceState';

export const Extras: FunctionComponent<WithSlideTransitionProps> = withSlideTransition(() => {
  const [ySwiped, setYSwiped] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { deviceState } = useDeviceState();
  const { push } = useHistory();

  const contentRef = useRef<HTMLDivElement>(null);
  const borderMiscRef = useRef<HTMLSpanElement>(null);
  const mobileBackgroundRef = useRef<HTMLSpanElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const mobileCtaRef = useRef<HTMLDivElement>(null);
  const arrowLinkRef = useRef<HTMLDivElement>(null);

  const handleTransitions = useCallback(() => {
    const timeline = gsap.timeline();

    timeline.to(
      contentRef.current,
      {
        opacity: 1,
        duration: 0,
        ease: 'power2.in',
      },
      0,
    );

    if (deviceState >= 2) {
      timeline.fromTo(
        borderMiscRef.current,
        {
          opacity: 0,
          xPercent: 100,
        },
        {
          opacity: 1,
          xPercent: 0,
          duration: 0.8,
          ease: 'power2.inOut',
        },
        0.8,
      );
    } else {
      timeline.fromTo(
        borderMiscRef.current,
        {
          opacity: 0,
          yPercent: 100,
        },
        {
          opacity: 1,
          yPercent: 0,
          duration: 0.8,
          ease: 'power2.inOut',
        },
        1,
      );
    }

    mobileBackgroundRef.current &&
      timeline.fromTo(
        mobileBackgroundRef.current,
        {
          opacity: 0,
          yPercent: -100,
        },
        {
          opacity: 1,
          yPercent: 0,
          duration: 0.8,
          ease: 'power2.inOut',
        },
        0,
      );

    const splitTitle = new SplitText(titleRef.current, { type: 'words, chars' });

    if (deviceState >= 2) {
      timeline.fromTo(
        splitTitle.chars,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          stagger: 0.04,
          duration: 1,
          ease: 'power2.inOut',
        },
        0.2,
      );
    } else {
      timeline.fromTo(
        splitTitle.chars,
        {
          opacity: 0,
          y: 5,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.04,
          duration: 0.8,
          ease: 'power2.inOut',
        },
        0.6,
      );
    }

    mobileCtaRef.current &&
      timeline.fromTo(
        mobileCtaRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
          ease: 'power2.in',
        },
        1,
      );

    timeline.fromTo(
      arrowLinkRef.current,
      {
        opacity: 0,
        y: 5,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: 'power2.in',
      },
      1,
    );

    return timeline;
  }, [deviceState]);

  useEffect(() => {
    handleTransitions();
  }, [handleTransitions]);

  useEffect(() => {
    handlExtrasViewAnalytics();
  }, []);

  const handleWheelScroll = (event: React.WheelEvent) => {
    event.persist();

    const _handleWheelScroll = throttle(() => {
      if (isTransitioning) return;

      if (event.deltaY >= 3 || event.deltaX >= 3) {
        setIsTransitioning(true);
        push(RoutePaths.EXTRAS_CONTENT, { fromRight: true });
      }
    }, 100);

    _handleWheelScroll();
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    const yPosition = event.changedTouches[0].clientY;

    if (event.type === 'touchstart') {
      return setYSwiped(yPosition);
    }

    if (yPosition < ySwiped) {
      push(RoutePaths.EXTRAS_CONTENT, { fromRight: true });
    }
  };

  return (
    <div
      ref={contentRef}
      className={styles.extras}
      data-custom-cursor-state={CustomCursorState.Action}
      data-custom-cursor-text="<i>Scroll</i>"
      onClick={() => push(RoutePaths.EXTRAS_CONTENT, { fromRight: true })}
      onWheel={handleWheelScroll}
      onTouchStart={handleTouchMove}
      onTouchEnd={handleTouchMove}
    >
      <span ref={borderMiscRef} className={styles.borderMisc} />

      <div className={styles.content}>
        {deviceState < 2 && <span ref={mobileBackgroundRef} className={styles.mobileBackground} />}

        <div className={styles.titleWrapper}>
          <h2 ref={titleRef} className={classNames(styles.title, 'globalTitle02 texture')}>
            <i>The</i>
            <span>— ALIENIST</span>
            <i>EXTRAS</i>
          </h2>
        </div>

        <div className={styles.ctaWrapper}>
          {deviceState < 2 && (
            <div ref={mobileCtaRef} className={styles.mobileCta}>
              <RoundedCta copy="<i>Scroll</i></br>Down" />
            </div>
          )}
          <div
            ref={arrowLinkRef}
            className={styles.arrowLink}
            data-custom-cursor-state={CustomCursorState.Pointer}
          >
            <span className={classNames(styles.icon, 'texture')} />
          </div>
        </div>
      </div>
    </div>
  );
});
