interface AnalyticsEventProps {
  type: string;
  data: {
    pageViewEvent?: string;
    interactionEvent?: string;
    interactionName: string;
    screenName: string;
    screenSection: string;
    hotspotName?: string;
  };
}

const handleAnalyticsEvent = ({ type, data }: AnalyticsEventProps) => {
  try {
    // @ts-ignore
    window.trackMetrics({
      type,
      data: {
        // dynamic data
        ...data,
        // static data
        pageName: 'tnt:c:the alienist: angel of darkness - explore new york city then & now',
        friendly_page_name: 'the alienist: angel of darkness - explore new york city then & now',
        url: 'www.tntdrama.com/angel-of-darkness/explore-nyc',
        series_name: 'The Alienist: Angel of Darkness',
        section: ['show info'],
        device: 'web',
        device_type: 'web',
        network: 'tnt',
        template_type: 'adbp:content',
      },
    });
  } catch (e) {
    console.warn(e.message);
  }
};

/////////// page views

const handleHeroPageViewAnalytics = () => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: 'Hero Lander Pageview',
      screenName: 'Hero Lander',
      screenSection: 'Landing',
    },
  });
};

const handleAboutPageViewAnalytics = () => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: 'About the Series Pageview',
      screenName: 'About the Series',
      screenSection: 'Landing',
    },
  });
};

const handleTrailerPageViewAnalytics = () => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: 'Watch the Trailer Pageview',
      screenName: 'Watch the Trailer',
      screenSection: 'Landing',
    },
  });
};

const handleMenuViewAnalytics = () => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: 'Menu Pageview',
      screenName: 'Menu',
      screenSection: 'Menu',
    },
  });
};

const handlExtrasViewAnalytics = () => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: 'Extras Pageview',
      screenName: 'Extras',
      screenSection: 'Extras',
    },
  });
};

const handlExtrasVideoViewAnalytics = (videoName: string) => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: `${videoName} Pageview`,
      screenName: videoName,
      screenSection: 'Extras',
    },
  });
};

const handleHotspotDetailsViewAnalytics = (locationName: string, hotspotName: string) => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: 'Hotspot Modal View',
      screenName: locationName,
      screenSection: 'Map',
      hotspotName,
    },
  });
};

const handlePostcardViewAnalytics = (locationName: string) => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: 'Postcard Modal View',
      screenName: locationName,
      screenSection: 'Map',
      hotspotName: 'Share a Postcard',
    },
  });
};

const handleMapViewAnalytics = (locationName: string) => {
  handleAnalyticsEvent({
    type: 'dynamic-page',
    data: {
      pageViewEvent: '1',
      interactionName: `${locationName} Pageview`,
      screenName: locationName,
      screenSection: 'Map',
    },
  });
};

/////////// interactive events

const handleSocialMediaClickAnalytics = (
  interactionName:
    | 'Facebook Link Click'
    | 'Twitter Link Click'
    | 'Instagram Link Click'
    | 'Youtube Link Click',
  screenSection: 'Menu' | 'Landing',
) => {
  handleAnalyticsEvent({
    type: 'interaction-event',
    data: {
      interactionEvent: '1',
      interactionName,
      screenName: screenSection,
      screenSection,
    },
  });
};

const handleEmailSignupSuccessAnalytics = () => {
  handleAnalyticsEvent({
    type: 'interaction-event',
    data: {
      interactionEvent: '1',
      interactionName: 'Email Signup',
      screenName: 'Menu',
      screenSection: 'Menu',
    },
  });
};

const handleTrailerStartAnalytics = () => {
  handleAnalyticsEvent({
    type: 'interaction-event',
    data: {
      interactionEvent: '1',
      interactionName: 'Trailer View',
      screenName: 'Watch the Trailer',
      screenSection: 'Landing',
    },
  });
};

const handleWatchShowLinkClickAnalytics = (screenSection: 'Landing' | 'Menu') => {
  handleAnalyticsEvent({
    type: 'interaction-event',
    data: {
      interactionEvent: '1',
      interactionName: 'Watch the Show Click',
      screenName: screenSection,
      screenSection,
    },
  });
};

const handlePostcardLinkCopyAnalytics = (locationName: string) => {
  handleAnalyticsEvent({
    type: 'interaction-event',
    data: {
      interactionEvent: '1',
      interactionName: 'Postcard Link Copy',
      screenName: locationName,
      screenSection: 'Map',
      hotspotName: 'Share a Postcard',
    },
  });
};

const handlExtrasVideoPlayAnalytics = (videoName: string) => {
  handleAnalyticsEvent({
    type: 'interaction-event',
    data: {
      interactionEvent: '1',
      interactionName: `${videoName} Video Play`,
      screenName: videoName,
      screenSection: 'Extras',
    },
  });
};

export {
  handleHeroPageViewAnalytics,
  handleAboutPageViewAnalytics,
  handleTrailerPageViewAnalytics,
  handleMenuViewAnalytics,
  handleMapViewAnalytics,
  handlExtrasViewAnalytics,
  handlExtrasVideoViewAnalytics,
  handleHotspotDetailsViewAnalytics,
  handlePostcardViewAnalytics,
  handleSocialMediaClickAnalytics,
  handleEmailSignupSuccessAnalytics,
  handleTrailerStartAnalytics,
  handleWatchShowLinkClickAnalytics,
  handlePostcardLinkCopyAnalytics,
  handlExtrasVideoPlayAnalytics,
};
