import { MapLocation, MapLocationHotspot, ShareHotspot } from '../../types/nyMapTypes';

import bgMap from '../../assets/images/map/bg_map_4.jpg';
import bgMapMobile from '../../assets/images/map/bg_map_4_mobile.jpg';
import hotspot1Thumbnail from '../../assets/images/map/downtown/delmonicos/delmonicos.jpg';
import hotspot2Thumbnail from '../../assets/images/map/downtown/wallstreet/wallstreet-now.jpg';
import hotspot2ThumbnailHidden from '../../assets/images/map/downtown/wallstreet/wallstreet-then.jpg';
import hotspot3Thumbnail from '../../assets/images/map/downtown/fulton/fulton-now.jpg';
import hotspot3ThumbnailHidden from '../../assets/images/map/downtown/fulton/fulton-then.jpg';
import hotspot4Thumbnail from '../../assets/images/map/downtown/brooklynbridge/brooklynbridge-now.jpg';
import hotspot4ThumbnailHidden from '../../assets/images/map/downtown/brooklynbridge/brooklynbridge-then.jpg';
import hotspot6Video from '../../assets/videos/lower-manhattan-perfume.mp4';
import hotspot6Thumbnail from '../../assets/images/map/downtown/lowerm/lowerm.jpg';
import hotspot7Thumbnail from '../../assets/images/map/downtown/nyjournal/nyjournal.jpg';
import hotspot7Video from '../../assets/videos/ny-journal.mp4';

// import hotspot3Video from '../../assets/videos/sample.mp4';

const shareHotspot: ShareHotspot = {
  markerPositionDesktop: {
    x: '60%',
    y: '20%',
  },
  markerPositionMobile: {
    x: '70%',
    y: '26%',
  },
  type: 'share',
  isPostcard: true,
};

const hotspot1: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '29%',
    y: '59%',
  },
  markerPositionMobile: {
    x: '26%',
    y: '56%',
  },
  type: 'city',
  title: `<span>Delmonico'</span>s`,
  cleanTitle: 'Delmonico',
  description: `A favourite restaurant of Dr. Kreizler and John Moore, the team of investigators dine on steaks while discussing the current case.`,
  thumbnail: {
    src: hotspot1Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot1Thumbnail,
        alt: 'landscape_1',
      },
    ],
  },
};

const hotspot2: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '35%',
    y: '54%',
  },
  markerPositionMobile: {
    x: '40%',
    y: '49%',
  },
  type: 'city',
  title: '<span>Wall</span> <i>Street</i>',
  cleanTitle: 'Wall Street',
  description: `Home to one of the most influential finance centers in the world: The New York Stock Exchange. Wall Street, a few street blocks on the tip of Manhattan, is famous for a number of historical items, each one of them deriving from numbers which impact both the American and global economies. `,
  thumbnail: {
    src: hotspot2ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot2Thumbnail,
        alt: '',
      },
    ],
    hiddenContent: {
      src: hotspot2ThumbnailHidden,
      alt: '',
    },
    isVideo: false,
  },
  closeButtonBlack: false,
};

const hotspot3: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '50.5%',
    y: '59%',
  },
  markerPositionMobile: {
    x: '61%',
    y: '55%',
  },
  type: 'city',
  title: '<span><i>Fulton</i></span> Fish <i>Market</i>',
  cleanTitle: 'Fulton Fish Market',
  description: `The Fulton Fish Market, the nation's largest and oldest wholesale market, began doing business at its current site in 1835, providing fresh fish to stores, hotels and restaurants in the metro area.`,
  thumbnail: {
    src: hotspot3ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot3Thumbnail,
        alt: '',
      },
    ],
    hiddenContent: {
      src: hotspot3ThumbnailHidden,
      alt: '',
    },
    isVideo: false,
  },
};

const hotspot4: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '67%',
    y: '58%',
  },
  markerPositionMobile: {
    x: '88%',
    y: '64%',
  },
  type: 'city',
  title: '<span>Brooklyn</span> <i>Bridge</i>',
  cleanTitle: 'Brooklyn Bridge',
  description: `When built in 1883, the Brooklyn Bridge became the world's largest suspension bridge and first to utilize steel, connecting Brooklyn and Manhattan for the first time.`,
  thumbnail: {
    src: hotspot4ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot4Thumbnail,
        alt: '',
      },
    ],
    hiddenContent: {
      src: hotspot4ThumbnailHidden,
      alt: '',
    },
    isVideo: false,
  },
};

// const hotspot5: MapLocationHotspot = {
//   markerPositionDesktop: {
//     x: '48.7%',
//     y: '43%',
//   },
//   markerPositionMobile: {
//     x: '50%',
//     y: '70%',
//   },
//   type: 'story',
//   title: '<span><i>Newspaper</i></span> Lane',
//   titlePreview: '<span><i>Newspaper</i></span><br> Lane',
//   description: ``,
//   thumbnail: {
//     src: hotspot5Thumbnail,
//     alt: 'alt',
//     positionX: 'right',
//     positionY: 'top',
//   },
//   mediaContent: {
//     src: hotspot5Thumbnail,
//     alt: '',
//     isVideo: false,
//   },
// };

const hotspot6: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '22%',
    y: '50%',
  },
  markerPositionMobile: {
    x: '10%',
    y: '50%',
  },
  type: 'show',
  title: '<span><i>Lower</i></span> Manhattan',
  cleanTitle: 'Lower Manhattan',
  titlePreview: '<span><i>Lower</i></span><br> Manhattan',
  description: `Lower Manhattan is the home of perfumerie D.S. & Durga. Discover the scent of First Light Five Burroughs.`,
  thumbnail: {
    src: hotspot6Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot6Video,
        alt: '',
      },
    ],
    hiddenContent: {
      src: hotspot6Thumbnail,
      alt: '',
    },
    isVideo: true,
  },
};

const hotspot7: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '59%',
    y: '48%',
  },
  markerPositionMobile: {
    x: '60%',
    y: '42%',
  },
  type: 'show',
  title: '<span><i>New</i> York</span> <i>Journal</i>',
  cleanTitle: 'New York Journal',
  description: `Discover Newspaper Row of 1897 in the World of the Alienist.`,
  thumbnail: {
    src: hotspot7Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'top',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot7Video,
        alt: '',
      },
    ],
    hiddenContent: {
      src: hotspot7Thumbnail,
      alt: '',
    },
    isVideo: true,
  },
};

const downtownLocationData: MapLocation = {
  bg: bgMap,
  bgMobile: bgMapMobile,
  path: '',
  name: 'Downtown',
  cleanName: 'Downtown',
  shortName: 'Downtown',
  shortNameClass: 'Downtown',
  description:
    'From Wall Street to City Hall, the 9/11 Memorial to Woolworth building, history and culture are what make Lower Manhattan.',
  backgroundImagePath: '../',
  hotspots: {
    mobile: [hotspot6, hotspot2, hotspot1, hotspot7, hotspot3, hotspot4],
    desktop: [hotspot6, hotspot7, hotspot1, hotspot2, hotspot3, hotspot4],
  },
  shareHotspot,
};

export { downtownLocationData };
