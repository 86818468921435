/* eslint-disable */
export const doNotSellItHandler = function (drupal, $, userConsent) {
  $('#onetrust-pc-sdk').removeAttr('style');
  $('#onetrust-pc-sdk').removeClass('hide');

  $('.onetrust-pc-dark-filter').removeAttr('style');
  $('.onetrust-pc-dark-filter').removeClass('hide');

  $('#onetrust-consent-sdk #onetrust-pc-sdk button').css('background-color', '');

  $('.save-preference-btn-handler').css('background-color', '#68b631');
  $('#accept-recommended-btn-handler"').css('background-color', '#68b631');

  drupal.behaviors.ccpa = {
    attach: function (context, settings) {
      var $item = $('<li>');
      var $anchor = $('<a>')
        .attr('href', '#')
        .html(drupal.t(userConsent.getLinkTitle()))
        .click(function (e) {
          e.preventDefault();
          userConsent.getLinkAction()();
        })
        .appendTo($item);
      $('nav.footer-nav ul', context).prepend($item);
    },
  };
};
