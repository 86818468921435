import React, { FunctionComponent, useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import gsap from 'gsap';
import { SplitText } from '../../../../vendor/SplitText';

import classNames from 'classnames';
import styles from './About.module.scss';

import { MaskedImage } from '../../../MaskedImage/MaskedImage';
import { RoutePaths } from '../../../../data/enum/RoutePaths';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';
import { RoundedCta } from '../../../RoundedCta/RoundedCta';
import { EasterEgg } from './EasterEgg/EasterEgg';

import { useDeviceState } from '../../../../hooks/useDeviceState';
import { handleAboutPageViewAnalytics } from '../../../../utils/analyticsWrapper';

import inkTransition from '../../../../assets/images/ink-transition3.jpg';
import mapBackground from '../../../../assets/images/map/bg_map_4.jpg';
import mapMobileBackground from '../../../../assets/images/map/bg_map_4_mobile.jpg';

import { transitionWithShader } from '../../../../utils/transitionWithShader';

import imageLeftVisible from '../../../../assets/images/landing/about/image-left.jpg';
import imageLeftHidden from '../../../../assets/images/landing/about/image-left-hidden.jpg';
import imageRightVisible from '../../../../assets/images/landing/about/image-right.jpg';
import imageRightHidden from '../../../../assets/images/landing/about/image-right-hidden.jpg';
import easterEggLeft1 from '../../../../assets/images/landing/about/img-1980s1.jpg';
import easterEggLeft2 from '../../../../assets/images/landing/about/img-alienists1.jpg';
import easterEggLeft3 from '../../../../assets/images/landing/about/img-crime1.jpg';
import easterEggRight1 from '../../../../assets/images/landing/about/img-1980s2.jpg';
import easterEggRight2 from '../../../../assets/images/landing/about/img-alienists2.jpg';
import easterEggRight3 from '../../../../assets/images/landing/about/img-crime2.jpg';

const leftImages = [
  <MaskedImage
    visibleImageSrc={imageLeftVisible}
    hiddenImageSrc={imageLeftHidden}
    cursorText="Discover</br><i>The City</i>"
  />,
  <div className={styles.easterEgg} style={{ backgroundImage: `url(${easterEggLeft1})` }} />,
  <div className={styles.easterEgg} style={{ backgroundImage: `url(${easterEggLeft2})` }} />,
  <div className={styles.easterEgg} style={{ backgroundImage: `url(${easterEggLeft3})` }} />,
];

const rightImages = [
  <MaskedImage
    visibleImageSrc={imageRightVisible}
    hiddenImageSrc={imageRightHidden}
    cursorText="Discover</br><i>The City</i>"
  />,
  <div className={styles.easterEgg} style={{ backgroundImage: `url(${easterEggRight1})` }} />,
  <div className={styles.easterEgg} style={{ backgroundImage: `url(${easterEggRight2})` }} />,
  <div className={styles.easterEgg} style={{ backgroundImage: `url(${easterEggRight3})` }} />,
];

interface AboutProps {
  onDownArrowButtonClick: () => void;
}

export const About: FunctionComponent<AboutProps> = ({ onDownArrowButtonClick }) => {
  const [imageGroupIndex, setImageGroupIndex] = useState(0);
  const { deviceState, isTouch } = useDeviceState();
  const [isEasterEggHovered, setIsEasterEggHovered] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { push } = useHistory();

  // Refs
  const wrapperRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const backgroundOverlayRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const taglineRef = useRef<HTMLParagraphElement>(null);
  const taglineLineRef = useRef<HTMLSpanElement>(null);
  const firstTextRef = useRef<HTMLParagraphElement>(null);
  const secondTextRef = useRef<HTMLParagraphElement>(null);
  const mobileTextRef = useRef<HTMLParagraphElement>(null);
  const dotsRef = useRef<HTMLParagraphElement>(null);
  const mobileCtaRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLSpanElement>(null);
  const imageMobileRef = useRef<HTMLDivElement>(null);
  const imageRightRef = useRef<HTMLDivElement>(null);
  const imageLeftRef = useRef<HTMLDivElement>(null);

  const handleMarkMouseEnter = useCallback(
    (newImageGroupIndex) => {
      setIsEasterEggHovered(true);
      setImageGroupIndex(newImageGroupIndex);
    },
    [setImageGroupIndex],
  );

  const handleMarkMouseLeave = useCallback(() => {
    setIsEasterEggHovered(false);
    setImageGroupIndex(0);
  }, [setImageGroupIndex]);

  const addTextsToTimeline = (array: Array<HTMLElement>) => {
    const timeline = gsap.timeline();
    const splitText: Array<any> = []; // TO DO: fix type

    array.forEach((element: HTMLElement) => {
      splitText.push(new SplitText(element, { type: 'chars, words' }));
    });

    // TO DO: fix element type
    splitText.forEach((element: any, index: number) => {
      timeline.fromTo(
        element.chars,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          stagger: index % 2 ? -0.035 : 0.035,
          duration: 1,
          ease: 'power2.inOut',
        },
        index * 0.01,
      );
    });

    return timeline;
  };

  const handleTransitions = useCallback(() => {
    const timeline = gsap.timeline();

    const splitTagline = new SplitText(taglineRef.current, { type: 'chars, words' });

    timeline.set(wrapperRef.current, { opacity: 1 });

    timeline.fromTo(
      backgroundOverlayRef.current,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 1.2,
      },
      0,
    );

    timeline.fromTo(
      splitTagline.chars,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.1,
        duration: 0.55,
        ease: 'power2.in',
        delay: 0.2,
      },
      0,
    );

    timeline.fromTo(
      taglineLineRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.6,
        ease: 'power2.in',
        delay: 0.2,
      },
      1,
    );

    if (mobileTextRef.current) {
      const splitMobileText = [].slice.call(mobileTextRef.current.children);
      timeline.add(addTextsToTimeline(splitMobileText), 0.4);
    }

    imageMobileRef.current &&
      timeline.fromTo(
        imageMobileRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
          ease: 'power2.in',
          delay: 0.2,
        },
        0,
      );

    mobileCtaRef.current &&
      timeline.fromTo(
        mobileCtaRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
          ease: 'power2.in',
        },
        1,
      );

    if (imageLeftRef.current && imageRightRef.current) {
      timeline.fromTo(
        [imageLeftRef.current, imageRightRef.current],
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 2,
          delay: 0.2,
        },
        0,
      );
    }

    if (firstTextRef.current) {
      const splitFirstTextSpans = [].slice.call(firstTextRef.current.children);
      timeline.add(addTextsToTimeline(splitFirstTextSpans), 0.4);
    }

    if (dotsRef.current) {
      const splitDots = new SplitText(dotsRef.current, { type: 'chars, words' });

      timeline.fromTo(
        splitDots.chars,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          stagger: 0.15,
          duration: 0.8,
          ease: 'power2.in',
        },
        0.8,
      );
    }

    if (secondTextRef.current) {
      const splitSecondTextSpans = [].slice.call(secondTextRef.current.children);
      timeline.add(addTextsToTimeline(splitSecondTextSpans), 0.4);
    }

    timeline.fromTo(
      iconRef.current,
      {
        opacity: 0,
        y: -5,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: 'power2.in',
      },
      2,
    );

    return timeline;
  }, []);

  const transitionToExplore = () => {
    if (isTransitioning) return;

    transitionWithShader(
      [isTouch ? mapMobileBackground : mapBackground, inkTransition],
      () => push(RoutePaths.EXPLORE, { noTransition: true }),
      () => setIsTransitioning(false),
      8,
    );
  };

  useEffect(() => {
    handleAboutPageViewAnalytics();
  }, []);

  useEffect(() => {
    handleTransitions();
  }, [handleTransitions]);

  return (
    <div className={styles.about}>
      <div ref={backgroundRef} className={styles.background} />
      <div ref={backgroundOverlayRef} className={styles.backgroundOverlay} />

      {!isTouch && (
        <div
          className={styles.linkOverlay}
          data-custom-cursor-state={CustomCursorState.ActionBig}
          data-custom-cursor-text="Discover</br><i>the City</i>"
          onClick={transitionToExplore}
        />
      )}

      <div ref={wrapperRef} className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          {deviceState >= 3 ? (
            <>
              <div ref={imageLeftRef} className={styles.imageLeft} onClick={transitionToExplore}>
                <EasterEgg elements={leftImages} currentElementIndex={imageGroupIndex} />
              </div>
              <div ref={imageRightRef} className={styles.imageRight} onClick={transitionToExplore}>
                <EasterEgg elements={rightImages} currentElementIndex={imageGroupIndex} />
              </div>
            </>
          ) : (
            <div ref={imageMobileRef} className={styles.imageRight}>
              {rightImages[imageGroupIndex]}
            </div>
          )}

          <div className={styles.content}>
            <p ref={taglineRef} className={classNames(styles.tagline, 'globalTagline01 texture')}>
              <span>About the </span>
              <span className={styles.underlineText}>
                <span>series</span>
                <span ref={taglineLineRef} className={classNames(styles.underline, 'texture')} />
              </span>
            </p>

            <div
              className={classNames(
                styles.copyWrapper,
                isEasterEggHovered && styles.isEasterEggHovered,
              )}
              ref={contentRef}
            >
              {deviceState >= 2 ? (
                <>
                  <p ref={firstTextRef} className={classNames(styles.copy, 'globalBody01')}>
                    <span>
                      <i className="texture">—In the early </i>
                      <mark
                        className={classNames(
                          styles.highlightedText,
                          styles.isNumber,
                          imageGroupIndex === 1 && styles.active,
                        )}
                        onMouseEnter={() => {
                          handleMarkMouseEnter(1);
                        }}
                        onMouseLeave={handleMarkMouseLeave}
                        data-custom-cursor-state={CustomCursorState.Pointer}
                      >
                        1890s,
                      </mark>
                      <span> NEW YORK CITY</span>
                    </span>
                    <span className="texture"> WAS PLAGUED BY A SERIES OF </span>
                    <span className="texture"> GRUESOME MURDERS ONLY AN </span>
                    <span>
                      <mark
                        className={classNames(
                          styles.highlightedText,
                          imageGroupIndex === 2 && styles.active,
                        )}
                        onMouseEnter={() => {
                          handleMarkMouseEnter(2);
                        }}
                        onMouseLeave={handleMarkMouseLeave}
                        data-custom-cursor-state={CustomCursorState.Pointer}
                      >
                        ALIENIST
                      </mark>
                      <span> COULD SOLVE.</span>
                    </span>
                  </p>
                  <p ref={dotsRef} className={classNames(styles.dots, 'globalBody01')}>
                    ...
                  </p>
                  <p ref={secondTextRef} className={classNames(styles.copy, 'globalBody01')}>
                    <span className="texture">NOW, ONE YEAR LATER, THE HUNT FOR</span>
                    <span>
                      <span className="texture">A </span>
                      <mark
                        className={classNames(
                          styles.highlightedText,
                          imageGroupIndex === 3 && styles.active,
                        )}
                        onMouseEnter={() => {
                          handleMarkMouseEnter(3);
                        }}
                        onMouseLeave={handleMarkMouseLeave}
                        data-custom-cursor-state={CustomCursorState.Pointer}
                      >
                        KILLER
                      </mark>
                      <span className="texture"> BEGINS AGAIN.</span>
                    </span>
                  </p>
                </>
              ) : (
                <p ref={mobileTextRef} className={classNames(styles.copy, 'globalBody01 texture')}>
                  <span>
                    <span className={styles.isNumber}>1890</span>'S NEW YORK CITY
                  </span>
                  <span>WAS TORTURED WITH</span>
                  <span>A SERIES OF GRUESOME</span>
                  <span>MURDERS. NOW, A YEAR </span>
                  <span>LATER, THE CITY SHOWS</span>
                  <span>A NEW SIDE OF CRIME</span>
                  <span>AND TERROR.</span>
                </p>
              )}
            </div>
          </div>

          <div className={styles.footer}>
            {isTouch && (
              <div ref={mobileCtaRef} className={styles.mobileCta} onClick={transitionToExplore}>
                <RoundedCta copy="Discover</br><i>the City</i>" />
              </div>
            )}
            <button
              className={styles.exploreLink}
              data-custom-cursor-state={CustomCursorState.Pointer}
              onClick={onDownArrowButtonClick}
            >
              <span ref={iconRef} className={classNames(styles.icon, 'texture')} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
