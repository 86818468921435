import React, { FunctionComponent, useRef } from 'react';

// import gsap from 'gsap';
// import { SplitText } from '../../vendor/SplitText';

import classNames from 'classnames';
import styles from './RotateYourDevice.module.scss';

interface RotateYourDeviceProps {}

export const RotateYourDevice: FunctionComponent<RotateYourDeviceProps> = () => {
  //   const [isActive, setIsActive] = useState(false);

  const backgroundRef = useRef<HTMLSpanElement>(null);
  const lineRef = useRef<HTMLSpanElement>(null);
  const iconRef = useRef<HTMLSpanElement>(null);
  const copyRef = useRef<HTMLParagraphElement>(null);

  //   const handleTransitions = () => {
  //     const timeline = gsap.timeline();

  //     timeline.fromTo(
  //       backgroundRef.current,
  //       {
  //         opacity: isActive ? 0 : 1,
  //       },
  //       {
  //         opacity: isActive ? 1 : 0,
  //         duration: 0.6,
  //         ease: 'power2.in',
  //       },
  //       0,
  //     );

  //     timeline.fromTo(
  //       lineRef.current,
  //       {
  //         opacity: isActive ? 0 : 0.15,
  //       },
  //       {
  //         opacity: isActive ? 0.15 : 0,
  //         duration: 0.6,
  //         ease: 'power2.in',
  //         delay: 0.2,
  //       },
  //       0,
  //     );

  //     timeline.fromTo(
  //       iconRef.current,
  //       {
  //         opacity: isActive ? 0 : 1,
  //       },
  //       {
  //         opacity: isActive ? 1 : 0,
  //         duration: 0.8,
  //         ease: 'power2.in',
  //         delay: 0.2,
  //       },
  //       0.6,
  //     );

  //     const splitMenuCopy = new SplitText(copyRef.current, { type: 'words, chars' });

  //     timeline.fromTo(
  //       splitMenuCopy.chars,
  //       {
  //         opacity: 0,
  //       },
  //       {
  //         opacity: 1,
  //         stagger: 0.08,
  //         duration: 0.6,
  //         ease: 'power2.in',
  //       },
  //       0.8,
  //     );
  //   };

  //   const handleResizeListener = () => {
  //   };

  //   useEffect(() => {
  //     window.addEventListener('resize', handleResizeListener);

  //     return () => {
  //       window.removeEventListener('resize', handleResizeListener);
  //     };
  //   }, []);

  //   useEffect(() => {
  //     handleTransitions();
  //   }, [isActive]);

  return (
    <div className={styles.rotateYourDevice}>
      <div className={styles.content}>
        <span ref={backgroundRef} className={styles.background} />
        <span ref={lineRef} className={styles.line} />
        <span ref={iconRef} className={classNames(styles.icon, 'texture')} />
        <p ref={copyRef} className={classNames(styles.copy, 'globalButton01 texture')}>
          ROTATE YOUR PHONE
          <br />
          FOR A BETTER EXPERIENCE
        </p>
      </div>
    </div>
  );
};
