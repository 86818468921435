import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useHistory, Route, Switch } from 'react-router-dom';
import throttle from 'lodash/throttle';

import gsap from 'gsap';

import { Trailer } from '../Landing/Trailer/Trailer';
import { About } from '../Landing/About/About';
import { Hero } from '../Landing/Hero/Hero';
import { RoutePaths } from '../../../data/enum/RoutePaths';

import classNames from 'classnames';
import styles from './Landing.module.scss';

import inkTransition from '../../../assets/images/ink-transition3.jpg';
import heroBackground from '../../../assets/images/landing/hero/background.jpg';
import heroMobileBackground from '../../../assets/images/landing/hero/background-mobile.jpg';
import aboutBackground from '../../../assets/images/landing/about/background.jpg';
import aboutMobileBackground from '../../../assets/images/landing/about/background-mobile.jpg';
import trailerBackground from '../../../assets/images/landing/trailer/background-desktop.jpg';
import trailerMobileBackground from '../../../assets/images/landing/trailer/background-mobile.jpg';

import { SocialMedia } from '../../SocialMedia/SocialMedia';
import { CustomCursorState } from '../../CustomCursor/CustomCursor';

import { ReactComponent as ArrowUp } from '../../../assets/svg/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow-down.svg';

import { useDeviceState } from '../../../hooks/useDeviceState';
import { useSwipeable } from 'react-swipeable';
import { transitionWithShader } from '../../../utils/transitionWithShader';
import { PostcardDownloader } from '../../PostcardDownloader/PostcardDownloader';

const landingRoutes = [
  {
    route: RoutePaths.LANDING_HERO,
    background: {
      desktop: heroBackground,
      mobile: heroMobileBackground,
    },
  },
  {
    route: RoutePaths.LANDING_ABOUT,
    background: {
      desktop: aboutBackground,
      mobile: aboutMobileBackground,
    },
  },
  {
    route: RoutePaths.LANDING_TRAILER,
    background: {
      desktop: trailerBackground,
      mobile: trailerMobileBackground,
    },
  },
];

interface LandingProps {
  isNavigationOpen: boolean;
}

export const Landing: FunctionComponent<LandingProps> = ({ isNavigationOpen }) => {
  const { push, location } = useHistory();
  const { deviceState, isTouch } = useDeviceState();

  const [showPostcardDownloader, setPostcardDownloaderVisibility] = useState(false);
  const [postcardFromValue, setPostcardFromValue] = useState('');
  const [postcardToValue, setPostcardToValue] = useState('');
  const [postcardImageIndex, setPostcardImageIndex] = useState(0);

  const [isTransitioning, setIsTransitioning] = useState(false);
  const [activeRouteIndex, setActiveRouteIndex] = useState(
    landingRoutes.findIndex((route) => route.route === location.pathname),
  );

  const paginationRef = useRef<HTMLDivElement>(null);
  const activeIndexRef = useRef<HTMLSpanElement>(null);

  const swipeHandlers = useSwipeable({
    delta: 25,
    onSwipedUp: () => swipeHandler('up'),
    onSwipedDown: () => swipeHandler('down'),
  });

  const wheelHandler = throttle(
    (event: React.WheelEvent) => {
      event.persist();

      if (Math.abs(event.deltaY) >= 3) {
        slideToRoute(event.deltaY > 0 ? +1 : -1);
      }
    },
    250,
    { trailing: false },
  );

  const swipeHandler = (direction: 'up' | 'down') => {
    slideToRoute(direction === 'up' ? +1 : -1);
  };

  const slideToRoute = (indexOffset: number) => {
    if (isTransitioning) return;

    const newRouteIndex = Math.max(0, (activeRouteIndex + indexOffset) % landingRoutes.length);

    if (newRouteIndex === activeRouteIndex) return;

    setIsTransitioning(true);

    activeIndexRef.current &&
      gsap.to(activeIndexRef.current, {
        autoAlpha: 0,
        y: 5,
        duration: 0.5,
        ease: 'expo.inOut',
      });

    transitionWithShader(
      [
        isTouch
          ? landingRoutes[newRouteIndex].background.mobile
          : landingRoutes[newRouteIndex].background.desktop,
        inkTransition,
      ],
      () => {
        push(landingRoutes[newRouteIndex].route, { noTransition: true });
        setActiveRouteIndex(newRouteIndex);
        activeIndexRef.current &&
          gsap.to(activeIndexRef.current, {
            autoAlpha: 1,
            y: 0,
            duration: 0.5,
            ease: 'expo.inOut',
          });
      },
      () => setIsTransitioning(false),
    );
  };

  useEffect(() => {
    if (!paginationRef.current) return;

    const timeline = gsap.timeline();

    timeline.to(
      paginationRef.current,
      {
        opacity: 1,
        duration: 0.8,
        stagger: 0.2,
        ease: 'power2.in',
      },
      0,
    );
  }, []);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);

    const newFromValue = currentUrl.searchParams.get('from') || '';
    const newToValue = currentUrl.searchParams.get('to') || '';
    const newPostcardImageIndex = parseInt(currentUrl.searchParams.get('postcard') || '0');

    setPostcardFromValue(newFromValue);
    setPostcardToValue(newToValue);
    setPostcardImageIndex(newPostcardImageIndex);

    setPostcardDownloaderVisibility(newFromValue && newToValue ? true : false);
  }, [
    setPostcardFromValue,
    setPostcardToValue,
    setPostcardImageIndex,
    setPostcardDownloaderVisibility,
  ]);

  return (
    <div className={styles.landing} {...swipeHandlers} onWheel={wheelHandler}>
      {deviceState >= 2 && (
        <>
          <SocialMedia menuIsOpen={false} />
          <div className={styles.content}>
            <div ref={paginationRef} className={styles.pagination}>
              <button
                className={styles.button}
                onClick={() => slideToRoute(-1)}
                data-custom-cursor-state={CustomCursorState.Pointer}
              >
                <ArrowUp />
              </button>
              <span className={classNames(styles.current, 'globalBody03')} ref={activeIndexRef}>
                {(activeRouteIndex + 1).toString().padStart(2, '0')}
              </span>
              <span className={styles.line}></span>
              <span className={classNames(styles.next, 'globalBody03')}>03</span>
              <button
                className={styles.button}
                onClick={() => slideToRoute(+1)}
                data-custom-cursor-state={CustomCursorState.Pointer}
              >
                <ArrowDown />
              </button>
            </div>
          </div>
        </>
      )}

      <Switch>
        <Route path={RoutePaths.LANDING_TRAILER}>
          <Trailer canPlayVideo={!isNavigationOpen} />
        </Route>
        <Route path={RoutePaths.LANDING_ABOUT}>
          <About onDownArrowButtonClick={() => slideToRoute(+1)} />
        </Route>
        <Route exact path={RoutePaths.LANDING_HERO}>
          <Hero onDownArrowButtonClick={() => slideToRoute(+1)} />
        </Route>
      </Switch>

      <PostcardDownloader
        isVisible={showPostcardDownloader}
        closeClick={() => setPostcardDownloaderVisibility(false)}
        fromValue={postcardFromValue}
        toValue={postcardToValue}
        activePostcardIndex={postcardImageIndex}
      />
    </div>
  );
};
