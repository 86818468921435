import React, { Component } from 'react';
import { Routes } from '../../routes/Routes';
import { CustomCursor } from '../CustomCursor/CustomCursor';
import { RotateYourDevice } from '../RotateYourDevice/RotateYourDevice';
import { Noise } from '../Noise/Noise';
import { Loader } from '../Loader/Loader';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  onComplete = () => {
    this.setState({ isLoading: false });
  };

  componentDidMount() {
    window.history.replaceState(null, '');
  }

  render() {
    return (
      <>
        <Noise />
        <RotateYourDevice />
        {this.state.isLoading ? <Loader onComplete={this.onComplete} /> : <Routes />}

        <CustomCursor />
      </>
    );
  }
}

export default App;
