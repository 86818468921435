import { useState, useEffect } from 'react';
import { DeviceState } from '../data/enum/DeviceState';
import debounce from 'lodash/debounce';
import { bowserData } from '..';

export const useDeviceState = () => {
  const getWindowSize = () => {
    let deviceState = -1;

    if (window.innerWidth <= 479) {
      deviceState = DeviceState.MAX_479;
    } else if (window.innerWidth >= 480 && window.innerWidth < 768) {
      deviceState = DeviceState.MIN_480;
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      deviceState = DeviceState.MIN_768;
    } else if (window.innerWidth >= 1024 && window.innerWidth < 1240) {
      deviceState = DeviceState.MIN_1024;
    } else if (window.innerWidth >= 1240 && window.innerWidth < 1600) {
      deviceState = DeviceState.MIN_1240;
    } else if (window.innerWidth >= 1600 && window.innerWidth < 1920) {
      deviceState = DeviceState.MIN_1600;
    } else if (window.innerWidth >= 1920) {
      deviceState = DeviceState.MIN_1920;
    }

    const isIpad =
      navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2
        ? true
        : false;

    return {
      deviceState,
      isTouch: bowserData.getPlatformType() !== 'desktop' || isIpad,
      isMobile: bowserData.getPlatformType() === 'mobile',
      isTablet: bowserData.getPlatformType() === 'tablet',
    };
  };

  const [deviceState, setDeviceState] = useState(getWindowSize);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      setDeviceState(getWindowSize());
    }, 100);

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return deviceState;
};
