import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';

interface EasterEggProps {
  currentElementIndex: number;
  elements: Array<JSX.Element>;
}

const EasterEgg: React.FC<EasterEggProps> = ({ currentElementIndex, elements }) => {
  const [targetIndex, setTargetIndex] = useState(currentElementIndex);
  const [oldIndex, setOldIndex] = useState(currentElementIndex);
  const [isAnimating, setIsAnimating] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const oldElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isAnimating || oldIndex === currentElementIndex) {
      return;
    }

    setTargetIndex(currentElementIndex);
    setIsAnimating(true);

    const timeline = gsap.timeline();

    timeline.fromTo(
      oldElementRef.current,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.8,
        ease: 'power2.in',
      },
      0,
    );
    timeline.fromTo(
      elementRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.8,
        ease: 'power2.in',
        onComplete: () => {
          setOldIndex(currentElementIndex);
          setIsAnimating(false);
        },
      },
      0,
    );
  }, [currentElementIndex, isAnimating, oldIndex, setIsAnimating]);

  return (
    <>
      <div ref={oldElementRef}>{elements[oldIndex]}</div>
      <div ref={elementRef}>{elements[targetIndex]}</div>
    </>
  );
};

export { EasterEgg };
