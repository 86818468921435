import React, { useRef, useEffect, useState } from 'react';

interface TextCanvasProps {
  imageSrc: string;
  texts: CanvasTextProps[];
  onCanvasUpdate: (canvas: HTMLCanvasElement) => void;
}

interface CanvasTextProps {
  copy: string;
  fontProps?: {
    family?: string;
    size?: string;
  };
  position: {
    x: number;
    y: number;
  };
}

const TextCanvas: React.FC<TextCanvasProps> = ({ imageSrc, texts, onCanvasUpdate }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [ctx, setContext] = useState<CanvasRenderingContext2D>();
  const [imageElement, setImageElement] = useState<HTMLImageElement>();

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    setContext(canvasRef.current.getContext('2d')!);
  }, [setContext]);

  useEffect(() => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      setImageElement(img);
    };

    img.src = imageSrc;
  }, [imageSrc]);

  useEffect(() => {
    if (!ctx || !canvasRef.current || !imageElement) {
      return;
    }

    drawOverlay(imageElement, ctx);
    texts.forEach((textProps) => drawText(textProps, ctx));
    onCanvasUpdate(canvasRef.current);
  }, [ctx, imageElement, onCanvasUpdate, texts]);

  const drawOverlay = (imageElement: HTMLImageElement, ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, imageElement.width, imageElement.height);
    ctx.drawImage(imageElement, 0, 0);
    ctx.fillStyle = 'rgba(255, 255, 255, 0)';
    ctx.fillRect(0, 0, imageElement.width, imageElement.height);
  };

  const drawText = (textProps: CanvasTextProps, ctx: CanvasRenderingContext2D) => {
    const { copy, fontProps, position } = textProps;
    const fontFamily = fontProps?.family || 'Montserrat';
    const fontSize = fontProps?.size || '17';

    ctx.fillStyle = 'black';
    ctx.textBaseline = 'middle';
    ctx.font = `italic ${fontSize}px '${fontFamily}'`;
    ctx.fillText(copy, position.x, position.y);
  };

  return (
    <>
      <canvas
        ref={canvasRef}
        width={`${imageElement ? imageElement.width : 400}px`}
        height={`${imageElement ? imageElement.height : 400}px`}
      ></canvas>
    </>
  );
};

export { TextCanvas };
