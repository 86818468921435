import React, { FunctionComponent, useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from './BackgroundLines.module.scss';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from '../../data/enum/RoutePaths';

export const BackgroundLines: FunctionComponent = () => {
  const { pathname } = useLocation();

  const [isHidden, setIsHidden] = useState(pathname === RoutePaths.EXTRAS_CONTENT);

  useEffect(() => {
    setIsHidden(pathname === RoutePaths.EXTRAS_CONTENT);
  }, [pathname]);

  return (
    <div className={classNames(styles.backgroundLines, isHidden && styles.isHidden)}>
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
    </div>
  );
};
