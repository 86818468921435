import React, { FunctionComponent, useState, useRef, useEffect, useCallback } from 'react';
import { RoutePaths } from '../../data/enum/RoutePaths';
import { useHistory } from 'react-router-dom';

import { Transition } from 'react-transition-group';
import gsap from 'gsap';
import SplitText from '../../vendor/SplitText';

import classNames from 'classnames';
import styles from './SiteNavigation.module.scss';

import { CustomCursorState } from '../CustomCursor/CustomCursor';
import { SocialMedia } from '../SocialMedia/SocialMedia';
import { BackgroundImage, BackgroundImageType } from './BackgroundImage/BackgroundImage';

import inkTransition from '../../assets/images/ink-transition3.jpg';
import heroBackground from '../../assets/images/landing/hero/background.jpg';
import heroMobileBackground from '../../assets/images/landing/hero/background-mobile.jpg';
import mapBackground from '../../assets/images/map/bg_map_4.jpg';
import mapMobileBackground from '../../assets/images/map/bg_map_4_mobile.jpg';
import extrasBackground from '../../assets/images/extras/bg.jpg';
import extrasMobileBackground from '../../assets/images/extras/bg-mobile.jpg';

import { useDeviceState } from '../../hooks/useDeviceState';
import {
  handleMenuViewAnalytics,
  handleWatchShowLinkClickAnalytics,
} from '../../utils/analyticsWrapper';
import { EmailSignUpForm } from './EmailSignUpForm/EmailSignUpForm';
import { transitionWithShader } from '../../utils/transitionWithShader';

interface SiteNavigationProps {
  isOpen: boolean;
  onExitTransition: () => void;
}

const splitItemText: Array<any> = [];
const splitItemNumber: Array<any> = [];

export const SiteNavigation: FunctionComponent<SiteNavigationProps> = ({
  isOpen,
  onExitTransition,
}) => {
  const [backgroundSource, setBackgroundSource] = useState<BackgroundImageType>('');
  const [backgroundVisible, setBackgroundVisible] = useState(false);
  const { deviceState, isTouch } = useDeviceState();
  const { push, location } = useHistory();

  const itemNumberRef = useRef<Array<HTMLSpanElement | null>>([]);
  const itemTextRef = useRef<Array<HTMLSpanElement | null>>([]);
  const formWrapperRef = useRef<HTMLDivElement>(null);
  const mobileIconRef = useRef<HTMLSpanElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const showBackgroundImage = (type: BackgroundImageType) => {
    setBackgroundVisible(true);
    setBackgroundSource(type);
  };

  const handleOnEnter = (el: HTMLElement) => {
    gsap.set(el, { autoAlpha: 0 });
  };

  const handleAddEndListener = (el: HTMLElement, done: any) => {
    gsap.to(el, {
      autoAlpha: isOpen ? 1 : 0,
      duration: 1,
      onComplete: done,
    });
  };

  const addTextsToTimeline = (array: Array<HTMLElement>, isOpen: boolean) => {
    const timeline = gsap.timeline();

    array.forEach((element: any, index: number) => {
      timeline.fromTo(
        element.chars,
        {
          opacity: isOpen ? 0 : 1,
        },
        {
          opacity: isOpen ? 1 : 0,
          stagger: index % 2 ? -0.05 : 0.05,
          duration: 1,
          ease: 'power2.inOut',
        },
        index * 0.01,
      );
    });

    return timeline;
  };

  const handleTransitions = useCallback(() => {
    if (!formWrapperRef.current) return;

    const timeline = gsap.timeline();

    itemTextRef.current.forEach((element: any) => {
      splitItemText.push(new SplitText(element, { type: 'chars, words' }));
    });
    timeline.add(addTextsToTimeline(splitItemText, isOpen), 0);

    itemNumberRef.current.forEach((element: any) => {
      splitItemNumber.push(new SplitText(element, { type: 'chars, words' }));
    });
    timeline.add(addTextsToTimeline(splitItemNumber, isOpen), 0);

    timeline.fromTo(
      formWrapperRef.current,
      {
        opacity: isOpen ? 0 : 1,
      },
      {
        opacity: isOpen ? 1 : 0,
        duration: 0.8,
        ease: 'power2.in',
      },
      1.4,
    );

    mobileIconRef.current &&
      timeline.fromTo(
        mobileIconRef.current,
        {
          opacity: isOpen ? 0 : 1,
        },
        {
          opacity: isOpen ? 1 : 0,
          duration: 0.8,
          ease: 'power2.in',
        },
        0.6,
      );
  }, [isOpen]);

  const handleWatchTheEpisodesClick = () => {
    onExitTransition();
    handleWatchShowLinkClickAnalytics('Menu');
  };

  const handleExploreClick = () => {
    if (location.pathname.includes(RoutePaths.EXPLORE)) {
      return onExitTransition();
    }

    transitionWithShader(
      [isTouch ? mapMobileBackground : mapBackground, inkTransition],
      () => {
        push(RoutePaths.EXPLORE, { noTransition: true });
        gsap.set(wrapperRef.current, { autoAlpha: 0 });
        onExitTransition();
      },
      () => {},
      10,
    );
  };

  const handleHomepageClick = () => {
    if (
      location.pathname === RoutePaths.LANDING_HERO ||
      location.pathname === RoutePaths.LANDING_ABOUT ||
      location.pathname === RoutePaths.LANDING_TRAILER
    ) {
      return onExitTransition();
    }

    transitionWithShader(
      [isTouch ? heroMobileBackground : heroBackground, inkTransition],
      () => {
        push(RoutePaths.LANDING, { noTransition: true });
        gsap.set(wrapperRef.current, { autoAlpha: 0 });
        onExitTransition();
      },
      () => {},
      10,
    );
  };

  const handleExtrasClick = () => {
    if (location.pathname === RoutePaths.EXTRAS) {
      return onExitTransition();
    }

    if (location.pathname === RoutePaths.EXTRAS_CONTENT) {
      onExitTransition();
      return push(RoutePaths.EXTRAS);
    }

    transitionWithShader(
      [isTouch ? extrasMobileBackground : extrasBackground, inkTransition],
      () => {
        push(RoutePaths.EXTRAS, { noTransition: true });
        gsap.set(wrapperRef.current, { autoAlpha: 0 });
        onExitTransition();
      },
      () => {},
      10,
    );
  };

  useEffect(() => {
    handleTransitions();
  }, [handleTransitions, isOpen]);

  useEffect(() => {
    if (isOpen) {
      handleMenuViewAnalytics();
    }
  }, [isOpen]);

  return (
    <Transition
      unmountOnExit
      in={isOpen}
      onEnter={(node: HTMLElement) => handleOnEnter(node)}
      addEndListener={(node, done) => {
        handleAddEndListener(node, done);
      }}
    >
      <nav id="navigationMenu" className={styles.siteNavigation} ref={wrapperRef}>
        <BackgroundImage isVisible={backgroundVisible} background={backgroundSource} />

        <div className={styles.wrapper}>
          <ul className={styles.list}>
            <li className={styles.item}>
              <div
                className={styles.link}
                onClick={handleHomepageClick}
                data-custom-cursor-state={CustomCursorState.Pointer}
                onMouseEnter={() => !isTouch && showBackgroundImage('homepage')}
                onMouseLeave={() => !isTouch && setBackgroundVisible(false)}
              >
                <span
                  ref={(el) => (itemNumberRef.current[0] = el)}
                  className={classNames(styles.number, 'globalTagline01')}
                >
                  01
                </span>
                <span className={classNames(styles.misc, 'texture')}></span>
                <span
                  ref={(el) => (itemTextRef.current[0] = el)}
                  className={classNames(styles.copy, 'globalTitle02 texture')}
                >
                  Homepage
                </span>
              </div>
            </li>

            <li className={styles.item}>
              <a
                href="https://tnt.app.link/tcpNOeSqX7"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                data-custom-cursor-state={CustomCursorState.Pointer}
                onMouseEnter={() => !isTouch && showBackgroundImage('episodes')}
                onMouseLeave={() => !isTouch && setBackgroundVisible(false)}
                onClick={handleWatchTheEpisodesClick}
              >
                <span
                  ref={(el) => (itemNumberRef.current[1] = el)}
                  className={classNames(styles.number, 'globalTagline01')}
                >
                  02
                </span>
                <span className={classNames(styles.misc, 'texture')}></span>
                <span
                  ref={(el) => (itemTextRef.current[1] = el)}
                  className={classNames(styles.copy, 'globalTitle02 texture')}
                >
                  Watch the episodes
                </span>
              </a>
            </li>

            <li className={styles.item}>
              <div
                className={styles.link}
                onClick={handleExploreClick}
                data-custom-cursor-state={CustomCursorState.Pointer}
                onMouseEnter={() => !isTouch && showBackgroundImage('discover')}
                onMouseLeave={() => !isTouch && setBackgroundVisible(false)}
              >
                <span
                  ref={(el) => (itemNumberRef.current[2] = el)}
                  className={classNames(styles.number, 'globalTagline01')}
                >
                  03
                </span>
                <span className={classNames(styles.misc, 'texture')}></span>
                <span
                  ref={(el) => (itemTextRef.current[2] = el)}
                  className={classNames(styles.copy, 'globalTitle02 texture')}
                >
                  Discover NY <span className={styles.isNumber}>1897</span>
                </span>
              </div>
            </li>

            <li className={styles.item}>
              <div
                className={styles.link}
                onClick={handleExtrasClick}
                data-custom-cursor-state={CustomCursorState.Pointer}
                onMouseEnter={() => !isTouch && showBackgroundImage('extras')}
                onMouseLeave={() => !isTouch && setBackgroundVisible(false)}
              >
                <span
                  ref={(el) => (itemNumberRef.current[3] = el)}
                  className={classNames(styles.number, 'globalTagline01')}
                >
                  04
                </span>
                <span className={classNames(styles.misc, 'texture')}></span>
                <span
                  ref={(el) => (itemTextRef.current[3] = el)}
                  className={classNames(styles.copy, 'globalTitle02 texture')}
                >
                  Extras
                </span>
              </div>
            </li>

            <li className={styles.item}>
              <div className={styles.itemWrapper}>
                <div className={styles.subscribe}>
                  {deviceState >= 2 && (
                    <span
                      ref={(el) => (itemNumberRef.current[4] = el)}
                      className={classNames(styles.number, 'globalTagline01')}
                    >
                      04
                    </span>
                  )}
                  <span
                    ref={(el) => (itemTextRef.current[4] = el)}
                    className={classNames(
                      styles.copy,
                      deviceState < 2 && styles.mobile,
                      'globalTitle02 texture',
                    )}
                  >
                    <span>Subscribe</span>
                    {deviceState < 2 && <span ref={mobileIconRef} className={styles.mobileIcon} />}
                  </span>
                </div>
                <div ref={formWrapperRef} className={styles.formWrapper}>
                  <div className={styles.form}>
                    <EmailSignUpForm />
                  </div>
                  <p className={classNames(styles.disclaimer, 'globalButton02 texture')}>
                    By signing up you agree to receive updates from TNT, WarnerMedia, and
                    affiliates.{' '}
                    <a
                      href="https://www.tntdrama.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-custom-cursor-state={CustomCursorState.Pointer}
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </div>
            </li>
          </ul>

          <SocialMedia isInsideNavigation={true} menuIsOpen={isOpen} />
        </div>
      </nav>
    </Transition>
  );
};
