import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';
import gsap from 'gsap';
import { SplitText } from '../../../../vendor/SplitText';
import { Transition } from 'react-transition-group';
import styles from './HotspotPopupDesktop.module.scss';
interface HotspotPopupProps {
  title: string;
  titlePreview?: string;
  top: string;
  left: string;
  thumbnailProps: {
    src: string;
    alt: string;
    positionX: string;
    positionY: string;
  };
  isVisible: boolean;
  onButtonClick: () => void;
}

const HotspotPopup: React.FC<HotspotPopupProps> = ({
  title,
  titlePreview,
  top,
  left,
  thumbnailProps,
  isVisible,
  onButtonClick,
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const captionRef = useRef<HTMLSpanElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!titleRef.current || !isVisible) return;

    const titleLine = new SplitText(titleRef.current, {
      type: 'lines, words, chars',
      linesClass: 'lineScape',
    });

    gsap.fromTo(
      imageRef.current,
      {
        rotateY: thumbnailProps.positionX === 'left' ? '15deg' : '-15deg',
        rotateX: thumbnailProps.positionY === 'bottom' ? '15deg' : '-15deg',
        autoAlpha: 0,
      },
      { rotateY: 0, rotateX: 0, autoAlpha: 1, duration: 1 },
    );

    gsap.fromTo(captionRef.current, { autoAlpha: 0 }, { autoAlpha: 1, duration: 1.7 });

    gsap.fromTo(
      titleLine.chars,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 1,
        delay: 0.2,
        stagger: 0.05,
      },
    );
  }, [thumbnailProps.positionX, thumbnailProps.positionY, title, isVisible]);

  return (
    <Transition
      in={isVisible}
      unmountOnExit
      onEnter={(node: HTMLElement) => gsap.set(node, { autoAlpha: 0 })}
      addEndListener={(node, done) => {
        gsap.to(node, 0.5, {
          autoAlpha: isVisible ? 1 : 0,
          onComplete: done,
        });
      }}
    >
      <div
        className={classNames(
          styles.hotspotPopup,
          styles.hotspotPreview,
          styles['positionX' + thumbnailProps.positionX],
          styles['positionY' + thumbnailProps.positionY],
        )}
        style={{ left, top }}
      >
        <div className={styles.thumbnailTitleWrapper} ref={textRef}>
          <span ref={captionRef} className={classNames('texture', styles.thumbnailCaption)}>
            Explore
          </span>
          <h3
            ref={titleRef}
            className={classNames('texture', styles.thumbnailTitle)}
            dangerouslySetInnerHTML={{ __html: titlePreview ? titlePreview : title }}
          ></h3>
        </div>
        <div ref={imageRef} className={styles.thumbnailImgWrapper}>
          <img
            className={styles.thumbnailImg}
            src={thumbnailProps.src}
            alt={thumbnailProps.alt}
            data-custom-cursor-state={CustomCursorState.Action}
            data-custom-cursor-text="<i>See More</i>"
            onClick={onButtonClick}
          ></img>
        </div>
      </div>
    </Transition>
  );
};

export { HotspotPopup };
