import React, { FunctionComponent, useEffect, useCallback, useRef } from 'react';

import gsap from 'gsap';
import { SplitText } from '../../../../vendor/SplitText';

import classNames from 'classnames';
import styles from './Slide.module.scss';

import { CustomCursorState } from '../../../CustomCursor/CustomCursor';

import { useDeviceState } from '../../../../hooks/useDeviceState';
import { useInView } from 'react-intersection-observer';

import { useLocation } from 'react-router-dom';

interface SlideProps {
  current: number;
  total: number;
  title: string;
  description: string;
  length: string;
  image: string;
  onClick: (event: React.MouseEvent) => void;
  isVisible: boolean;
}

export const Slide: FunctionComponent<SlideProps> = ({
  current,
  total,
  title,
  description,
  length,
  image,
  onClick,
  isVisible,
}) => {
  const { deviceState, isTouch } = useDeviceState();
  const [inViewRef, inView] = useInView({ threshold: 0.85 });

  const titleRef = useRef<HTMLHeadingElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const currentCopyRef = useRef<HTMLParagraphElement>(null);
  const totalCopyRef = useRef<HTMLParagraphElement>(null);
  const lineRef = useRef<HTMLSpanElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const { state } = useLocation();

  const handleTransitions = useCallback(() => {
    const delay = state ? 1 : 0;
    const timeline = gsap.timeline({ delay });

    timeline.fromTo(
      currentCopyRef.current,
      {
        opacity: isVisible ? 0 : 1,
        y: isVisible ? 5 : 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: 'power2.inOut',
      },
      0.2,
    );

    timeline.fromTo(
      lineRef.current,
      {
        opacity: isVisible ? 0 : 1,
        y: isVisible ? -10 : 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: 'power2.inOut',
      },
      0.4,
    );

    timeline.fromTo(
      totalCopyRef.current,
      {
        opacity: isVisible ? 0 : 1,
        y: isVisible ? 5 : 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: 'power2.inOut',
      },
      0.6,
    );

    const splitDescription = new SplitText(descriptionRef.current, { type: 'words, chars' });

    timeline.fromTo(
      splitDescription.chars,
      {
        opacity: isVisible ? 0 : 1,
        y: isVisible ? 5 : 0,
      },
      {
        opacity: 1,
        y: 0,
        stagger: 0.035,
        duration: 0.8,
        ease: 'power2.inOut',
      },
      0.3,
    );

    timeline.fromTo(
      cardRef.current,
      {
        opacity: isVisible ? 0 : 1,
        y: isVisible ? 5 : 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: 'power2.inOut',
      },
      0.3,
    );

    if (titleRef.current) {
      const splitTitle = new SplitText(titleRef.current, { type: 'words, chars' });

      timeline.fromTo(
        splitTitle.chars,
        {
          opacity: isVisible ? 0 : 1,
          y: isVisible ? 15 : 0,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.03,
          duration: 0.8,
          ease: 'power2.inOut',
        },
        0.4,
      );
    }
  }, [isVisible, state]);

  useEffect(() => {
    handleTransitions();
  }, [handleTransitions]);

  return (
    <div className={styles.slide} ref={inViewRef}>
      <div className={styles.content}>
        <div className={styles.detail}>
          <div className={styles.pagination}>
            <p ref={currentCopyRef} className={classNames(styles.current, 'globalBody03 texture')}>
              <span>{current.toString().padStart(2, '0')}</span>
            </p>
            <span className={styles.lineWrapper}>
              <span ref={lineRef} className={styles.line} />
            </span>
            <p ref={totalCopyRef} className={classNames(styles.total, 'globalBody03 texture')}>
              <span>{total.toString().padStart(2, '0')}</span>
            </p>
          </div>

          <div className={styles.descriptionWrapper}>
            <p
              ref={descriptionRef}
              className={classNames(styles.description, 'globalButton01 texture')}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>

        <div
          ref={cardRef}
          className={classNames(styles.card, inView && styles.isActive)}
          data-custom-cursor-state={CustomCursorState.Action}
          data-custom-cursor-text="Open<br><i>Video</i>"
          onClick={onClick}
        >
          <span className={styles.image} style={{ backgroundImage: `url(${image})` }} />
          {!isTouch && (
            <p className={classNames(styles.duration, 'globalTagline01 texture')}>{length}</p>
          )}
        </div>
      </div>

      {!isTouch && deviceState >= 3 && (
        <div className={styles.titleWrapper}>
          <h2
            ref={titleRef}
            className={classNames(styles.title, inView && styles.isActive, 'texture')}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      )}
    </div>
  );
};
