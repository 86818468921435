import React from 'react';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';

export interface WithLocationTransitionProps {
  isVisible: boolean;
  endTransition: () => void;
}

export const withLocationTransition = <T extends WithLocationTransitionProps>(
  WrappedComponent: React.FC<T>,
) => (props: T) => {
  return (
    <Transition
      unmountOnExit
      in={props.isVisible}
      onEnter={(node: HTMLElement) => gsap.set(node, { autoAlpha: props.isVisible ? 0 : 1 })}
      addEndListener={(node, done) => {
        gsap.to(node, 2, {
          autoAlpha: props.isVisible ? 1 : 0,
          ease: 'expo.inOut',
          onComplete: () => {
            done();
            props.endTransition();
          },
        });
      }}
    >
      <WrappedComponent {...props} />
    </Transition>
  );
};
