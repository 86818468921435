import React, { useRef } from 'react';
import gsap from 'gsap';

import { Transition } from 'react-transition-group';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';

import classNames from 'classnames';
import styles from './Modal.module.scss';
import { useDeviceState } from '../../../../hooks/useDeviceState';

export interface ModalProps {
  isVisible: boolean;
  closeClick: () => void;
  closeButtonIsBlack?: boolean;
  isPostcard?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isVisible,
  closeClick,
  closeButtonIsBlack,
  children,
  isPostcard,
}) => {
  const { isTouch } = useDeviceState();
  const modalRef = useRef<HTMLDivElement>(null);

  const initialState = (node: HTMLElement) => {
    gsap.set(node, { autoAlpha: 0 });
  };

  // modal + shadow
  const transitionElement = (node: HTMLElement, done: () => void) => {
    gsap.to(node, {
      autoAlpha: isVisible ? 1 : 0,
      duration: isVisible ? 0.5 : 0.4,
      delay: isVisible ? 0 : 0.6,
      onComplete: done,
    });
  };

  return (
    <Transition
      unmountOnExit
      in={isVisible}
      onEnter={initialState}
      addEndListener={transitionElement}
    >
      <div className={styles.modalWrapper}>
        {isTouch ? (
          <div className={styles.underlay} onClick={closeClick} />
        ) : (
          <div
            className={styles.underlay}
            onClick={closeClick}
            data-custom-cursor-state={CustomCursorState.Action}
            data-custom-cursor-text="<i>Close</i>"
          />
        )}
        <div
          className={classNames(
            styles.modal,
            isVisible && styles.isVisible,
            isPostcard && styles.postcard,
          )}
          ref={modalRef}
        >
          {isTouch && (
            <>
              {isPostcard && (
                <p className={classNames(styles.mobileZoomCopy, 'globalBody03')}>
                  <span>Tap on the postcard</span>
                  <span>
                    to zoom in
                    <span className={styles.copyIcon} />
                  </span>
                </p>
              )}
              <button
                className={classNames(
                  styles.closeButton,
                  closeButtonIsBlack && styles.closeButtonBlack,
                  'globalBody03',
                )}
                onClick={closeClick}
              >
                Close
              </button>
            </>
          )}
          {children}
        </div>
      </div>
    </Transition>
  );
};

export { Modal };
