const toDoubleDigits = (input: number) => input.toString().padStart(2, '0');

export const secondsToTime = (input: number) => {
  const hours = Math.floor(input / 3600);
  const minutes = Math.floor((input % 3600) / 60);
  const seconds = Math.floor((input % 3600) % 60);

  return {
    hours: toDoubleDigits(hours),
    minutes: toDoubleDigits(minutes),
    seconds: toDoubleDigits(seconds),
  };
};
