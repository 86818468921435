// Desktop
import aboutBackground from '../../assets/images/landing/about/background.jpg';
import aboutImageLeft from '../../assets/images/landing/about/image-left.jpg';
import aboutImageLeftHidden from '../../assets/images/landing/about/image-left-hidden.jpg';
import aboutImageRight from '../../assets/images/landing/about/image-right.jpg';
import aboutImageRightHidden from '../../assets/images/landing/about/image-right-hidden.jpg';
import aboutImage1980s1 from '../../assets/images/landing/about/img-1980s1.jpg';
import aboutImage1980s2 from '../../assets/images/landing/about/img-1980s2.jpg';
import aboutImageAlienists1 from '../../assets/images/landing/about/img-alienists1.jpg';
import aboutImageAlienists2 from '../../assets/images/landing/about/img-alienists2.jpg';
import aboutImageCrime1 from '../../assets/images/landing/about/img-crime1.jpg';
import aboutImageCrime2 from '../../assets/images/landing/about/img-crime2.jpg';
import dots from '../../assets/images/map/dots.png';
import heroBackground from '../../assets/images/landing/hero/background.jpg';
import heroBackgroundHidden from '../../assets/images/landing/hero/background-hidden.jpg';
import trailerBackground from '../../assets/images/landing/trailer/background-desktop.jpg';
import trailerBackgroundHidden from '../../assets/images/landing/trailer/background-hidden.jpg';
import mapBackgroundLocation from '../../assets/images/map/bg_location.png';
import mapBackground1 from '../../assets/images/map/bg_map_1.jpg';
import mapBackground2 from '../../assets/images/map/bg_map_2.jpg';
import mapBackground3 from '../../assets/images/map/bg_map_3.jpg';
import mapBackground4 from '../../assets/images/map/bg_map_4.jpg';
import mapBackgroundHotspotModal from '../../assets/images/map/bg_modal_hotspot.jpg';
import mapCompass from '../../assets/images/map/compass.png';
import mapEyePin from '../../assets/images/map/eye_pin.png';
import mapPlayIcon from '../../assets/images/map/play_icon.png';
import mapScrollIndicator from '../../assets/images/map/scroll_ind.png';
import mapStamp from '../../assets/images/map/stamp.png';
import navigationDiscover from '../../assets/images/navigation/discover.jpg';
import navigationEpisodes from '../../assets/images/navigation/episodes.jpg';
import navigationExtras from '../../assets/images/navigation/extras.jpg';
import navigationHomepage from '../../assets/images/navigation/homepage.jpg';
import skullIcon from '../../assets/images/map/skull.png';
import textureGrunge from '../../assets/images/grunge.png';
import textureInk from '../../assets/images/ink-transition3.jpg';
import texture from '../../assets/images/texture.jpg';

// Postcard
import postcardFull from '../../assets/images/postcards/postcard1_full.jpg';
import postcardSrc1 from '../../assets/images/postcards/img_postcard1.jpg';
import postcardSrc2 from '../../assets/images/postcards/img_postcard2.jpg';
import postcardSrc3 from '../../assets/images/postcards/img_postcard3.jpg';
import postcardBg from '../../assets/images/postcards/modal_postcard_bg.jpg';

// Extras
import extrasBackground from '../../assets/images/extras/bg.jpg';
import extrasCardTexture from '../../assets/images/extras/card-texture.jpg';
import extrasSlideImage01 from '../../assets/images/extras/slides/image-01.jpg';
import extrasSlidePosterDesktop01 from '../../assets/images/extras/slides/poster-desktop-01.jpg';
import extrasSlideImage02 from '../../assets/images/extras/slides/image-02.jpg';
import extrasSlidePosterDesktop02 from '../../assets/images/extras/slides/poster-desktop-02.jpg';
import extrasSlideImage03 from '../../assets/images/extras/slides/image-03.jpg';
import extrasSlidePosterDesktop03 from '../../assets/images/extras/slides/poster-desktop-03.jpg';
import extrasSlideImage04 from '../../assets/images/extras/slides/image-04.jpg';
import extrasSlidePosterDesktop04 from '../../assets/images/extras/slides/poster-desktop-04.jpg';
import extrasSlideImage05 from '../../assets/images/extras/slides/image-05.jpg';
import extrasSlidePosterDesktop05 from '../../assets/images/extras/slides/poster-desktop-05.jpg';

// Downtown (ex Lower Manhattan)
import dtHotspot1Thumbnail from '../../assets/images/map/downtown/delmonicos/delmonicos.jpg';
import dtHotspot2Thumbnail from '../../assets/images/map/downtown/wallstreet/wallstreet-now.jpg';
import dtHotspot2ThumbnailHidden from '../../assets/images/map/downtown/wallstreet/wallstreet-then.jpg';
import dtHotspot3Thumbnail from '../../assets/images/map/downtown/fulton/fulton-now.jpg';
import dtHotspot3ThumbnailHidden from '../../assets/images/map/downtown/fulton/fulton-then.jpg';
import dtHotspot4Thumbnail from '../../assets/images/map/downtown/brooklynbridge/brooklynbridge-now.jpg';
import dtHotspot4ThumbnailHidden from '../../assets/images/map/downtown/brooklynbridge/brooklynbridge-then.jpg';
import dtHotspot6Thumbnail from '../../assets/images/map/downtown/lowerm/lowerm.jpg';
import dtHotspot7Thumbnail from '../../assets/images/map/downtown/nyjournal/nyjournal.jpg';

// Midtown (ex Downtown)
import mtHotspot1Thumbnail from '../../assets/images/map/midtown/washingtonpark/washingtonpark-now.jpg';
import mtHotspot1ThumbnailHidden from '../../assets/images/map/midtown/washingtonpark/washingtonpark-then.jpg';
import mtHotspot2Thumbnail from '../../assets/images/map/midtown/bowery/bowery-now.jpg';
import mtHotspot2ThumbnailHidden from '../../assets/images/map/midtown/bowery/bowery-then.jpg';
import mtHotspot3Thumbnail from '../../assets/images/map/midtown/kreizlerinstitute/kreizlerinstitute.jpg';
import mtHotspot4Thumbnail from '../../assets/images/map/midtown/eastside/eastside.jpg';
import mtHotspot5Thumbnail from '../../assets/images/map/midtown/hospital/hospital.jpg';

// Westside
import wsHotspot1Thumbnail from '../../assets/images/map/westside/madisonsq/madisonsq-now.jpg';
import wsHotspot1ThumbnailHidden from '../../assets/images/map/westside/madisonsq/madisonsq-then.jpg';
import wsHotspot2Thumbnail from '../../assets/images/map/westside/flatiron/flatiron-now.jpg';
import wsHotspot2ThumbnailHidden from '../../assets/images/map/westside/flatiron/flatiron-then.jpg';
import wsHotspot3Thumbnail from '../../assets/images/map/westside/unionsq/unionsq-now.jpg';
import wsHotspot3ThumbnailHidden from '../../assets/images/map/westside/unionsq/unionsq-then.jpg';
import wsHotspot4Thumbnail from '../../assets/images/map/westside/siegel/siegel.jpg';
import wsHotspot4aThumbnail from '../../assets/images/map/westside/siegel/siegel02.jpg';
import wsHotspot4bThumbnail from '../../assets/images/map/westside/siegel/siegel03.jpg';
import wsHotspot4cThumbnail from '../../assets/images/map/westside/siegel/siegel04.jpg';
import wsHotspot6Thumbnail from '../../assets/images/map/westside/howardagencydept/howardagencydept.jpg';
import wsHotspot7Thumbnail from '../../assets/images/map/westside/gasenvoort/gasenvoort.jpg';
import wsHotspot8Thumbnail from '../../assets/images/map/westside/howardagencydept/howardagencydeptarrival.jpg';
import wsHotspot9Thumbnail from '../../assets/images/map/westside/broadway/broadway.jpg';

// Uptown
import utHotspot2Thumbnail from '../../assets/images/map/uptown/centralpark/centralpark.jpg';
import utHotspot2ThumbnailHidden from '../../assets/images/map/uptown/centralpark/centralpark02.jpg';
import utHotspot3Thumbnail from '../../assets/images/map/uptown/met/met-now.jpg';
import utHotspot3ThumbnailHidden from '../../assets/images/map/uptown/met/met-then.jpg';
import utHotspot4Thumbnail from '../../assets/images/map/uptown/grandcentral/grandcentral-now.jpg';
import utHotspot4ThumbnailHidden from '../../assets/images/map/uptown/grandcentral/grandcentral-then.jpg';
import utHotspot5Thumbnail from '../../assets/images/map/uptown/timessquare/timessquare-now.jpg';
import utHotspot5ThumbnailHidden from '../../assets/images/map/uptown/timessquare/timessquare-then.jpg';
import utHotspot6Thumbnail from '../../assets/images/map/uptown/spanishconsulate/spanishconsulate.jpg';
import utHotspot7Thumbnail from '../../assets/images/map/uptown/5thavenue/5thavenue.jpg';
import utHotspot8Thumbnail from '../../assets/images/map/uptown/hudsonriver/hudsonriver.jpg';

// Mobile
import mobileHeroBackground from '../../assets/images/landing/hero/background-mobile.jpg';
import mobileAboutBackground from '../../assets/images/landing/about/background-mobile.jpg';
import mobileTrailerBackground from '../../assets/images/landing/trailer/background-mobile.jpg';
import extrasSlidePosterMobile01 from '../../assets/images/extras/slides/poster-mobile-01.jpg';
import extrasSlidePosterMobile02 from '../../assets/images/extras/slides/poster-mobile-02.jpg';
import extrasSlidePosterMobile03 from '../../assets/images/extras/slides/poster-mobile-03.jpg';
import extrasSlidePosterMobile04 from '../../assets/images/extras/slides/poster-mobile-04.jpg';
import extrasSlidePosterMobile05 from '../../assets/images/extras/slides/poster-mobile-05.jpg';
import mapBackgroundMobile1 from '../../assets/images/map/bg_map_1_mobile.jpg';
import mapBackgroundMobile2 from '../../assets/images/map/bg_map_2_mobile.jpg';
import mapBackgroundMobile3 from '../../assets/images/map/bg_map_3_mobile.jpg';
import mapBackgroundMobile4 from '../../assets/images/map/bg_map_4_mobile.jpg';
import mapBackgroundHotspotModalMobile from '../../assets/images/map/bg_modal_mobile.jpg';
import rotateBackground from '../../assets/images/rotate-your-device/background.jpg';
import rotateIcon from '../../assets/images/rotate-your-device/icon.png';
import extrasBackgroundMobile from '../../assets/images/extras/bg-mobile.jpg';

const imagesToLoadDesktop = [
  postcardFull,
  postcardSrc1,
  postcardSrc2,
  postcardSrc3,
  postcardBg,
  aboutBackground,
  aboutImageLeft,
  aboutImageLeftHidden,
  aboutImageRight,
  aboutImageRightHidden,
  aboutImage1980s1,
  aboutImage1980s2,
  aboutImageAlienists1,
  aboutImageAlienists2,
  aboutImageCrime1,
  aboutImageCrime2,
  dots,
  heroBackground,
  heroBackgroundHidden,
  trailerBackground,
  trailerBackgroundHidden,
  mapBackgroundLocation,
  mapBackground1,
  mapBackground2,
  mapBackground3,
  mapBackground4,
  mapBackgroundHotspotModal,
  mapCompass,
  mapPlayIcon,
  mapScrollIndicator,
  mapStamp,
  navigationDiscover,
  navigationEpisodes,
  navigationExtras,
  navigationHomepage,
  skullIcon,
  textureGrunge,
  textureInk,
  texture,
  dtHotspot1Thumbnail,
  dtHotspot2Thumbnail,
  dtHotspot2ThumbnailHidden,
  dtHotspot3Thumbnail,
  dtHotspot3ThumbnailHidden,
  dtHotspot4Thumbnail,
  dtHotspot4ThumbnailHidden,
  dtHotspot6Thumbnail,
  dtHotspot7Thumbnail,
  mtHotspot1Thumbnail,
  mtHotspot1ThumbnailHidden,
  mtHotspot2Thumbnail,
  mtHotspot2ThumbnailHidden,
  mtHotspot3Thumbnail,
  mtHotspot4Thumbnail,
  mtHotspot5Thumbnail,
  wsHotspot1Thumbnail,
  wsHotspot1ThumbnailHidden,
  wsHotspot2Thumbnail,
  wsHotspot2ThumbnailHidden,
  wsHotspot3Thumbnail,
  wsHotspot3ThumbnailHidden,
  wsHotspot4Thumbnail,
  wsHotspot4aThumbnail,
  wsHotspot4bThumbnail,
  wsHotspot4cThumbnail,
  wsHotspot6Thumbnail,
  wsHotspot7Thumbnail,
  wsHotspot8Thumbnail,
  wsHotspot9Thumbnail,
  utHotspot2Thumbnail,
  utHotspot2ThumbnailHidden,
  utHotspot3Thumbnail,
  utHotspot3ThumbnailHidden,
  utHotspot4Thumbnail,
  utHotspot4ThumbnailHidden,
  utHotspot5Thumbnail,
  utHotspot5ThumbnailHidden,
  utHotspot6Thumbnail,
  utHotspot7Thumbnail,
  utHotspot8Thumbnail,
  extrasBackground,
  extrasCardTexture,
  extrasSlideImage01,
  extrasSlidePosterDesktop01,
  extrasSlideImage02,
  extrasSlidePosterDesktop02,
  extrasSlideImage03,
  extrasSlidePosterDesktop03,
  extrasSlideImage04,
  extrasSlidePosterDesktop04,
  extrasSlideImage05,
  extrasSlidePosterDesktop05,
];

const imagesToLoadMobile = [
  dots,
  postcardFull,
  postcardSrc1,
  postcardSrc2,
  postcardSrc3,
  postcardBg,
  mobileHeroBackground,
  mobileAboutBackground,
  mobileTrailerBackground,
  mapBackgroundMobile1,
  mapBackgroundMobile2,
  mapBackgroundMobile3,
  mapBackgroundMobile4,
  dtHotspot1Thumbnail,
  dtHotspot2Thumbnail,
  dtHotspot2ThumbnailHidden,
  dtHotspot3Thumbnail,
  dtHotspot3ThumbnailHidden,
  dtHotspot4Thumbnail,
  dtHotspot4ThumbnailHidden,
  dtHotspot7Thumbnail,
  mtHotspot1Thumbnail,
  mtHotspot1ThumbnailHidden,
  mtHotspot2Thumbnail,
  mtHotspot2ThumbnailHidden,
  mtHotspot3Thumbnail,
  mtHotspot4Thumbnail,
  mtHotspot5Thumbnail,
  wsHotspot1Thumbnail,
  wsHotspot1ThumbnailHidden,
  wsHotspot2Thumbnail,
  wsHotspot2ThumbnailHidden,
  wsHotspot3Thumbnail,
  wsHotspot3ThumbnailHidden,
  wsHotspot4Thumbnail,
  wsHotspot4aThumbnail,
  wsHotspot4bThumbnail,
  wsHotspot4cThumbnail,
  wsHotspot6Thumbnail,
  wsHotspot7Thumbnail,
  wsHotspot8Thumbnail,
  wsHotspot9Thumbnail,
  utHotspot2Thumbnail,
  utHotspot2ThumbnailHidden,
  utHotspot3Thumbnail,
  utHotspot3ThumbnailHidden,
  utHotspot4Thumbnail,
  utHotspot4ThumbnailHidden,
  utHotspot5Thumbnail,
  utHotspot5ThumbnailHidden,
  utHotspot6Thumbnail,
  utHotspot7Thumbnail,
  utHotspot8Thumbnail,
  mapBackgroundHotspotModalMobile,
  rotateBackground,
  rotateIcon,
  mapEyePin,
  extrasBackgroundMobile,
  extrasCardTexture,
  extrasSlideImage01,
  extrasSlidePosterMobile01,
  extrasSlideImage02,
  extrasSlidePosterMobile02,
  extrasSlideImage03,
  extrasSlidePosterMobile03,
  extrasSlideImage04,
  extrasSlidePosterMobile04,
  extrasSlideImage05,
  extrasSlidePosterMobile05,
];

export { imagesToLoadDesktop, imagesToLoadMobile };
