import { MapLocation, MapLocationHotspot, ShareHotspot } from '../../types/nyMapTypes';

import bgMap from '../../assets/images/map/bg_map_3.jpg';
import bgMapMobile from '../../assets/images/map/bg_map_3_mobile.jpg';
import hotspot1Thumbnail from '../../assets/images/map/midtown/washingtonpark/washingtonpark-now.jpg';
import hotspot1ThumbnailHidden from '../../assets/images/map/midtown/washingtonpark/washingtonpark-then.jpg';
import hotspot2Thumbnail from '../../assets/images/map/midtown/bowery/bowery-now.jpg';
import hotspot2ThumbnailHidden from '../../assets/images/map/midtown/bowery/bowery-then.jpg';
import hotspot3Thumbnail from '../../assets/images/map/midtown/kreizlerinstitute/kreizlerinstitute.jpg';
import hotspot3Video from '../../assets/videos/kreizler-institute-hypnosis.mp4';
import hotspot4Thumbnail from '../../assets/images/map/midtown/eastside/eastside.jpg';
import hotspot5Thumbnail from '../../assets/images/map/midtown/hospital/hospital.jpg';
import hotspot5Video from '../../assets/videos/lying-in-hospital-poison.mp4';

const shareHotspot: ShareHotspot = {
  markerPositionDesktop: {
    x: '50%',
    y: '68%',
  },
  markerPositionMobile: {
    x: '15%',
    y: '23%',
  },
  type: 'share',
  isPostcard: true,
};

const hotspot1: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '27%',
    y: '28%',
  },
  markerPositionMobile: {
    x: '20%',
    y: '42%',
  },
  type: 'city',
  title: `<span>Washington</span> S. <i>Park</i>`,
  cleanTitle: 'Washington S. Park',
  description: `The storied past of Washington Square Park goes far beyond than the original, temporary arch being made of papier-mache and plaster for George Washington's Centennial Parade. But at the heart of it all, the park is a gathering ground for everything from celebrations to revolutions.`,
  thumbnail: {
    src: hotspot1ThumbnailHidden,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot1Thumbnail,
        alt: 'landscape_1',
      },
    ],
    hiddenContent: {
      src: hotspot1ThumbnailHidden,
      alt: 'landscape_2',
    },
  },
};

const hotspot2: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '62%',
    y: '37%',
  },
  markerPositionMobile: {
    x: '63%',
    y: '52%',
  },
  type: 'city',
  title: `<span>Bowery</span>`,
  cleanTitle: 'Bowery',
  description: `Locale of the oldest street in New York City, the Bowery neighborhood has been home to a vibrant culture whose communities span from rich to poor, upscale to downtrodden. In modern times, some of the city's most lavish residences and business fronts sprawl from Spring Street to Canal.`,
  thumbnail: {
    src: hotspot2ThumbnailHidden,
    alt: 'alt',
    positionX: 'left',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot2Thumbnail,
        alt: 'landscape_1',
      },
    ],
    hiddenContent: {
      src: hotspot2ThumbnailHidden,
      alt: 'landscape_2',
    },
  },
};

const hotspot3: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '74%',
    y: '52%',
  },
  markerPositionMobile: {
    x: '73%',
    y: '36%',
  },
  type: 'story',
  title: `<span>Kreizler</span> <i>Institute</i>`,
  cleanTitle: 'Kreizler Institute',
  description: `The Kreizler Institute for Children was founded by Dr. Kreizler in 1885.`,
  thumbnail: {
    src: hotspot3Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot3Video,
        alt: 'Kreizler Institute',
      },
    ],
    hiddenContent: {
      src: hotspot3Thumbnail,
      alt: 'Kreizler Institute',
    },
    isVideo: true,
  },
  closeButtonBlack: false,
  isDarkPin: true,
};

const hotspot4: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '46%',
    y: '25%',
  },
  markerPositionMobile: {
    x: '50%',
    y: '40%',
  },
  type: 'show',
  title: `<span>East</span> <i>Side</i>`,
  cleanTitle: 'East Side',
  description: `Modern day bar Death & Co. resdiscovers the flavors of 1897 with cocktail recipes inspired by the series for you <a download target="_blank" href="https://i.cdn.tntdrama.com/assets/RecipeCards_v05.pdf">to download</a>.`,
  thumbnail: {
    src: hotspot4Thumbnail,
    alt: 'alt',
    positionX: 'right',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot4Thumbnail,
        alt: 'East Side',
      },
    ],
  },
};

const hotspot5: MapLocationHotspot = {
  markerPositionDesktop: {
    x: '76%',
    y: '25%',
  },
  markerPositionMobile: {
    x: '85%',
    y: '52%',
  },
  type: 'story',
  title: `<span><i>Lying</i></span> <i>in</i> Hospital`,
  titlePreview: `<span><i>Lying</i></span><br><i>in</i> Hospital`,
  cleanTitle: 'Lying in Hospital',
  description: `Sara Howard and Dr. Kreizler suspect there are dark secrets and begin investigating.`,
  thumbnail: {
    src: hotspot5Thumbnail,
    alt: 'alt',
    positionX: 'left',
    positionY: 'bottom',
  },
  mediaContent: {
    visibleContent: [
      {
        src: hotspot5Video,
        alt: 'Lying in Hospital',
      },
    ],
    hiddenContent: {
      src: hotspot5Thumbnail,
      alt: '',
    },
    isVideo: true,
  },
  isDarkPin: true,
};

const midtownLocationData: MapLocation = {
  bg: bgMap,
  bgMobile: bgMapMobile,
  path: '/midtown',
  name: 'Midtown',
  cleanName: 'Midtown',
  shortName: 'Midtown',
  shortNameClass: 'Midtown',
  description: `Some of New York City's most historical parks, locations, and buildings arose here below 14th Street.`,
  backgroundImagePath: '../',
  hotspots: {
    mobile: [hotspot1, hotspot4, hotspot2, hotspot5, hotspot3],
    desktop: [hotspot1, hotspot5, hotspot2, hotspot3, hotspot4],
  },
  shareHotspot,
};

export { midtownLocationData };
