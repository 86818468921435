import React, { useRef } from 'react';
import classNames from 'classnames';

import styles from './MaskedImage.module.scss';

import { CustomCursorState } from '../CustomCursor/CustomCursor';

interface MaskedImageProps {
  cursorText?: string;
  visibleImageSrc: string;
  hiddenImageSrc: string;
}

const MaskedImage: React.FC<MaskedImageProps> = ({
  visibleImageSrc,
  hiddenImageSrc,
  cursorText,
}) => {
  const maskRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.maskedImage}>
      <div
        className={classNames(styles.image, styles.visible)}
        style={{ backgroundImage: `url(${visibleImageSrc})` }}
      ></div>
      <div
        ref={maskRef}
        className={classNames(styles.hiddenImageWrapper)}
        data-custom-cursor-state={CustomCursorState.Mask}
        data-custom-cursor-text={cursorText}
      >
        <div className={classNames(styles.hiddenMask, styles.hidden)}>
          <div
            className={classNames(styles.image, styles.hidden)}
            style={{ backgroundImage: `url(${hiddenImageSrc})` }}
          ></div>
          <div className={styles.hiddenImageBackgroundColor}></div>
        </div>
      </div>
    </div>
  );
};

export { MaskedImage };
