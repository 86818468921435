import React, { FunctionComponent, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Landing } from '../components/views/Landing/Landing';
import { NYMapView } from '../components/views/NYMap/NYMapView';
import { RoutePaths, rootPath } from '../data/enum/RoutePaths';
import { SiteHeader } from '../components/SiteHeader/SiteHeader';
import { SiteFooter } from '../components/SiteFooter/SiteFooter';
import { BackgroundLines } from '../components/BackgroundLines/BackgroundLines';
import { ExtrasView } from '../components/views/Extras/ExtrasView';

export const Routes: FunctionComponent = () => {
  const canvasStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
    pointerEvents: 'none',
  };

  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  return (
    <Router basename={rootPath}>
      <BackgroundLines />
      <div id="transitionCanvas" style={canvasStyle} />
      <SiteHeader isNavigationOpen={isNavigationOpen} onMenuOpen={setIsNavigationOpen} />
      <Switch>
        <Route path={RoutePaths.EXTRAS}>
          <ExtrasView />
        </Route>
        <Route path={RoutePaths.EXPLORE}>
          <NYMapView />
        </Route>
        <Route path={RoutePaths.LANDING}>
          <Landing isNavigationOpen={isNavigationOpen} />
        </Route>
      </Switch>
      <SiteFooter />
    </Router>
  );
};
